<div [formGroup]="form">
  <div
    *ngIf="dateAndTime && !readOnly"
    [ngSwitch]="dateAndTime.dateDisplayFormat"
  >
    <ff-date-and-time
      *ngSwitchCase="displayFormat.DateAndTime"
      [value]="formattedDateTime"
      [label]="dateAndTime.title"
      [formControlName]="dateAndTime.id"
      [id]="dateAndTime.id"
      [tooltip]="dateAndTime.description"
      placeHolder="Select Date And Time"
      [enabled]="dateAndTime.enabled"
      [showTitle]="dateAndTime.showTitle"
      [required]="dateAndTime.required!"
      (OnChange)="change($event)"
      [showErrorText]="showErrorText"
    >
      <ng-container *ngTemplateOutlet="commentIcon" />
    </ff-date-and-time>

    <ff-date-only
      *ngSwitchCase="displayFormat.DateOnly"
      [value]="formattedDateTime"
      [label]="dateAndTime.title"
      [formControlName]="dateAndTime.id"
      [id]="dateAndTime.id"
      [tooltip]="dateAndTime.description"
      [enabled]="dateAndTime.enabled"
      placeHolder="Select Date"
      [showTitle]="dateAndTime.showTitle"
      [required]="dateAndTime.required!"
      (OnChange)="change($event)"
      [showErrorText]="showErrorText"
    >
      <ng-container *ngTemplateOutlet="commentIcon" />
    </ff-date-only>

    <ff-time-only
      *ngSwitchCase="displayFormat.TimeOnly"
      [value]="formattedDateTime"
      [label]="dateAndTime.title"
      [formControlName]="dateAndTime.id"
      [id]="dateAndTime.id"
      [tooltip]="dateAndTime.description"
      [enabled]="dateAndTime.enabled"
      placeHolder="Select Time"
      [showTitle]="dateAndTime.showTitle"
      [required]="dateAndTime.required!"
      (OnChange)="change($event)"
      [showErrorText]="showErrorText"
    >
      <ng-container *ngTemplateOutlet="commentIcon" />
    </ff-time-only>
  </div>
  <ff-textbox
    *ngIf="dateAndTime && readOnly"
    [tooltip]="dateAndTime.description"
    [label]="dateAndTime.title"
    [id]="dateAndTime.id"
    [required]="dateAndTime.required!"
    [showTitle]="dateAndTime.showTitle"
    [value]="getDisplayValue"
    [showComment]="showComment"
    [enabled]="dateAndTime.enabled"
  >
    <ng-container *ngTemplateOutlet="commentIcon" />
  </ff-textbox>
</div>
<ng-template #commentIcon>
  <ng-content />
</ng-template>
