import { Component, Input } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FileExtensionPermissionType } from '@flowforma/ff-components';

@Component({
  selector: 'add-attachments-dialog',
  templateUrl: './add-attachments-dialog.component.html',
  styleUrls: ['./add-attachments-dialog.component.scss'],
})
export class AddAttachmentsDialogComponent {
  public form: FormGroup = new FormGroup({
    fileInput: new FormControl(''),
  });

  @Input() stepId?: string;
  @Input() unallowedFileTypes!: string[];

  fileExtensionPermissionType = FileExtensionPermissionType;
  validFiles?: File[];
  invalidFiles?: File[];

  constructor(public activeModal: NgbActiveModal) {}

  confirmAddAttachments(): void {
    if (!this.form.valid) {
      this.form.markAllAsTouched();
      return;
    }

    const formData = this.form.value.fileInput as File[];
    this.activeModal.close(formData);
  }

  change(files: FileList | null): void {
    if (files?.length) {
      const fileList: File[] = Array.from(files);
      const validFileList: File[] = [];
      const invalidFileList: File[] = [];

      fileList.forEach((file) => {
        if (this.validateFileExtension(file)) {
          validFileList.push(file);
        } else {
          invalidFileList.push(file);
        }
      });
      this.invalidFiles = (this.invalidFiles ?? []).concat(invalidFileList);
      this.validFiles = (this.validFiles ?? []).concat(validFileList);
      this.form.patchValue({ fileInput: this.validFiles });
    }
  }

  deleteValidFile(fileIndex: number): void {
    if (
      this.validFiles &&
      fileIndex >= 0 &&
      fileIndex < this.validFiles.length
    ) {
      this.validFiles.splice(fileIndex, 1);
      this.form.patchValue({ fileInput: this.validFiles });
    }
  }

  deleteInvalidFile(fileIndex: number): void {
    if (
      this.invalidFiles &&
      fileIndex >= 0 &&
      fileIndex < this.invalidFiles.length
    ) {
      this.invalidFiles.splice(fileIndex, 1);
    }
  }

  getFileArray(): File[] {
    return this.validFiles ?? [];
  }

  closeModal(): void {
    this.activeModal.dismiss();
  }

  getFileExtension(file: File): string {
    return file.name.includes('.')
      ? file.name.slice(file.name.lastIndexOf('.'))
      : '';
  }

  validateFileExtension(file: File): boolean {
    const fileExtension = this.getFileExtension(file);
    return !this.unallowedFileTypes.includes(fileExtension);
  }
}
