import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, of, switchMap } from 'rxjs';
import { environment } from 'src/environments/environment';
import {
  FileExtensionPermissionType,
  FileUploadDetails,
  FileUploadStorageType,
} from '@flowforma/ff-components';

@Injectable({
  providedIn: 'root',
})
export class FileService {
  private _domain = environment.ffxFileApiUrl;
  private _downloadFileUrl = '/Download';
  private _deleteFileUrl = '/Delete';
  private _uploadFileForTableColumnsAndWorksheetNamesUrl =
    '/GetColumnTitlesAndWorksheetNamesFromFile';

  private _options = {
    responseType: ResponseContentType.Blob,
  };

  constructor(private http: HttpClient) {}

  /**
   * @description Calls the download file with correct parameters for sharepoint
   * @param { FileUploadStorageType } storageType Where the file is stored
   * @param { string } fileName Parameter desc
   * @param { string } fileUrl Url of file to download
   * @returns { void }
   */
  downloadFileByStorageType(
    storageType: FileUploadStorageType,
    fileName: string,
    fileUrl: string,
  ): void {
    switch (storageType) {
      case FileUploadStorageType.SharePoint:
        this.downloadFileData(fileName, fileUrl, storageType, false);
        break;
      case FileUploadStorageType.Azure:
        this.downloadFileData(fileName, fileUrl, storageType, false);
        break;
      default:
        break;
    }
  }

  deleteListItemFile(
    storageType: FileUploadStorageType,
    fileUrl: string,
  ): Observable<boolean> {
    switch (storageType) {
      case FileUploadStorageType.SharePoint:
      case FileUploadStorageType.Azure:
        return this.deleteFile(fileUrl, storageType);
      default:
        return of(false);
    }
  }

  /**
   * @description Calls the download file with correct parameter for specified storage type
   * @param { string } fileName Name of file
   * @param { string } fileUrl Location of file
   * @param { FileUploadStorageType } storageType Specifies file storage type
   * @param { boolean } openInNewTab Whether to open file in new tab
   */
  downloadFileData(
    fileName: string,
    fileUrl: string,
    storageType: FileUploadStorageType,
    openInNewTab: boolean = false,
  ): void {
    this.http
      .get(
        `${this._domain}${this._downloadFileUrl}?relativeFilePath=${fileUrl}&storageType=${storageType}`,
        this._options,
      )
      .subscribe((data: Blob) => {
        this.downloadFile(fileName, data, openInNewTab);
      });
  }

  getImageData(
    fileUrl: string,
    fileName: string,
    storageType: FileUploadStorageType,
  ): Observable<File | null> {
    return this.getImage(
      `${this._domain}${this._downloadFileUrl}?relativeFilePath=${fileUrl}&storageType=${storageType}`,
      fileName,
    );
  }

  getImage(fileUrl: string, fileName: string): Observable<File | null> {
    return this.http.get(fileUrl, this._options).pipe(
      switchMap((data) => {
        return of(new File([data], fileName, { type: fileName }));
      }),
      catchError((error) => {
        console.error('Error fetching file URL:', error);
        return of(null); // Return null if there is an error
      }),
    );
  }

  /**
   * @description Starts download of file for parameters given
   * @param { string } fileName Name to give file downloaded
   * @param { Blob } data File data to download
   * @param { boolean } openInNewTab Whether to open file in new tab
   * @returns { void }
   */
  downloadFile(
    fileName: string,
    data: Blob,
    openInNewTab: boolean = false,
  ): void {
    const blobType = this.getFileDownloadType(fileName);

    // If pdf open in new tab
    if (openInNewTab && blobType === 'application/pdf') {
      this.openFileInNewTab(data, blobType);
      return;
    }
    const windowTimeout = 100;
    const blob = new Blob([data], { type: blobType });
    const fileURL = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = fileURL;
    link.target = '_blank';
    link.download = fileName;
    link.dispatchEvent(
      new MouseEvent('click', {
        bubbles: true,
        cancelable: true,
        view: window,
      }),
    );
    // Some browsers require a small bit of time
    setTimeout(() => {
      window.URL.revokeObjectURL(fileURL);
    }, windowTimeout);
  }

  /**
   * @description Deletes file from specified storage type
   * @param { string } fileUrl Url of the file
   * @returns { Observable<boolean> } Whether the file was successfully delete
   */
  deleteFile(
    fileUrl: string,
    storageType: FileUploadStorageType,
  ): Observable<boolean> {
    const url = `${this._domain}${this._deleteFileUrl}?relativeFilePath=${fileUrl}&storageType=${storageType}`;

    return this.http.delete<boolean>(url);
  }

  /**
   * @description Opens file in new tab
   * @param { Blob } data Data for the blob
   * @param { string } blobType Type of blob
   * @returns { void }
   */
  openFileInNewTab(data: Blob, blobType: string): void {
    const blob = new Blob([data], { type: blobType });
    const fileURL = URL.createObjectURL(blob);
    window.open(fileURL, '_blank');
  }

  /**
   * @description Gets the file type for the download
   * @param { string } fileName Name of file
   * @returns { string | undefined } Type of file
   */
  getFileDownloadType(fileName: string): string | undefined {
    const lastDotIndex = fileName.lastIndexOf('.');
    if (lastDotIndex === -1) {
      return undefined; // No file extension found
    }
    const fileExtension = fileName.slice(lastDotIndex + 1).toLowerCase();
    if (fileExtension === 'pdf') {
      return 'application/pdf';
    }

    return 'application/octet-stream';
  }

  /**
   * @description Check is valid image name
   * @param { string } fileName Name of file to check
   * @returns { boolean } Whether the file name is an allowed image
   */
  isImageFileName(fileName: string): boolean {
    const fileExtension = fileName.split('.').pop()?.toLowerCase();

    const imageExtensions: string[] = [
      'jpg',
      'jpeg',
      'png',
      'gif',
      'bmp',
      'tif',
      'tiff',
      'ico',
    ];

    // Check if the file extension is in the list of image extensions
    return !!fileExtension && imageExtensions.includes(fileExtension);
  }

  uploadFileForTableColumnsAndWorksheetNames(
    file: File,
    hasColumnTitleRow: boolean,
  ): Observable<any> {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('hasColumnTitleRow', String(hasColumnTitleRow));

    const url = `${this._domain}${this._uploadFileForTableColumnsAndWorksheetNamesUrl}`;

    return this.http.post<string[]>(`${url}`, formData);
  }

  /**
   * @description checks if the file extension is valid
   * @param file file to be checked
   * @param fileExtensionPermissionType permission type: allowed or dissallowed
   * @param extensions extensions to check
   * @returns { boolean } if the file extension is valid
   */
  static isValidFileExtension(
    file: File,
    fileExtensionPermissionType: FileExtensionPermissionType | undefined,
    extensions: string[] | undefined,
  ): boolean {
    if (extensions && extensions.length > 0) {
      const fileExtResult = this.allowedOrDisallowedFileExt(
        file.name,
        fileExtensionPermissionType,
        extensions,
      );
      if (fileExtResult) return true;
      return false;
    }
    return true;
  }

  static allowedOrDisallowedFileExt(
    fileName: string,
    fileExtensionPermissionType: FileExtensionPermissionType | undefined,
    extensions: string[],
  ): boolean | undefined {
    switch (fileExtensionPermissionType) {
      case FileExtensionPermissionType.Allowed:
        return extensions?.includes(
          `.${fileName.split('.').pop()?.toLowerCase()}`,
        );
      case FileExtensionPermissionType.Disallowed:
        return !extensions?.includes(
          `.${fileName.split('.').pop()?.toLowerCase()}`,
        );
    }
    return true;
  }

  /**
   * @description checks if the file size is valid
   * @param file file to be checked
   * @param fileSizeLimit file size limit
   * @returns { boolean } if the file size is valid
   */
  static isValidFileSize(
    fileSize: number,
    fileSizeLimit: number | undefined,
  ): boolean {
    if (fileSizeLimit) {
      return fileSize / (1024 * 1024) <= fileSizeLimit;
    }
    return true;
  }

  /**
   * @description checks if the file name is unique
   * @param fileName file name to be checked
   * @param currentFileDetailsList list of file details
   * @returns { boolean } if the file name is unique
   */
  static isFileNameUnique(
    fileName: string,
    currentFileDetailsList: FileUploadDetails[],
  ): boolean {
    return !currentFileDetailsList.some(
      (file) => file.displayName === fileName,
    );
  }
}

export enum ResponseContentType {
  Blob = 'blob',
}
