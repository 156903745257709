import { Component, OnInit } from '@angular/core';
import { WetSignature } from 'src/app/models/WetSignature';
import { QuestionBaseComponent } from '../question-base-component';

@Component({
  selector: 'wet-signature',
  templateUrl: './wet-signature.component.html',
})
export class WetSignatureComponent
  extends QuestionBaseComponent<string>
  implements OnInit
{
  wetSignature!: WetSignature;

  ngOnInit(): void {
    this.wetSignature = this.question as WetSignature;
  }
}
