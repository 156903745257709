import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { FFxUser } from '../../../models/PersonOrGroup';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PersonOrGroupService {
  private _domain: string = environment.ffxFlowApiUrl;

  constructor(private _httpClient: HttpClient) {}

  /**
   * Retrieves a list of people and groups based on the provided search value.
   * @param searchValue The search value to filter the list of people and groups.
   * @returns An Observable of an array of PersonOrGroup objects.
   */
  GetPeopleAndGroups(searchValue: string): Observable<FFxUser[]> {
    const requestUrl = `${this._domain}/Users?searchvalue=${searchValue}`;

    return this._httpClient.get<FFxUser[]>(requestUrl);
  }

  /**
   * Retrieves a list of people and groups based on the provided search value for a specific question in a Flow.
   * @param flowId The ID of the Flow.
   * @param questionId The ID of the question.
   * @param searchValue The search value to filter the list of people and groups.
   * @returns An Observable of an array of PersonOrGroup objects.
   */
  GetPeopleAndGroupsQuestion(
    flowId: string,
    questionId: string,
    searchValue: string,
  ): Observable<FFxUser[]> {
    const requestUrl = `${this._domain}/Flow/${flowId}/Question/${questionId}/Users?searchvalue=${searchValue}`;

    return this._httpClient.get<FFxUser[]>(requestUrl);
  }
}
