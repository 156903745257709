import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { ButtonSize, ButtonType } from '@flowforma/ff-components';
import { AvailableComponent } from '../form/form.component';
import { EventType, FlowEvent, FlowStepEvent } from '../../../models/FlowEvent';
import { FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'workflow-history',
  templateUrl: './workflow-history.component.html',
  styleUrls: ['./workflow-history.component.scss'],
})
export class WorkflowHistoryComponent implements OnChanges {
  @Input() flowTitle!: string;
  @Input() flowFormTitle!: string;
  @Input() flowEvents: FlowEvent[] = [];
  @Input() allStepsTitles: Map<string, string> = new Map<string, string>();

  @Output() changeComponentView = new EventEmitter<AvailableComponent>();

  flowEventsOrderEnum: SortType = SortType.Descending;
  displayedSortOptionEnum: SortType = SortType.Ascending;
  buttonTypeEnum: typeof ButtonType = ButtonType;
  buttonSizeEnum: typeof ButtonSize = ButtonSize;
  buttonFilterEnum: ButtonType = ButtonType.Secondary;
  transitionTypeEnum: typeof TransitionType = TransitionType;

  copyFlowEvents: FlowEvent[] = [];
  acopyFlowEvents: FlowEvent[] = [];
  showFilter: boolean = false;
  actionOptions: string[] = [
    ...Object.values(FlowEventName),
    ...Object.values(StepEventName),
  ];
  currentParallelStepGroup: string | undefined;

  public form = new FormGroup({
    action: new FormControl(''),
    startDate: new FormControl(''),
    endDate: new FormControl(''),
    user: new FormControl([] as string[]),
  });

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['flowEvents']) {
      this.flowEvents.sort((a, b) => {
        return (
          new Date(a.eventDate).getTime() - new Date(b.eventDate).getTime()
        );
      });
      this.flowEvents.reverse();
      this.copyFlowEvents = [...this.flowEvents];
    }
  }

  returnBack(): void {
    this.changeComponentView.emit(AvailableComponent.FormSummary);
  }

  sortEvents(sortOrder: SortType): void {
    this.copyFlowEvents.reverse();
    this.flowEventsOrderEnum = sortOrder;
    this.displayedSortOptionEnum =
      sortOrder === SortType.Ascending
        ? SortType.Descending
        : SortType.Ascending;

    this.resetCurrentParallelStepGroup();
  }

  filterAndSortEvents(): void {
    this.changeFilterColor();

    this.copyFlowEvents = [...this.flowEvents].filter(
      (event) =>
        this.filterByAction(event) &&
        this.filterByDate(event) &&
        this.filterByUser(event),
    );

    if (this.flowEventsOrderEnum !== SortType.Descending)
      this.copyFlowEvents.reverse();

    this.resetCurrentParallelStepGroup();
  }

  toggleFilter(): void {
    this.showFilter = !this.showFilter;
  }

  getEventUser(event: FlowEvent): string {
    return event.userDisplayName;
  }

  getEventText(event: FlowEvent): string {
    if ((event as FlowStepEvent).stepId) {
      return this.mapEventTypeToEventName(event.eventType, StepEventName);
    }
    return this.mapEventTypeToEventName(event.eventType, FlowEventName);
  }

  getStepName(event: FlowEvent): string {
    if ((event as FlowStepEvent).stepId) {
      const stepId = (event as FlowStepEvent).stepId!;
      return this.allStepsTitles.get(stepId) ?? '';
    }
    return '';
  }

  getEventIcon(event: FlowEvent): string {
    if ((event as FlowStepEvent).stepId) {
      return this.mapEventTypeToEventIcon(event.eventType, StepEventIcon);
    }
    return this.mapEventTypeToEventIcon(event.eventType, FlowEventIcon);
  }

  changeFilterColor(): void {
    this.buttonFilterEnum =
      this.form.controls.action.value?.length ||
      this.form.controls.startDate.value !== '' ||
      this.form.controls.endDate.value !== ''
        ? ButtonType.Primary
        : ButtonType.Secondary;
  }

  getEventUsers(): string[] {
    return [...new Set(this.flowEvents.map((event) => event.userDisplayName))];
  }

  private filterByAction(event: FlowEvent): boolean {
    const actionValue = this.form.controls.action.value;
    if (actionValue && actionValue.length > 0) {
      return actionValue.includes(
        (event as FlowStepEvent).stepId
          ? this.mapEventTypeToEventName(event.eventType, StepEventName)
          : this.mapEventTypeToEventName(event.eventType, FlowEventName),
      );
    }
    return true;
  }

  private filterByDate(event: FlowEvent): boolean {
    const startDate = this.form.controls.startDate.value;
    const endDate = this.form.controls.endDate.value;
    return (
      (!startDate ||
        new Date(event.eventDate).getTime() >= new Date(startDate).getTime()) &&
      (!endDate ||
        new Date(event.eventDate).getTime() <= new Date(endDate).getTime())
    );
  }

  private filterByUser(event: FlowEvent): boolean {
    const users = this.form.controls.user.value;
    if (!users || users.length === 0) {
      return true;
    }

    return users.includes(event.userDisplayName);
  }

  private mapEventTypeToEventName(
    eventType: EventType,
    eventNames: Record<string, string>,
  ): string {
    return eventNames[eventType];
  }

  private mapEventTypeToEventIcon(
    eventType: EventType,
    eventIcons: Record<string, string>,
  ): string {
    return eventIcons[eventType];
  }

  isEventParallel(event: FlowStepEvent): boolean {
    return !!event.parallelStepGroupId;
  }

  isLastEvent(event: FlowStepEvent): boolean {
    return (
      this.copyFlowEvents.findIndex((e) => e === event) ===
      this.copyFlowEvents.length - 1
    );
  }

  isFirstEvent(event: FlowStepEvent): boolean {
    return this.copyFlowEvents.findIndex((e) => e === event) === 0;
  }

  /**
   * Determines the type of transition between two events
   * @param event event to check
   * @returns transition type
   */
  isTransition(event: FlowEvent): TransitionType {
    const stepEvent = event as FlowStepEvent;

    if (
      this.currentParallelStepGroup == stepEvent.parallelStepGroupId ||
      (!!this.currentParallelStepGroup && !!stepEvent.parallelStepGroupId)
    ) {
      return TransitionType.StraightLine;
    }

    this.currentParallelStepGroup = stepEvent.parallelStepGroupId;

    if (!stepEvent.parallelStepGroupId) {
      return TransitionType.ParallelGroupEnd;
    }

    return TransitionType.ParallelGroupStart;
  }

  private resetCurrentParallelStepGroup() {
    if ((this.copyFlowEvents[0] as FlowStepEvent).stepId) {
      const stepEvent = this.copyFlowEvents[0] as FlowStepEvent;
      this.currentParallelStepGroup = stepEvent.parallelStepGroupId;
    } else {
      this.currentParallelStepGroup = undefined;
    }
  }
}

export enum FlowEventIcon {
  Started = 'bi-plus-circle-dotted',
  Completed = 'bi-check-circle-fill text-success',
}

export enum StepEventIcon {
  Started = 'bi-plus-square-dotted',
  Submitted = 'bi-check-square',
  Saved = 'bi-save',
  Delegated = 'bi-box-arrow-in-down-right',
  ReOpen = 'bi-reply-all-fill text-warning',
}

export enum StepEventName {
  Started = 'Step started',
  Submitted = 'Step completed',
  Saved = 'Step saved',
  Delegated = 'Delegated',
  ReOpen = 'Reopened',
  Comment = 'Comments',
}

export enum FlowEventName {
  Started = 'Form started',
  Completed = 'Form submitted',
}

export enum SortType {
  Ascending = 'Newest first',
  Descending = 'Oldest first',
}

export enum TransitionType {
  StraightLine = 'Straight line',
  ParallelGroupStart = 'Parallel group start',
  ParallelGroupEnd = 'Parallel group end',
}
