import { FileUploadStorageType } from '@flowforma/ff-components';

export class Attachment {
  id!: string;
  displayName?: string;
  relativeFilePath?: string;
  storageType!: FileUploadStorageType;
}

export enum StepAttachmentAction {
  Upload,
  Delete,
}
