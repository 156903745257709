export enum RuleTriggerEvent {
  // Question level events
  QuestionUpdated = 'QuestionUpdated',

  // Step level events
  StepCompleting = 'StepCompleting',
  StepStarting = 'StepStarting',
  StepSaving = 'StepSaving',

  // Form level events
  FormCompleted = 'FormCompleted',
  FormStarted = 'FormStarted',
  FormLoaded = 'FormLoaded',
  FormRejected = 'FormRejected',
}
