import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IStep } from '../../../models/IStep';

@Component({
  selector: 'step-remarks',
  templateUrl: './step-remarks.component.html',
})
export class StepRemarksComponent {
  @Input() step!: IStep;

  @Output() questionIdToAnchor = new EventEmitter<string>();

  anchorToQuestion(questionId: string): void {
    this.questionIdToAnchor.emit(questionId);
  }

  get overallCount(): number {
    return this.step.notes?.length ?? 0;
  }

  get inlineCount(): number {
    return this.step.questions.filter((q) => q.commentThread).length || 0;
  }
}
