import { Question } from './Question';

export class MultipleLinesOfText extends Question<string> {
  numberOfLines?: number;
  numberOfColumns?: number;
  autoSize?: boolean;
  textType?: TextType;
}

export enum TextType {
  PlainText = 'PlainText',
  RichText = 'RichText',
}
