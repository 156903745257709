import { Injectable } from '@angular/core';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import {
  ControlWithValidators,
  CustomFormControl,
  ValidatorNames,
} from 'src/app/components/forms/form/form.component';
import { QuestionEvent } from 'src/app/components/steps/step-detail/step-detail.component';
import { IStep } from 'src/app/models/IStep';
import { BaseQuestion, Question } from 'src/app/models/Question';
import { QuestionType } from 'src/app/models/QuestionType';
import { PatchFlowEventResponse } from 'src/app/models/responses/flow/FlowResponse';
import { Section } from 'src/app/models/Section';
import {
  TableAnswer,
  TableColumn,
  TableQuestion,
} from 'src/app/models/TableQuestion';

@Injectable({
  providedIn: 'root',
})
export class FormControlService {
  questionType: typeof QuestionType = QuestionType;

  addAllQuestionsFormControls(
    questions: BaseQuestion[] | undefined,
    group: CustomFormControl,
  ): void {
    questions?.forEach((question) => {
      switch (question.questionType) {
        case this.questionType.Section:
          this.addSectionQuestionFormControls(group, question);
          break;
        case this.questionType.Table:
          this.addTableQuestionFormControls(group, question);
          break;
        default:
          this.addQuestionFormControl(group, question);
          break;
      }
    });
  }

  addSectionQuestionFormControls(
    group: CustomFormControl,
    question: BaseQuestion,
  ): void {
    const section = question as Section;
    this.addAllQuestionsFormControls(section.questions, group);
  }

  addTableQuestionFormControls(
    group: CustomFormControl,
    question: BaseQuestion,
  ): void {
    const questionTable = question as TableQuestion;
    this.addQuestionFormControl(group, questionTable);

    for (const tableColumn of questionTable.tableColumns ?? []) {
      this.addTableColumnFormControls(tableColumn, group);
    }
  }

  addTableColumnFormControls(
    tableColumn: TableColumn,
    group: CustomFormControl,
  ): void {
    for (const tableCell of tableColumn.cells ?? []) {
      this.addTableCellFormControls(
        tableColumn.headerQuestion,
        tableCell,
        group,
      );
    }
    for (const footerQuestion of tableColumn.footerQuestions ?? []) {
      this.addQuestionFormControl(group, footerQuestion);
    }
  }

  addTableCellFormControls(
    headerQuestion: Question<void>,
    tableCell: TableAnswer,
    group: CustomFormControl,
  ): void {
    const mappedQuestion: Question<typeof tableCell.value> = {
      ...headerQuestion,
      id: tableCell?.id,
      value: tableCell?.value,
    };
    mappedQuestion.enabled = tableCell.enabled;
    mappedQuestion.visible = tableCell.visible;

    this.addQuestionFormControl(group, mappedQuestion);
  }

  removeTableQuestionFormControls(
    tableQuestion: TableQuestion,
    form: FormGroup,
  ): void {
    this.removeQuestionFormControl(tableQuestion, form);

    for (const tableColumn of tableQuestion.tableColumns ?? []) {
      this.removeTableColumnFormControls(tableColumn, form);
    }
  }

  removeTableColumnFormControls(
    tableColumn: TableColumn,
    form: FormGroup,
  ): void {
    for (const tableCell of tableColumn.cells ?? []) {
      this.removeTableCellFormControls(tableCell, form);
    }
    for (const footerQuestion of tableColumn.footerQuestions ?? []) {
      this.removeQuestionFormControl(footerQuestion, form);
    }
  }

  removeTableCellFormControls(tableCell: TableAnswer, form: FormGroup): void {
    form.removeControl(tableCell.id);
  }

  /**
   * @description Adds the question to the group
   * @param { any } group Group the question is to be added to
   * @param { BaseQuestion } question Question to be added to group
   * @returns { void }
   */
  addQuestionFormControl<T>(
    group: CustomFormControl,
    question: Question<T>,
  ): void {
    group[question.id] = new FormControl(
      { value: question.value, disabled: !question.enabled },
      question.required ? Validators.required : null,
    );
    group[question.id].title = question.title;
    group[question.id].id = question.id;
  }

  removeQuestionFormControl(question: BaseQuestion, form: FormGroup): void {
    form.removeControl(question.id);
  }

  loadFormGroup(selectedStep?: IStep): FormGroup {
    const group: CustomFormControl = {};
    this.addAllQuestionsFormControls(selectedStep?.questions, group);
    return new FormGroup(group);
  }

  getControl(controlName: string, form: FormGroup) {
    return form.get(controlName);
  }

  addValidationMessageToControl(
    questionId: string,
    form: FormGroup,
    errorMessage?: string,
  ) {
    const formControlToPatch = this.getControl(questionId, form);
    if (formControlToPatch && errorMessage) {
      formControlToPatch.addValidators(this.customErrorValidator(errorMessage));
      formControlToPatch.markAsDirty();
      formControlToPatch.updateValueAndValidity();
    }
  }

  /**
   * @description Validator for custom error message
   * @param { string } errorMessage Validation error message
   * @returns { ValidatorFn } Validator
   */
  customErrorValidator(errorMessage?: string): ValidatorFn {
    return (_: AbstractControl): ValidationErrors | null => {
      if (errorMessage) {
        return { customError: errorMessage };
      }
      return null;
    };
  }

  /**
   * @description Updates the form value for a question
   * @param { string } questionId The Id of question to update
   * @param { string } value The value that the question is to be updated with
   * @returns { void }
   */
  updateFormValue(questionId: string, value: string, form: FormGroup): void {
    const formToPatch = this.getControl(questionId, form);
    formToPatch?.patchValue(value);
    formToPatch?.updateValueAndValidity();
  }

  /**
   * @description Remove validators from form
   * @param { FormGroup } formGroup FormGroup to be clearer of errors
   * @returns { void }
   */
  clearFormValidatorsOfErrors(formGroup: FormGroup): void {
    Object.keys(formGroup.controls).forEach((formControlName) => {
      const formControl = formGroup.get(formControlName);
      formControl?.clearValidators();
      formControl?.updateValueAndValidity();
    });
  }

  handleCustomValidationOnResponse(
    response: PatchFlowEventResponse,
    questionEvent: QuestionEvent,
    formControlToPatch: AbstractControl<any> | null,
    form: FormGroup,
  ): void {
    if (
      !response.affectedQuestions?.find(
        (af) => af.questionId == questionEvent.questionId,
      )
    ) {
      // Since custom error is only validation allowed, its only one that needs to be removed on return
      // This should skip file upload questions since they have custom validators that are used.
      this.removeOnlyCustomError(formControlToPatch);
    }
    // Affected questions that don't have error messages can have validators removed if exist
    response.affectedQuestions?.forEach((affectedQuestion) => {
      if (!affectedQuestion.errorMessage) {
        const affectedControlToPatch = form.get(affectedQuestion.questionId);
        this.removeOnlyCustomError(affectedControlToPatch);
      }
    });
  }

  /**
   * @description Remove all validators except required.
   * @param { AbstractControl<any> } formControlToPatch The control to remove validators on
   * @returns { void }
   */
  removeOnlyCustomError(formControlToPatch: AbstractControl<any> | null): void {
    const formValidators = (formControlToPatch as ControlWithValidators)
      ?._rawValidators;
    // If no validators exist return
    if (!formValidators) {
      return;
    }
    const filteredFormValidators = formValidators.filter((x) =>
      Object.values(ValidatorNames).includes(x.name),
    );

    this.clearValidationMessage(formControlToPatch);
    formControlToPatch?.setValidators(filteredFormValidators);
    formControlToPatch?.updateValueAndValidity();
  }

  /**
   * @description Clears error from control, and remove validators
   * @param { AbstractControl<any> } formControlToPatch The control to remove validators on
   * @returns { void }
   */
  clearValidationMessage(
    formControlToPatch: AbstractControl<any> | null,
  ): void {
    formControlToPatch?.setErrors(null);
    formControlToPatch?.clearValidators();
    formControlToPatch?.updateValueAndValidity();
  }
}
