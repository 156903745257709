<div [formGroup]="form">
  <div *ngIf="!readOnly">
    <ff-select
      [formControlName]="sqlLookup.id"
      [tooltip]="sqlLookup.description"
      [label]="sqlLookup.title"
      [id]="sqlLookup.id"
      [filter]="true"
      [showClear]="true"
      [placeHolder]="'Please select'"
      [required]="sqlLookup.required"
      [showTitle]="sqlLookup.showTitle"
      [enabled]="sqlLookup.enabled"
      [options]="options"
      (searchOptions)="retrieveOptions()"
      (OnChange)="convertValue($event)"
      [showErrorText]="showErrorText"
      [hasOptionSearch]="true"
      [autoOptionFocus]="false"
      [panelStyleClass]="'ffx-select-panel'"
    >
      <ng-container *ngTemplateOutlet="commentIcon"></ng-container>
    </ff-select>
  </div>
  <div *ngIf="readOnly">
    <ff-textbox
      [tooltip]="sqlLookup.description"
      [label]="sqlLookup.title"
      [id]="sqlLookup.id"
      [required]="sqlLookup.required"
      [showTitle]="sqlLookup.showTitle"
      [showComment]="showComment"
      [enabled]="sqlLookup.enabled"
      [value]="sqlLookup.value ?? ''"
    >
      <ng-container *ngTemplateOutlet="commentIcon" />
    </ff-textbox>
  </div>
</div>

<ng-template #commentIcon>
  <ng-content></ng-content>
</ng-template>
