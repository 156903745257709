<form-card>
  <div class="mb-3">
    <img id="image0_983_146782" [src]="logo" class="col-2" alt="Logo" />
  </div>

  <form-name
    class="d-block mb-4"
    [flowTitle]="flowTitle!"
    [formTitle]="formTitle!"
  ></form-name>

  <ff-message-divider
    [message]="message"
    [alertType]="alertTypeEnum.Success"
    [title]="'Submitted'"
    [subTitle]="stepSubTitle"
  />

  <div class="my-4">
    <h6 class="fw-bold">Step Output(s)</h6>
    <span *ngIf="!attachments || !attachments.length" class="ms-3"
      >No outputs in step</span
    >
    <span class="ms-3 d-flex gap-2" *ngFor="let attachment of attachments">
      <i class="bi bi-file-earmark text-primary"></i>
      <button
        class="btn text-start text-primary p-0 m-0 flex-grow-1 btn-text"
        (click)="attachmentLinkClick(attachment)"
      >
        {{ attachment.displayName }}
      </button>
    </span>
  </div>

  <ff-separator class="d-block my-4"></ff-separator>

  <div class="col-md-6 col-12 pb-4">
    <ff-text [text]="'Found this easy?'"></ff-text>

    <div class="col-12 pt-4">
      <div class="d-flex gap-2 flex-row">
        <h3 class="m-0">
          <span
            class="review rounded-3 p-1"
            role="button"
            [ngClass]="{
              active: stepFeedback.type === feedbackTypeEnum.Positive
            }"
            (click)="onFeedbackClick(feedbackTypeEnum.Positive)"
            >👍</span
          >
        </h3>
        <h3 class="m-0">
          <span
            class="review rounded-3 p-1"
            role="button"
            [ngClass]="{
              active: stepFeedback.type === feedbackTypeEnum.Negative
            }"
            (click)="onFeedbackClick(feedbackTypeEnum.Negative)"
            >👎</span
          >
        </h3>
      </div>
      <div
        *ngIf="stepFeedback.type === feedbackTypeEnum.Negative"
        class="mt-2 feedbackInput"
        [formGroup]="form"
      >
        <ff-textarea
          [id]="'StepSubmittedFeedback'"
          formControlName="feedback"
          [enabled]="true"
          [numberOfLines]="3"
          [placeHolder]="'Tell us why'"
          [maxlength]="300"
          (OnKeyUp)="keyUp($event)"
          (OnChange)="changeFeedback($event)"
        ></ff-textarea>
        <div class="pt-1 fw-bold text-muted">
          {{ characterCount }}/{{ maxCharacterLimit }}
        </div>
      </div>
    </div>
  </div>

  <div
    class="col-12 mt-5 form-detail ms-auto d-flex justify-content-end gap-2 flex-column flex-sm-row"
  >
    <button
      (click)="clickAction(stepSubmittedActionEnum.View)"
      type="button"
      class="btn btn-outline-primary"
    >
      View submitted step
    </button>
    <button
      (click)="clickAction(stepSubmittedActionEnum.Exit)"
      *ngIf="lastStep"
      type="button"
      class="btn btn-primary"
    >
      Go to dashboard
    </button>
    <button
      (click)="clickAction(stepSubmittedActionEnum.Proceed)"
      *ngIf="!lastStep"
      type="button"
      class="btn btn-primary"
    >
      Proceed to next step
    </button>
  </div>
</form-card>
