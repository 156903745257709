<div [formGroup]="form">
  <ff-signature-pad
    [placeHolder]="wetSignature.placeholderText"
    [formControlName]="wetSignature.id"
    [tooltip]="wetSignature.description"
    [label]="wetSignature.title"
    [id]="wetSignature.id"
    [required]="wetSignature.required"
    [showTitle]="wetSignature.showTitle"
    [enabled]="wetSignature.enabled"
    [readOnly]="readOnly"
    [subtitle]="'Add your signature here'"
    [showSubtitle]="shouldShowSubtitle"
    (OnChange)="change($event)"
    class="d-inline-block"
    [showErrorText]="showErrorText"
  >
    <ng-container *ngTemplateOutlet="commentIcon"></ng-container>
  </ff-signature-pad>
</div>

<ng-template #commentIcon>
  <ng-content></ng-content>
</ng-template>
