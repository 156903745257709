import { Component, Input } from '@angular/core';

@Component({
  selector: 'form-name',
  templateUrl: './form-name.component.html',
})
export class FormNameComponent {
  @Input() flowTitle!: string;
  @Input() formTitle!: string;
}
