import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { KeyValuePair } from 'src/app/models/SharePointLookup';
import { SqlLookupOptionsResponse } from 'src/app/models/SqlLookup';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class LookupService {
  private _domain: string = environment.ffxFlowApiUrl;

  constructor(private _httpClient: HttpClient) {}

  GetSharePointLookupQuestionOptions(
    flowId: string,
    questionId: string,
    tableId?: string,
  ): Observable<KeyValuePair[]> {
    const requestUrl = tableId
      ? `${this._domain}/Flow/${flowId}/Question/${tableId}/SharePointLookupOptions?cellid=${questionId}`
      : `${this._domain}/Flow/${flowId}/Question/${questionId}/SharePointLookupOptions`;

    return this._httpClient.get<KeyValuePair[]>(requestUrl);
  }

  GetSqlLookupQuestionOptions(
    flowId: string,
    questionId: string,
    tableId?: string,
  ): Observable<SqlLookupOptionsResponse> {
    const requestUrl = tableId
      ? `${this._domain}/Flow/${flowId}/Question/${tableId}/SqlLookupOptions?cellid=${questionId}`
      : `${this._domain}/Flow/${flowId}/Question/${questionId}/SqlLookupOptions`;

    return this._httpClient.get<SqlLookupOptionsResponse>(requestUrl);
  }
}
