<div class="modal-header">
  <h5 class="modal-title">Upload File(s) to Table</h5>
  <i class="bi bi-x-lg" (click)="closeModal()"></i>
</div>

<div class="modal-body">
  <div [formGroup]="form">
    <div class="container mt-1">
      <div class="row">
        <div class="col-md-4 ps-0">
          <ff-upload
            [formControlName]="formControlNamesConst.FileInput"
            [extensions]="allowedFileTypes"
            [allowMultiple]="false"
            [fileExtensionPermissionType]="fileExtensionPermissionType.Allowed"
            (OnChange)="change($event)"
            [files]="getFileArray()"
            class="d-block"
          >
          </ff-upload>
        </div>
        <div class="col-md-8 ps-0">
          <p-checkbox
            [formControlName]="formControlNamesConst.HasColumnHeaderRow"
            styleClass="ffx-checkbox"
            label="Has Column Title Row"
            binary="true"
            class="mt-3"
          ></p-checkbox>
        </div>
      </div>
    </div>

    <div *ngFor="let file of validFiles; let i = index">
      <ff-upload-list-file-item
        [fileName]="file.name"
        [readOnly]="false"
        (onDelete)="deleteValidFile(i)"
      ></ff-upload-list-file-item>
    </div>
    <div *ngFor="let file of invalidFiles; let i = index">
      <ff-upload-list-file-item
        [fileName]="file.name"
        [readOnly]="false"
        [isFileValid]="false"
        (onDelete)="deleteInvalidFile(i)"
      ></ff-upload-list-file-item>
    </div>

    <ff-multiselect
      *ngIf="validFiles?.length === 1 && isExcelFile(validFiles![0])"
      [formControlName]="formControlNamesConst.SelectedWorksheetNames"
      [tooltip]="'Select worksheet(s) to upload to table'"
      [label]="'Worksheet(s)'"
      [id]="'worksheetName'"
      [required]="true"
      [enabled]="true"
      [options]="allSourceWorksheetNames"
      [showTitle]="true"
      [showErrorText]="false"
      class="mt-3 d-block"
    >
    </ff-multiselect>

    <ff-select
      [formControlName]="formControlNamesConst.MappingType"
      [tooltip]="'Select mapping type for how file gets uploaded to table'"
      [label]="'Mapping Type'"
      [id]="'mappingType'"
      [required]="true"
      [enabled]="true"
      [options]="choices"
      [showTitle]="true"
      [showErrorText]="false"
      class="mt-3 d-block"
    >
    </ff-select>

    <ff-label
      [label]="'Column Mappings'"
      [tooltip]="'Select file column to map to table column'"
      class="mt-3 d-block column-mappings-label"
    >
    </ff-label>
    <div class="container mt-1">
      <div class="row">
        <div class="col-md-5 ps-0">
          <ff-label [label]="'Column Source'" class="mt-1 d-block"> </ff-label>
        </div>
        <div class="col-md-5 ps-0">
          <ff-label [label]="'Column Target'" class="mt-1 d-block"> </ff-label>
        </div>
        <div class="col-md-2 ps-0">
          <label
            tabindex="0"
            class="btn btn-outline-primary cursor-pointer p-0 table-upload-btn"
            title="Auto Map Columns"
            [class.align-add-btn]="mappings.length === 1"
            (click)="autoMapColumns()"
          >
            <div class="d-flex px-2 py-1">
              <i class="bi bi-arrow-clockwise"></i>
            </div>
          </label>
        </div>
      </div>
    </div>
    <div class="mapping-rows container mt-1" formArrayName="mappings">
      <div
        class="row mb-3"
        *ngFor="let mappingControl of mappings.controls; let i = index"
        [formGroupName]="i"
      >
        <div class="col-md-5 ps-0">
          <p-dropdown
            required="true"
            [options]="allSourcetableColumns"
            placeholder="Select column"
            class="mt-3 d-block"
            [formControlName]="formControlNamesConst.SourceColumnName"
            appendTo="body"
            [class.required-dropdown]="
              !mappings.at(i).get(formControlNamesConst.SourceColumnName)
                ?.valid &&
              mappings.at(i).get(formControlNamesConst.SourceColumnName)
                ?.touched
            "
          >
          </p-dropdown>
        </div>
        <div class="col-md-5 ps-0">
          <p-dropdown
            [options]="tableColumnsTarget"
            optionLabel="displayValue"
            optionValue="value"
            placeholder="Select column"
            class="mt-3 d-block"
            [formControlName]="formControlNamesConst.TargetColumnId"
            appendTo="body"
            [class.required-dropdown]="
              !mappings.at(i).get(formControlNamesConst.TargetColumnId)
                ?.valid &&
              mappings.at(i).get(formControlNamesConst.TargetColumnId)?.touched
            "
          >
          </p-dropdown>
        </div>
        <div
          class="col-md-2 d-flex justify-content-end align-items-center pt-3"
        >
          <label
            tabindex="0"
            class="btn btn-outline-primary cursor-pointer p-0 table-upload-btn"
            title="Add mapping"
            [class.align-add-btn]="mappings.length === 1"
            (click)="addMapping()"
          >
            <div class="d-flex px-2 py-1">
              <i class="bi bi-plus-square"></i>
            </div>
          </label>
          <label
            tabindex="0"
            *ngIf="mappings.length > 1"
            class="btn btn-outline-primary cursor-pointer p-0 table-upload-btn"
            title="Delete Mapping"
            (click)="deleteMapping(i)"
          >
            <div class="d-flex px-2 py-1">
              <i class="bi bi-trash"></i>
            </div>
          </label>
        </div>
      </div>
    </div>
  </div>

  <div class="modal-footer border-0">
    <button
      type="button"
      class="btn btn-outline-primary"
      (click)="closeModal()"
    >
      Cancel
    </button>
    <button type="button" class="btn btn-primary" (click)="confirmFileUpload()">
      Upload File
    </button>
  </div>
</div>
