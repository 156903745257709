<div [formGroup]="form">
  <ng-container *ngIf="slt && !readOnly">
    <ff-input-text
      *ngIf="slt.format !== 'Hyperlink'"
      [formControlName]="slt.id"
      [tooltip]="slt.description"
      [label]="slt.title"
      [id]="slt.id"
      [required]="slt.required!"
      [showTitle]="slt.showTitle"
      [maxLength]="slt.maxLength"
      [enabled]="slt.enabled"
      [isInputReadOnly]="isCalculatedQuestion"
      [showErrorText]="showErrorText"
      (OnChange)="change($event)"
    >
      <ng-container *ngTemplateOutlet="commentIcon"></ng-container>
    </ff-input-text>

    <ff-hyperlink
      *ngIf="slt.format === 'Hyperlink'"
      [formControlName]="slt.id"
      [tooltip]="slt.description"
      [label]="slt.title"
      [id]="slt.id"
      [required]="slt.required!"
      [showTitle]="slt.showTitle"
      [enabled]="slt.enabled"
      [isInputReadOnly]="isCalculatedQuestion"
      [showErrorText]="showErrorText"
      [hyperlinkLabel]="slt.label"
    >
      <ng-container *ngTemplateOutlet="commentIcon"></ng-container>
    </ff-hyperlink>
  </ng-container>

  <ff-textbox
    *ngIf="slt && readOnly"
    [tooltip]="slt.description"
    [label]="slt.title"
    [id]="slt.id"
    [required]="slt.required!"
    [showTitle]="slt.showTitle"
    [value]="slt.value"
    [showComment]="showComment"
    [enabled]="slt.enabled"
    [isHyperlink]="slt.format === 'Hyperlink'"
    [hyperlinkLabel]="slt.label"
  >
    <ng-container *ngTemplateOutlet="commentIcon"></ng-container>
  </ff-textbox>
</div>

<ng-template #commentIcon>
  <ng-content></ng-content>
</ng-template>
