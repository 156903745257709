<form-card>
  <div class="mb-3">
    <img id="image0_983_146782" [src]="logo" class="col-2" alt="Logo" />
  </div>

  <form-name
    class="d-block mb-3"
    [flowTitle]="flow.title"
    [formTitle]="flow.formTitle"
  ></form-name>

  <div class="alert alert-info alert-divider-information mb-3">
    <ff-text class="d-block fw-bold mb-2" text="Completed"
      ><i class="bi bi-check-circle-fill me-2"></i
    ></ff-text>
    <ff-text class="d-block fs-7 mb-2">
      {{ flow.dateStarted | longDatePipe }} -
      {{ flow.dateCompleted | longDatePipe }}</ff-text
    >
    <div class="d-flex">
      <ff-text
        *ngFor="let contributor of flow.contributors"
        [text]="contributor"
        class="me-4"
      >
        <i class="bi bi-person-circle h5 text-primary me-2"></i>
      </ff-text>
    </div>
  </div>

  <ff-button
    [id]="'FormSummaryViewHistoryButton'"
    class="d-block mb-3"
    [buttonType]="buttonTypeEnum.Secondary"
    [textValue]="'View form history'"
    [enabled]="true"
    [buttonSize]="buttonSizeEnum.Small"
    [buttonTooltip]="false"
    (OnClick)="openWorkflowHistory()"
    ><i class="bi bi-clock-history" Icon></i
  ></ff-button>

  <ff-button
    [id]="'FormSummaryReopenFormButton'"
    class="d-block mb-3"
    [buttonType]="buttonTypeEnum.Warning"
    [textValue]="'Re-open form'"
    [enabled]="true"
    [buttonSize]="buttonSizeEnum.Small"
    [buttonTooltip]="false"
    (OnClick)="reopenForm()"
    ><i class="bi bi-reply-all-fill" Icon></i
  ></ff-button>

  <div class="pt-3 mb-4">
    <ff-text class="d-block fs-4 fw-medium" text="Outputs"></ff-text>
    <ff-separator class="d-block"></ff-separator>
  </div>

  <div ngbAccordion>
    <div ngbAccordionItem class="border d-none">
      <h2 ngbAccordionHeader>
        <button ngbAccordionButton class="p-3">
          <ff-text class="fw-semibold" text="Form output"></ff-text>
        </button>
      </h2>
      <div ngbAccordionCollapse>
        <div ngbAccordionBody class="py-0">
          <ng-template></ng-template>
        </div>
      </div>
    </div>
    <div ngbAccordionItem class="border d-none">
      <h2 ngbAccordionHeader>
        <button ngbAccordionButton class="p-3">
          <ff-text class="fw-semibold" text="Linked databases"></ff-text>
        </button>
      </h2>
      <div ngbAccordionCollapse>
        <div ngbAccordionBody class="py-0">
          <ng-template></ng-template>
        </div>
      </div>
    </div>
    <div ngbAccordionItem class="border">
      <h2 ngbAccordionHeader>
        <button ngbAccordionButton class="p-3">
          <ff-text
            class="fw-semibold"
            [text]="'Uploaded documents' + getUploadedDocumentsCountSuffix()"
          ></ff-text>
        </button>
      </h2>
      <div ngbAccordionCollapse>
        <div ngbAccordionBody class="py-0">
          <ng-template>
            <div *ngFor="let step of flow.steps" class="d-block mb-2 pt-2">
              <ff-text class="fw-semibold" [text]="step.title"></ff-text>
              <span
                *ngIf="
                  !step.attachments || !step.attachments.length;
                  else attachmentsFound
                "
                >No uploaded documents in step</span
              >
              <ng-template #attachmentsFound>
                <span
                  class="d-flex gap-2"
                  *ngFor="let attachment of step.attachments"
                >
                  <ff-upload-list-file-item
                    [fileName]="attachment.displayName"
                    [readOnly]="true"
                    (onClick)="attachmentLinkClick(attachment)"
                  ></ff-upload-list-file-item>
                </span>
              </ng-template>
            </div>
          </ng-template>
        </div>
      </div>
    </div>
  </div>
</form-card>
