<form-card>
  <div class="d-flex flex-column gap-2">
    <ff-button
      [buttonType]="buttonTypeEnum.Secondary"
      [id]="'WorkflowHistoryBackButton'"
      [textValue]="'Back'"
      [enabled]="true"
      [buttonSize]="buttonSizeEnum.Small"
      [buttonTooltip]="false"
      (OnClick)="returnBack()"
      ><i class="bi bi-arrow-left" Icon></i
    ></ff-button>
    <div>
      <ff-text
        class="d-block fs-4 fw-medium my-3"
        [text]="'Form History'"
      ></ff-text>
      <form-name
        class="d-block"
        [flowTitle]="flowTitle"
        [formTitle]="flowFormTitle"
      ></form-name>
    </div>
  </div>
  <ff-separator></ff-separator>
  <div class="d-flex flex-row justify-content-between align-items-center">
    <a
      class="cursor-pointer text-decoration-none"
      (click)="sortEvents(displayedSortOptionEnum)"
    >
      <ff-text
        class="fs-7 text-primary align-self-center py-2"
        [text]="displayedSortOptionEnum"
        ><i class="bi bi-arrow-down-up me-2"></i
      ></ff-text>
    </a>
    <ff-button
      [id]="'WorkflowHistoryToggleFilterButton'"
      class="py-2"
      [buttonType]="
        showFilter ? buttonTypeEnum.Primary : buttonTypeEnum.Secondary
      "
      [enabled]="true"
      [buttonSize]="buttonSizeEnum.Small"
      [buttonTooltip]="false"
      (OnClick)="toggleFilter()"
      ><i class="bi bi-filter-circle" Icon></i
    ></ff-button>
  </div>
  <div
    [ngClass]="{
      'd-none': !showFilter,
      'd-flex': showFilter
    }"
    class="mb-3 d-flex flex-row justify-content-between gap-3 flex-column flex-md-row"
    [formGroup]="form"
  >
    <ff-multiselect
      formControlName="action"
      [id]="'WorkflowHistoryActionFilter'"
      class="w-100"
      [label]="'Filter by action'"
      [options]="actionOptions"
      [placeHolder]="'Please select'"
      (OnChange)="filterAndSortEvents()"
    ></ff-multiselect>
    <div class="w-100 d-flex flex-column">
      <ff-label
        [label]="'Filter by date'"
        [controlId]="'WorkflowHistoryStartDateEndDateFilters'"
      ></ff-label>
      <div class="w-100 d-flex flex-row align-items-center gap-2">
        <ff-date-only
          formControlName="startDate"
          [id]="'WorkflowHistoryStartDateFilter'"
          class="w-50"
          [placeHolder]="'Start date'"
          (OnChange)="filterAndSortEvents()"
        ></ff-date-only>
        <ff-date-only
          formControlName="endDate"
          [id]="'WorkflowHistoryEndDateFilter'"
          class="w-50"
          [placeHolder]="'End date'"
          (OnChange)="filterAndSortEvents()"
        ></ff-date-only>
      </div>
    </div>
    <ff-multiselect
      formControlName="user"
      [id]="'WorkflowHistoryUserFilter'"
      class="w-100"
      [label]="'Filter by user'"
      [placeHolder]="'Please select'"
      [options]="getEventUsers()"
      (OnChange)="filterAndSortEvents()"
    ></ff-multiselect>
  </div>
  <div
    *ngFor="let event of copyFlowEvents"
    class="position-relative pb-3 workflow-block"
    [ngClass]="{
      'ps-4': !isEventParallel(event),
      'ps-40': isEventParallel(event)
    }"
  >
    <ng-container *ngIf="isTransition(event) as transitionResult">
      <!-- Parallel line start -->
      <ng-container
        *ngIf="transitionResult === transitionTypeEnum.ParallelGroupStart"
      >
        <div
          class="parallel-svg-position position-absolute"
          [ngClass]="{
            'd-none': isFirstEvent(event)
          }"
        >
          <svg
            width="12"
            height="19"
            viewBox="0 0 12 19"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            class="align-top"
          >
            <path
              d="
          M1 0
          V0.888888
          C1 2.9619 2.07009 4.88805 3.83019 5.98323
          L8.16981 8.68344
          C9.92991 9.77861 11 11.7048 11 13.7778
          V19"
              class="parallel-line-svg"
            />
          </svg>
        </div>
        <div
          class="border h-25 position-absolute parallel-line-start"
          [ngClass]="{
            'd-none': isFirstEvent(event)
          }"
        ></div>

        <div
          class="border position-absolute parallel-line-start pg-line"
          [ngClass]="{
            'h-25': isLastEvent(event) || isFirstEvent(event),
            'h-50': !isLastEvent(event) && !isFirstEvent(event)
          }"
        ></div>

        <div
          class="border h-50 position-absolute"
          [ngClass]="{
            'bottom-line': !isEventParallel(event),
            'bottom-parallel-line': isEventParallel(event),
            'd-none': isLastEvent(event)
          }"
        ></div>
        <div
          *ngIf="isEventParallel(event) && !isLastEvent(event)"
          class="border h-50 position-absolute bottom-parallel-line pg-line"
        ></div>
      </ng-container>

      <!-- Parallel line end -->
      <ng-container
        *ngIf="transitionResult === transitionTypeEnum.ParallelGroupEnd"
      >
        <div class="parallel-svg-position position-absolute">
          <svg
            width="12"
            height="19"
            viewBox="0 0 12 19"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            class="align-top"
          >
            <path
              d="
              M1 19
              V18.1111
              C1 16.0381 2.07009 14.1119 3.83019 13.0168
              L8.16981 10.3166
              C9.92991 9.22139 11 7.29523 11 5.22222
              V0"
              class="parallel-line-svg"
            />
          </svg>
        </div>
        <div
          class="border h-25 position-absolute parallel-line-end"
          [ngClass]="{
            'd-none': isFirstEvent(event)
          }"
        ></div>
        <div
          class="border h-50 position-absolute"
          [ngClass]="{
            'bottom-line': !isEventParallel(event),
            'bottom-parallel-line': isEventParallel(event),
            'd-none': isFirstEvent(event) || isLastEvent(event)
          }"
        ></div>

        <div
          class="border position-absolute parallel-line-start pg-line"
          [ngClass]="{
            'h-25': isFirstEvent(event),
            'h-50': !isFirstEvent(event)
          }"
        ></div>
      </ng-container>

      <!-- Straight lines -->
      <ng-container
        *ngIf="transitionResult === transitionTypeEnum.StraightLine"
      >
        <div
          class="border h-50 position-absolute"
          [ngClass]="{
            'top-line': !isEventParallel(event),
            'top-parallel-line': isEventParallel(event),
            'd-none': isFirstEvent(event)
          }"
        ></div>

        <div
          *ngIf="isEventParallel(event) && !isFirstEvent(event)"
          class="border h-50 position-absolute top-parallel-line pg-line"
        ></div>
        <div
          class="border h-50 position-absolute"
          [ngClass]="{
            'bottom-line': !isEventParallel(event),
            'bottom-parallel-line': isEventParallel(event),
            'd-none': isLastEvent(event)
          }"
        ></div>

        <div
          *ngIf="isEventParallel(event) && !isLastEvent(event)"
          class="border h-50 position-absolute bottom-parallel-line pg-line"
        ></div>
      </ng-container>
    </ng-container>

    <!-- Circle -->
    <div
      class="z-1 rounded-circle position-absolute"
      [ngClass]="{
        circle: !isEventParallel(event),
        'parallel-circle': isEventParallel(event)
      }"
    ></div>

    <!-- Text Box -->
    <div
      class="d-flex flex-column align-items-center border rounded fs-8 w-100"
    >
      <div class="d-flex flex-row justify-content-between w-100 px-3 pt-2">
        <ff-text class="text-black fw-bold" [text]="getEventText(event)"
          ><i class="bi me-1" [ngClass]="getEventIcon(event)"></i>
        </ff-text>
        <div class="d-flex align-items-center">
          <div>
            <i class="bi bi-person-circle h5 text-primary me-2"></i>
          </div>
          <ff-text class="fw-bold fs-8" [text]="getEventUser(event)"></ff-text>
        </div>
      </div>
      <div class="d-flex flex-row justify-content-between w-100 px-3 pb-2">
        <ff-text [text]="getStepName(event)"></ff-text>
        <div class="text-secondary">
          {{ event.eventDate | mediumDateTimePipe }}
        </div>
      </div>
    </div>
  </div>
</form-card>
