import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError, share } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { IFlow } from '../../../models/IFlow';
import { environment } from 'src/environments/environment';
import { FormHistory } from '../../../models/FormHistory';
import { ExceptionHandleService } from '../exception/exception-handle.service';
import {
  FormReopenRequest,
  PatchFlowRequest,
} from 'src/app/models/requests/flow/FlowRequest';
import { PatchFlowEventResponse } from 'src/app/models/responses/flow/FlowResponse';

@Injectable({
  providedIn: 'root',
})
export class FlowService {
  private _domain: string = environment.ffxFlowApiUrl;

  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
  };

  constructor(
    private _http: HttpClient,
    private _exceptionHandleService: ExceptionHandleService,
  ) {}

  get = (flowId: number, formId: number, isNew: boolean): Observable<IFlow> => {
    const url = `${this._domain}/Flow/${flowId}/Form/${formId}?isNew=${isNew}`;
    return this._http.get<IFlow>(url, this.httpOptions).pipe(share());
  };

  patchFlow = (
    patchFlowRequest: PatchFlowRequest,
  ): Observable<PatchFlowEventResponse> => {
    return this._http
      .patch<PatchFlowEventResponse>(
        `${this._domain}/Flow`,
        patchFlowRequest,
        this.httpOptions,
      )
      .pipe(catchError(this._exceptionHandleService.handleError));
  };

  getFormHistory(flowId: string): Observable<FormHistory> {
    return this._http.get<FormHistory>(
      `${this._domain}/Flow/${flowId}/FormHistory`,
      this.httpOptions,
    );
  }

  reopenForm = (
    flowId: string,
    reopenRequest: FormReopenRequest,
  ): Observable<IFlow> => {
    return this._http.patch<IFlow>(
      `${this._domain}/Flow/${flowId}/Reopen`,
      reopenRequest,
      this.httpOptions,
    );
  };

  flowDelete(flowId: string): Observable<IFlow> {
    return this._http.delete<IFlow>(`${this._domain}/Flow/${flowId}`);
  }
}
