import { Question } from './Question';

export class SingleLineOfText extends Question<string> {
  constructor(question: Question<string[]>);
  constructor();
  constructor(question?: Question<string[]>) {
    super();
    if (question) {
      Object.assign(this, question);
    }
  }

  maxLength?: string;
  format?: string;
  label?: string;
}
