import { Component, Input, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FileExtensionPermissionType } from '@flowforma/ff-components';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FileService } from 'src/app/common/services/files/file.service';
import { TableColumnValue } from 'src/app/models/TableQuestion';
import { VisualMode } from 'src/app/models/Visual';
import { TableColumn } from '../table-features/table-features.service';
import { QuestionService } from 'src/app/common/services/question/question.service';

@Component({
  selector: 'app-table-file-upload',
  templateUrl: './table-file-upload.component.html',
  styleUrls: ['./table-file-upload.component.scss'],
})
export class TableFileUploadComponent implements OnInit {
  @Input() flowId!: string;
  @Input() questionId!: string;
  @Input() tableColumns: TableColumn[] = [];

  VisualMode = VisualMode;

  public form: FormGroup;

  fileExtensionPermissionType = FileExtensionPermissionType;
  validFiles?: File[];
  invalidFiles?: File[];
  allowedFileTypes = [
    AllowedFileExtension.CSV,
    AllowedFileExtension.XLS,
    AllowedFileExtension.XLSX,
  ];
  choices: string[] = ['Append', 'Overwrite', 'Merge'];
  allSourceWorksheetNames: string[] = [];
  allSourcetableColumns: string[] = [];
  tableColumnsTarget: TableColumnValue[] = [];
  disableColumnTitleRow = true;
  formControlNamesConst = FormControlNames;

  constructor(
    private fb: FormBuilder,
    private _activeModal: NgbActiveModal,
    private _fileService: FileService,
    private _questionService: QuestionService,
  ) {
    this.form = this.fb.group({
      [FormControlNames.FileInput]: [''],
      [FormControlNames.MappingType]: ['', Validators.required],
      [FormControlNames.SelectedWorksheetNames]: [''],
      [FormControlNames.HasColumnHeaderRow]: [false],
      [FormControlNames.Mappings]: this.fb.array([]),
    });
  }

  ngOnInit(): void {
    this.addMapping();
  }

  get mappings(): FormArray {
    return this.form.get(FormControlNames.Mappings) as FormArray;
  }

  updateTargetColumn(): void {
    this.tableColumnsTarget = this.tableColumns.map(
      (column) =>
        ({
          value: column.question.id,
          displayValue: column.question.title,
        }) as TableColumnValue,
    );
  }

  addMapping(): void {
    this.mappings.push(
      this.fb.group({
        sourceColumnName: ['', Validators.required],
        targetColumnId: ['', Validators.required],
      }),
    );
  }

  deleteMapping(index: number): void {
    if (this.mappings.length > 1) {
      this.mappings.removeAt(index);
    }
  }

  confirmFileUpload(): void {
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      return;
    }

    this._questionService
      .uploadFilesToTable(
        this.flowId,
        this.questionId,
        this.validFiles!,
        this.form.get(FormControlNames.HasColumnHeaderRow)?.value,
        this.form.value[FormControlNames.SelectedWorksheetNames],
        this.form.value[FormControlNames.Mappings],
        this.form.value[FormControlNames.MappingType][0],
      )
      .subscribe((response: any) => {
        this._activeModal.close(response);
      });
  }

  change(files: FileList | null): void {
    if (files?.length) {
      this.resetFields();

      const fileList: File[] = Array.from(files);
      const validFileList: File[] = [];
      const invalidFileList: File[] = [];

      fileList.forEach((file) => {
        if (this.validateFileExtension(file)) {
          validFileList.push(file);
        } else {
          invalidFileList.push(file);
        }
      });
      this.invalidFiles = invalidFileList;
      this.validFiles = validFileList;
      if (this.validFiles.length > 0) {
        this._fileService
          .uploadFileForTableColumnsAndWorksheetNames(
            this.validFiles[0],
            this.form.get(FormControlNames.HasColumnHeaderRow)?.value,
          )
          .subscribe((response) => {
            this.allSourcetableColumns = response.columnTitles;
            this.allSourceWorksheetNames = response.worksheetNames;
          });

        this.isExcelFile(this.validFiles[0])
          ? this.form.get(FormControlNames.SelectedWorksheetNames)?.enable()
          : this.form.get(FormControlNames.SelectedWorksheetNames)?.disable();
        this.form.get(FormControlNames.HasColumnHeaderRow)?.disable();
      }

      this.form.patchValue({ [FormControlNames.FileInput]: this.validFiles });
    }
  }

  deleteValidFile(fileIndex: number): void {
    if (
      this.validFiles &&
      fileIndex >= 0 &&
      fileIndex < this.validFiles.length
    ) {
      this.validFiles.splice(fileIndex, 1);

      this.form.get(FormControlNames.HasColumnHeaderRow)?.enable();
      this.resetFields();
    }
  }

  resetFields(): void {
    // Reset the form values
    this.form.patchValue({
      fileInput: this.validFiles,
      selectedWorksheetNames: [],
    });

    // Reset source columns and worksheet names
    this.allSourcetableColumns = [];
    this.allSourceWorksheetNames = [];

    // Reset mappings
    this.mappings.clear();
    this.addMapping();
  }

  deleteInvalidFile(fileIndex: number): void {
    if (
      this.invalidFiles &&
      fileIndex >= 0 &&
      fileIndex < this.invalidFiles.length
    ) {
      this.invalidFiles.splice(fileIndex, 1);
    }
  }

  /**
   * Auto map columns based on the column titles of the uploaded file
   */
  autoMapColumns(): void {
    let tempArray: FormArray = this.fb.array([]);

    this.allSourcetableColumns.forEach((sourceColumn) => {
      const targetColumn = this.tableColumnsTarget.find(
        (tc) => tc.displayValue === sourceColumn,
      );

      if (targetColumn) {
        tempArray.push(
          this.fb.group({
            sourceColumnName: [sourceColumn, Validators.required],
            targetColumnId: [targetColumn.value, Validators.required],
          }),
        );
      }
    });

    if (tempArray.length > 0) {
      this.mappings.clear();

      tempArray.controls.forEach((control) => {
        this.mappings.push(control);
      });
    }
  }

  getFileArray(): File[] {
    return this.validFiles ?? [];
  }

  closeModal(): void {
    this._activeModal.dismiss();
  }

  getFileExtension(file: File): string {
    return file.name.includes('.')
      ? file.name.slice(file.name.lastIndexOf('.'))
      : '';
  }

  validateFileExtension(file: File): boolean {
    const fileExtension = this.getFileExtension(file);
    Object.values(AllowedFileExtension);
    return this.allowedFileTypes.includes(
      fileExtension as AllowedFileExtension,
    );
  }

  isExcelFile(file: File): boolean {
    const fileExtension: string = this.getFileExtension(file);
    return [AllowedFileExtension.XLS, AllowedFileExtension.XLSX].includes(
      fileExtension as AllowedFileExtension,
    );
  }
}

enum AllowedFileExtension {
  CSV = '.csv',
  XLS = '.xls',
  XLSX = '.xlsx',
}

const FormControlNames = {
  FileInput: 'fileInput',
  MappingType: 'mappingType',
  SelectedWorksheetNames: 'selectedWorksheetNames',
  HasColumnHeaderRow: 'hasColumnHeaderRow',
  Mappings: 'mappings',
  SourceColumnName: 'sourceColumnName',
  TargetColumnId: 'targetColumnId',
};
