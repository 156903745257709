import { Component } from '@angular/core';

@Component({
  selector: 'form-footer',
  templateUrl: './form-footer.component.html',
  styleUrls: ['./form-footer.component.scss'],
})
export class FormFooterComponent {
  defaultLogoImage: string = 'assets/default_logo.png';
}
