import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';
import { Observable } from 'rxjs';

import { ActivatedRoute } from '@angular/router';
import { UserService } from '@flowforma/core';

export const InterceptorSkipHeader = 'X-Skip-Interceptor';
export const AuthorizationHeader = 'authorization';

@Injectable()
export class FFxApiInterceptor implements HttpInterceptor {
  // Headers
  private readonly organizationIdHeader = 'X-Organization-Id';
  private readonly appUrlHeader = 'X-App-Url';

  constructor(
    private _userService: UserService,
    private _route: ActivatedRoute,
  ) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler,
  ): Observable<HttpEvent<unknown>> {
    // Ignore adding header if needed
    if (request.headers?.has(InterceptorSkipHeader)) {
      const modifiedReq = request.clone({
        headers: request.headers
          .delete(InterceptorSkipHeader)
          .delete(AuthorizationHeader),
      });
      return next.handle(modifiedReq);
    }

    const organizations = this._userService.userOrganizations[0]; //this will later change, currently we just take the first found organization
    const organizationId = organizations?.id;

    if (organizationId) {
      request = request.clone({
        headers: request.headers.set(this.organizationIdHeader, organizationId),
      });
    }

    const appUrl = this._route.snapshot.queryParamMap.get('appUrl'); // Later this will not come via query param map
    if (appUrl) {
      request = request.clone({
        headers: request.headers.set(this.appUrlHeader, appUrl),
      });
    }

    return next.handle(request);
  }
}
