import { FFxUser } from '../../PersonOrGroup';
import { Feedback } from '../../Feedback';
import { CreateCommentThreadDto } from 'src/app/components/questions/question-comment/question-comment.model';
import { RuleTriggerEvent } from '../../RuleTriggerEvent';

export class SubmitStepRequest {
  constructor(
    public flowId: string,
    public stepId: string,
    public ruleTriggerEvent: RuleTriggerEvent,
    public files?: File[],
  ) {}
}

export class DelegateStepRequest {
  constructor(
    public peopleOrGroup: FFxUser[],
    public comment?: string,
  ) {}
}

export class StepFeedbackRequest {
  constructor(public stepFeedback: Feedback) {}
}

export class StepReopenRequest {
  constructor(
    public dueDate: Date,
    public commentThreads: CreateCommentThreadDto[],
  ) {}
}

export class StepNoteRequest {
  constructor(
    public text: string,
    public createdDate: Date,
    public id?: string,
  ) {}
}

export class StepAttachmentUploadRequest {
  constructor(public files: File[]) {}
}
