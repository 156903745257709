<div class="d-flex fs-65 align-items-center">
  <div class="circle-container">
    <div
      class="problem-circle circle-without-line-align-middle d-flex align-items-center justify-content-center align-self-center rounded-circle"
      [ngClass]="{
        'bg-danger': problemIndicatorType === problemIndicatorTypeEnum.Error,
        'bg-warning': problemIndicatorType === problemIndicatorTypeEnum.Issue
      }"
    >
      <span class="text-white fw-bold">{{ errorAmount }}</span>
    </div>
  </div>
  <div
    class="fw-bold d-lg-block"
    [ngClass]="{
      'd-none': !isSideStepperExpanded,
      'd-block': isSideStepperExpanded,
      'text-danger': problemIndicatorType === problemIndicatorTypeEnum.Error,
      'text-warning': problemIndicatorType === problemIndicatorTypeEnum.Issue
    }"
  >
    {{ getDisplayText() }}
  </div>
</div>
