import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { BaseQuestion } from '../../../models/Question';
import { Separator } from '../../../models/Separator';

@Component({
  selector: 'separator',
  templateUrl: './separator.component.html',
})
export class SeparatorComponent implements OnInit {
  @Input() question!: BaseQuestion;
  @Input() form!: FormGroup;

  separator!: Separator;

  ngOnInit(): void {
    this.separator = this.question as Separator;
  }
}
