<div [formGroup]="form">
  <ff-button
    *ngIf="button"
    [formControlName]="button.id"
    [tooltip]="button.description"
    [label]="button.title"
    [id]="button.id"
    [required]="button.required"
    [showTitle]="button.showTitle"
    [buttonType]="buttonType"
    [enabled]="button.enabled && !readOnly"
    [textValue]="button.textValue"
    [showCommentIcon]="showCommentIcon"
    (OnClick)="change()"
  >
    <ng-content commentIcon />
  </ff-button>
</div>
