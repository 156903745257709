<div class="form-footer d-flex flex-column align-items-end">
  <div class="d-flex justify-content-end align-items-center w-100">
    <ff-text [text]="'Get more work done with'"></ff-text>
    <img
      class="col-3 position-relative z-1"
      alt="flowforma logo"
      [src]="defaultLogoImage"
    />
  </div>
  <a
    class="nav-link text-primary position-relative"
    href="https://www.flowforma.com/"
    target="_blank"
    >Find out more</a
  >
</div>
