import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { IFlow } from '../../../models/IFlow';
import { IStep } from '../../../models/IStep';

@Component({
  selector: 'form-reopening-confirmation-dialog',
  templateUrl: './form-reopening-confirmation-dialog.component.html',
  styleUrls: ['./form-reopening-confirmation-dialog.component.scss'],
})
export class FormReopeningConfirmationDialogComponent implements OnInit {
  @Input() flow!: IFlow;

  public form = new FormGroup({
    stepToReopen: new FormControl(''),
    reasonForReopening: new FormControl(''),
  });

  stepsToPickFrom: { displayName: string; value: string }[] = [];

  constructor(private _activeModal: NgbActiveModal) {}

  /**
   * @description Initializes the component
   * @returns { void }
   */
  ngOnInit(): void {
    this.stepsToPickFrom = this.flow.steps.map((step: IStep) => {
      return { displayName: step.title, value: step.id! };
    });
  }

  /**
   * @description Closes the modal
   * @returns { void }
   */
  closeModal(): void {
    this._activeModal.dismiss();
  }

  /**
   * @description Submits the form
   * @returns { void }
   */
  submitForm(): void {
    if (!this.form.valid) {
      this.form.markAllAsTouched();
      return;
    }

    const formData: FormReopeningData = {
      stepToReopen: this.form.value.stepToReopen!,
      reasonForReopening: this.form.value.reasonForReopening!,
    };
    this._activeModal.close(formData);
  }

  /**
   * @description Handles the change event of the step to reopen control
   * @param value new value of the control
   * @returns { void }
   */
  onStepToReopenChange(value: string[] | null): void {
    if (!value || value.length === 0) {
      return;
    }
    this.form.controls.stepToReopen.setValue(value[0]);
  }
}

export interface FormReopeningData {
  stepToReopen: string;
  reasonForReopening: string;
}
