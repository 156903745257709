import { Component, Input } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'delegate-step-dialog',
  templateUrl: './delegate-step-dialog.component.html',
})
export class DelegateStepDialogComponent {
  @Input() flowTitle!: string;
  @Input() stepTitle!: string;

  public form = new FormGroup({
    peopleOrGroup: new FormControl(null),
    comment: new FormControl(null),
  });

  constructor(public activeModal: NgbActiveModal) {}

  public confirmDelegate(): void {
    if (!this.form.valid) {
      this.form.markAllAsTouched();
      return;
    }

    this.activeModal.close(this.form.value);
  }

  closeModal(): void {
    this.activeModal.dismiss();
  }
}
