import { Component, OnInit } from '@angular/core';
import { ChoiceType } from '../../../models/ChoiceType';
import { Choice } from '../../../models/Choice';
import { QuestionBaseComponent } from '../question-base-component';
import { VisualMode } from 'src/app/models/Visual';

@Component({
  selector: 'choice',
  templateUrl: './choice.component.html',
})
export class ChoiceComponent
  extends QuestionBaseComponent<string[]>
  implements OnInit
{
  choice!: Choice;
  choiceTypeEnum: typeof ChoiceType = ChoiceType;
  VisualMode = VisualMode;

  ngOnInit(): void {
    this.choice = new Choice(this.question);
  }

  singleChoiceChange(value: string): void {
    super.change([value]);
  }

  get choiceReadOnlyValue(): string | undefined {
    return this.choice.value?.join(', ');
  }
}
