<div class="d-flex align-items-center start-parallel-step-group-container">
  <div class="stepper-item">
    <div
      class="position-relative stepper-item-line"
      [class.active]="active"
      [class.submitted]="submitted"
    ></div>
  </div>
  <i
    (click)="toggleParallelStepGroupExpanded($event)"
    (keydown)="toggleParallelStepGroupExpanded($event)"
    class="bi position-relative fs-8 parallel-step-group-icon parallel-step-group-expand"
    [ngClass]="
      parallelStepGroupExpanded === undefined || !!parallelStepGroupExpanded
        ? 'bi-caret-down-fill'
        : 'bi-caret-right-fill'
    "
  >
  </i>
  <div
    (click)="toggleParallelStepGroupExpanded($event)"
    (keydown)="toggleParallelStepGroupExpanded($event)"
    [attr.tabindex]="0"
    class="fs-8 parallel-step-group-text parallel-step-group-expand tabbable"
  >
    {{ parallelStepGroupName }}
  </div>
</div>
