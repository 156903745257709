<div [formGroup]="form">
  <ff-upload
    *ngIf="fileUpload"
    [formControlName]="fileUpload.id"
    [showTitle]="fileUpload.showTitle"
    [tooltip]="fileUpload.description"
    [label]="fileUpload.title"
    [id]="fileUpload.id"
    [required]="fileUpload.required!"
    [files]="fileArray"
    [savedFiles]="fileListItemUploads"
    [fileExtensionPermissionType]="fileUpload.fileExtensionPermissionType"
    [extensions]="fileUpload.extensions"
    [allowMultiple]="fileUpload.allowMultipleFiles"
    [hideUploadButton]="fileUpload.hideUploadButton"
    [hideUploadLink]="fileUpload.hideUploadLink"
    [withPreview]="fileUpload.showImagePreview ?? false"
    [withAnnotation]="fileUpload.annotateImage ?? false"
    [annotationColor]="fileUpload.annotationColor ?? '#FF0000'"
    (OnChange)="fileUploadChange($event)"
    (onDelete)="deleteFile($event)"
    [enabled]="question.enabled"
    [fileSizeLimit]="fileUpload.fileSizeLimit"
    [readOnly]="readOnly"
    [showErrorText]="showErrorText"
  >
    <ng-content />
  </ff-upload>
</div>
