import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IFlow } from '../../../models/IFlow';
import {
  ButtonSize,
  ButtonType,
  FileUploadStorageType,
} from '@flowforma/ff-components';
import { AvailableComponent } from '../form/form.component';
import { FormReopeningData } from '../form-reopening-confirmation-dialog/form-reopening-confirmation-dialog.component';
import { FFModalService } from '../../../common/services/ff-modal/ff-modal.service';
import { FileService } from '../../../common/services/files/file.service';
import { Attachment } from '../../../models/Attachment';

@Component({
  selector: 'form-summary',
  templateUrl: './form-summary.component.html',
})
export class FormSummaryComponent {
  @Input() flow!: IFlow;
  @Input() logo!: string;
  @Output() changeComponentView = new EventEmitter<AvailableComponent>();
  @Output() getWorkflowHistoryEvent = new EventEmitter<void>();
  @Output() confirmReopenForm = new EventEmitter<FormReopeningData>();

  buttonTypeEnum: typeof ButtonType = ButtonType;
  buttonSizeEnum: typeof ButtonSize = ButtonSize;
  fileUploadStorageTypeEnum: typeof FileUploadStorageType =
    FileUploadStorageType;

  constructor(
    private _modalService: FFModalService,
    private _fileService: FileService,
  ) {}

  openWorkflowHistory(): void {
    this.changeComponentView.emit(AvailableComponent.WorkflowHistory);
    this.getWorkflowHistoryEvent.emit();
  }

  /**
   * @description Opens modal to reopen form
   * @returns { void }
   */
  reopenForm(): void {
    this._modalService.openFormReopenModal(
      this.flow,
      (formReopeningData: FormReopeningData) => {
        this.confirmReopenForm.emit(formReopeningData);
      },
    );
  }

  /**
   * @description Downloads step attachment
   * @param attachment attachment to download
   */
  attachmentLinkClick(attachment: Attachment): void {
    this._fileService.downloadFileByStorageType(
      attachment.storageType,
      attachment.displayName!,
      attachment.relativeFilePath!,
    );
  }

  /**
   * @description Gets uploaded documents count suffix
   * @returns { string } uploaded documents count suffix or empty string if no documents uploaded
   */
  getUploadedDocumentsCountSuffix(): string {
    const count: number = this.flow.steps
      .map((step) => step.attachments?.length ?? 0)
      .reduce((a, b) => a + b, 0);

    return count > 0 ? ` (${count})` : '';
  }
}
