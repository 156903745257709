import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ScrollLockService {
  private locked = false;

  lockScroll(): void {
    if (!this.locked) {
      document.body.style.overflow = 'hidden';
      this.locked = true;
    }
  }

  unlockScroll(): void {
    if (this.locked) {
      document.body.style.overflow = 'auto';
      this.locked = false;
    }
  }
}
