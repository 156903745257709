import { Component, Input } from '@angular/core';
import { QuestionBaseComponent } from '../question-base-component';
import {
  KeyValuePair,
  SharePointLookup,
} from 'src/app/models/SharePointLookup';
import { LookupService } from 'src/app/common/services/lookup/lookup.service';
import { take } from 'rxjs';

@Component({
  selector: 'share-point-lookup',
  templateUrl: './share-point-lookup.component.html',
})
export class SharePointLookupComponent extends QuestionBaseComponent<string> {
  sharePointLookup!: SharePointLookup;
  isOptionsLoaded: boolean = false;
  options?: KeyValuePair[];

  @Input() tableId?: string;

  constructor(private _lookupService: LookupService) {
    super();
  }

  ngOnInit(): void {
    this.sharePointLookup = this.question as SharePointLookup;
  }

  retrieveOptions() {
    //Get SharePointLookup options once on loading component.
    if (!this.isOptionsLoaded) {
      this._lookupService
        .GetSharePointLookupQuestionOptions(
          this.flowId,
          this.sharePointLookup.id,
          this.tableId,
        )
        .pipe(take(1))
        .subscribe((options) => {
          this.options = options;
        });
      this.isOptionsLoaded = true;
    }
  }
}
