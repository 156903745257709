import { Pipe } from '@angular/core';
import { BaseFFxDatePipe } from '@flowforma/core';

@Pipe({
  name: 'customDayPipe',
})
export class CustomDayPipe extends BaseFFxDatePipe {
  override get formatString(): string {
    return 'EEEE d';
  }
}
