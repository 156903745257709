import { Component, Input } from '@angular/core';

@Component({
  selector: 'parallel-step-group',
  templateUrl: './parallel-step-group.component.html',
  styleUrls: ['./parallel-step-group.component.scss'],
})
export class ParallelStepGroupComponent {
  @Input() parallelStepGroupName?: string;
}
