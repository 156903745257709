<div class="modal-header">
  <h4 class="modal-title">Confirm re-opening</h4>
  <i class="bi bi-x fs-1 close-icon" (click)="closeModal()"></i>
</div>

<div class="modal-body">
  <div class="alert-warning alert">
    <span><i class="bi bi-info-circle-fill"></i></span>
    <span class="ms-2 fw-bold">Please note</span>
    <ul class="mb-0">
      <li>
        Re-opening a step opens all subsequent steps to ensure changes are
        applied across the flow.
      </li>
      <li>All contributors will be notified.</li>
    </ul>
  </div>

  <div [formGroup]="form">
    <ff-select
      [id]="'FormReopeningConfirmationDialogStepToReopen'"
      [label]="'Which step do you want to re-open from?'"
      formControlName="stepToReopen"
      [required]="true"
      [enabled]="true"
      (OnChange)="onStepToReopenChange($event)"
      [options]="stepsToPickFrom"
      [showTitle]="true"
      [errorMessageSuffix]="'step you want to re-open from'"
    ></ff-select>
    <div class="py-2"></div>
    <ff-textarea
      [id]="'FormReopeningConfirmationDialogReasonForReopening'"
      class="mt-2"
      [label]="'Why are you re-opening this form?'"
      formControlName="reasonForReopening"
      [numberOfLines]="3"
      [readOnly]="false"
      [required]="true"
      [placeHolder]="''"
      [errorMessageSuffix]="'reason for re-opening this form'"
    ></ff-textarea>
  </div>

  <div class="modal-footer">
    <button class="btn btn-outline-primary" (click)="closeModal()">
      Cancel
    </button>
    <button class="btn btn-primary" (click)="submitForm()">Confirm</button>
  </div>
</div>
