import { AffectedQuestion } from './AffectedQuestion';

export abstract class QuestionCustomActionError {
  constructor(affectedQuestion?: AffectedQuestion) {
    this.affectedQuestion = affectedQuestion;
  }

  affectedQuestion?: AffectedQuestion;
}

export class QuestionCustomActionControl extends QuestionCustomActionError {
  constructor(
    controlId: string,
    affectedQuestion?: AffectedQuestion,
    tableId?: string,
  ) {
    super(affectedQuestion);
    this.controlId = controlId;
    this.tableId = tableId;
  }

  controlId: string;
  tableId?: string;
}
