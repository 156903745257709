import { Component, EventEmitter, Input, Output } from '@angular/core';

import {
  Comment,
  CommentReason,
  CommentThread,
  CreateCommentRequest,
  DeleteCommentRequest,
  EditCommentRequest,
  OnCommentRequest,
  Reason,
} from '../question-comment/question-comment.model';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ButtonSize, ButtonType } from '@flowforma/ff-components';
import { BaseQuestion } from 'src/app/models/Question';

@Component({
  selector: 'question-comment-thread',
  templateUrl: './question-comment-thread.component.html',
  styleUrls: ['./question-comment-thread.component.scss'],
})
export class QuestionCommentThreadComponent {
  @Input() commentThread!: CommentThread;
  @Input() question!: BaseQuestion;
  @Input() isStepCompleted!: boolean;

  public editForm = new FormGroup({
    id: new FormControl(),
    index: new FormControl(),
    text: new FormControl(''),
  });

  buttonSizeEnum: typeof ButtonSize = ButtonSize;
  buttonTypeEnum: typeof ButtonType = ButtonType;
  maxCharacterLimit: number = 100;
  commentContent: number = 0;
  showReplyTextarea: boolean = false;
  @Output() questionComment = new EventEmitter<OnCommentRequest>();
  userName: string = 'Jake Donaghoe';
  reason: CommentReason[] = [
    { value: Reason.VerifyInfo, displayName: 'Recheck Information' },
    { value: Reason.MoreInfo, displayName: 'More Information Required' },
    { value: Reason.UploadError, displayName: 'Wrong file uploaded' },
    { value: Reason.Other, displayName: 'Other' },
  ];
  currentTime: Date = new Date();

  constructor() {
    setInterval(() => {
      this.currentTime = new Date();
    }, 60000);
  }

  public commentForm = new FormGroup({
    text: new FormControl('', [Validators.maxLength(100)]),
  });

  getReasonText(text: Reason): string {
    if (text === Reason.Other) return this.commentThread.otherReason!;

    return this.reason.find((x) => x.value == text)!.displayName;
  }

  replyToCommentThread() {
    this.showReplyTextarea = true;
  }

  keyUp(value: string): void {
    if (value) this.commentContent = value.length;
  }

  closeReply(): void {
    this.commentForm.controls.text.setValue('');
    this.showReplyTextarea = false;
  }

  saveReply(): void {
    if (!this.commentForm.valid) {
      this.commentForm.markAllAsTouched();
      return;
    }

    this.questionComment.emit(
      new OnCommentRequest(
        new CreateCommentRequest(
          this.question,
          undefined,
          this.commentForm.controls.text.value!,
        ),
      ),
    );

    this.commentForm.controls.text.setValue('');
    this.showReplyTextarea = false;
  }

  onEditClick(id: string | undefined, text: string, index: number): void {
    this.commentThread.comments[index].enableEdit = true;
    this.editForm.controls.text.setValue(text);
    this.editForm.controls.id.setValue(id);
    this.editForm.controls.index.setValue(index);
  }

  get canShowReply(): boolean {
    return !this.isStepCompleted && !this.showReplyTextarea;
  }

  canShowEdit(comment: Comment): boolean {
    return (
      !comment.enableEdit &&
      !this.commentThread.isAddressed &&
      !this.showReplyTextarea &&
      comment.isEditable
    );
  }

  canShowDelete(comment: Comment): boolean {
    return (
      !this.commentThread.isAddressed &&
      !this.showReplyTextarea &&
      !comment.enableEdit &&
      comment.isEditable
    );
  }

  onDeleteComment(index: number, id: string | undefined): void {
    this.questionComment.emit(
      new OnCommentRequest(
        new DeleteCommentRequest(this.question, id, '', index),
      ),
    );
  }

  onEditSave(): void {
    if (!this.editForm.valid) {
      this.editForm.markAllAsTouched();
      return;
    }

    this.questionComment.emit(
      new OnCommentRequest(
        new EditCommentRequest(
          this.question,
          this.editForm.controls.id.value,
          this.editForm.controls.text.value!,
          this.editForm.controls.index.value,
        ),
      ),
    );

    this.onCancelEdit(this.editForm.controls.index.value);
  }

  onCancelEdit(index: number) {
    this.commentThread.comments[index].enableEdit = false;
    this.editForm.reset();
  }
}
