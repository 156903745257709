<div class="stepper-item">
  <div class="stepper-item-top-line" [class.active]="active || submitted"></div>
  <div
    *ngIf="!firstStepInParallelStepGroup"
    class="stepper-item-top-line pg-line"
    [class.hide]="firstStepInFlow"
    [class.active]="active"
    [class.submitted]="submitted"
    [class.selected]="selected"
    [class.disabled]="disabled"
  ></div>
  <div
    *ngIf="firstStepInParallelStepGroup && !disabled"
    class="parallel-stepper-item-start"
  >
    <svg
      width="12"
      height="19"
      viewBox="0 0 12 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="
          M1 0
          V0.888888
          C1 2.9619 2.07009 4.88805 3.83019 5.98323
          L8.16981 8.68344
          C9.92991 9.77861 11 11.7048 11 13.7778
          V19"
        class="parallel-stepper-line-svg"
        [class.active]="active"
        [class.submitted]="submitted"
      />
    </svg>
  </div>
  <div
    *ngIf="firstStepInParallelStepGroup && disabled"
    class="parallel-stepper-item-start-disabled"
  >
    <svg
      width="12"
      height="22"
      viewBox="0 0 12 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="
          M1 0
          V1.81665
          C1 3.74572 1.92752 5.55713 3.49267 6.68478
          L8.50733 10.2977
          C10.0725 11.4253 11 13.2367 11 15.1658
          V22"
        class="parallel-stepper-line-svg"
        [class.active]="active"
        [class.submitted]="submitted"
      />
    </svg>
  </div>
  <div
    class="stepper-item-bottom-line"
    [class.active]="active || submitted"
  ></div>
  <div
    *ngIf="
      !lastStepInParallelStepGroup ||
      (lastStepInParallelStepGroup && (submitted || active))
    "
    class="stepper-item-bottom-line pg-line"
    [class.lastStepInParallelStepGroup]="lastStepInParallelStepGroup"
    [class.hide]="lastStepInFlow"
    [class.submitted]="submitted"
    [class.selected]="selected"
    [class.disabled]="disabled"
    [class.active]="active"
  ></div>
  <div
    *ngIf="lastStepInParallelStepGroup && !disabled"
    class="parallel-stepper-item-end"
  >
    <svg
      width="12"
      height="19"
      viewBox="0 0 12 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="
          M1 19
          V18.1111
          C1 16.0381 2.07009 14.1119 3.83019 13.0168
          L8.16981 10.3166
          C9.92991 9.22139 11 7.29523 11 5.22222
          V0"
        class="parallel-stepper-line-svg"
        [class.active]="active"
        [class.submitted]="submitted"
      />
    </svg>
  </div>
  <div
    *ngIf="lastStepInParallelStepGroup && disabled"
    class="parallel-stepper-item-end-disabled"
  >
    <svg
      width="12"
      height="22"
      viewBox="0 0 12 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="
          M1 22
          V20.1833
          C1 18.2543 1.92752 16.4429 3.49267 15.3152
          L8.50733 11.7023
          C10.0725 10.5747 11 8.76326 11 6.8342
          V1.19209e-07"
        class="parallel-stepper-line-svg"
        [class.active]="active"
        [class.submitted]="submitted"
      />
    </svg>
  </div>
  <div
    class="parallel-stepper-item-circle"
    [class.active]="active"
    [class.submitted]="submitted"
    [class.selected]="selected"
    [class.disabled]="disabled"
  ></div>
</div>
