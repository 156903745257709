import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { FormGroup } from '@angular/forms';

import { NgbPopover } from '@ng-bootstrap/ng-bootstrap';

import { QuestionEvent } from '../../steps/step-detail/step-detail.component';

import { BaseQuestion } from '../../../models/Question';
import { QuestionType } from '../../../models/QuestionType';
import {
  CommentThread,
  CreateCommentRequest,
  CreateCommentThreadRequest,
  DeleteCommentRequest,
  EditCommentRequest,
  OnCommentRequest,
  Comment,
  CommentReplyRequest,
} from '../question-comment/question-comment.model';
import { UserService } from '@flowforma/core';
import { QuestionCustomActionControl } from 'src/app/models/QuestionCustomActionError';
import { CommentService } from 'src/app/common/services/question/comment/comment.service';
import { PatchFlowTableEventResponse } from 'src/app/models/responses/question/QuestionResponse';

@Component({
  selector: 'question-selector',
  templateUrl: './question-selector.component.html',
})
export class QuestionSelectorComponent {
  questionType: typeof QuestionType = QuestionType;

  @Input() question!: BaseQuestion;
  @Input() form!: FormGroup;
  @Input() readOnly!: boolean;
  @Input() flowId!: string;
  @Input() isStepCompleted!: boolean;
  @Input() showCommentIcon: boolean = true;
  @Input() showErrorText: boolean = true;
  @Input() tableId?: string;

  @Output()
  questionEvent = new EventEmitter<QuestionEvent>();

  @Output()
  tableEvent = new EventEmitter<PatchFlowTableEventResponse>();

  @Output()
  questionErrorEvent = new EventEmitter<QuestionCustomActionControl>();

  @ViewChild('commentPopOver') commentPopOver?: NgbPopover;
  @Input() reopenMode: boolean = false;
  isCommentPopUpActive: boolean = false;
  showCommentThread: boolean = false;
  constructor(
    private cd: ChangeDetectorRef,
    private _commentService: CommentService,
    private _userService: UserService,
  ) {}
  onValueChanged(questionEvent: QuestionEvent): void {
    this.questionEvent.emit(questionEvent);
  }

  onTableEvent(tableEvent: PatchFlowTableEventResponse): void {
    this.tableEvent.emit(tableEvent);
  }

  onQuestionErrorEvent(event: QuestionCustomActionControl): void {
    this.questionErrorEvent.emit(event);
  }

  onQuestionCommentClick(): void {
    if (!this.question.commentThread) this.commentPopOver?.open();
    else this.toggleCommentThread();
  }

  onQuestionComment(request: OnCommentRequest): void {
    if (request.data instanceof CreateCommentThreadRequest) {
      this.commentPopOver?.close();
      this.addQuestionCommentThread(request.data);
      this.toggleCommentThread();
    } else if (request.data instanceof CreateCommentRequest) {
      this.addReplyToQuestionCommentThread(request.data);
    } else if (request.data instanceof DeleteCommentRequest) {
      this.deleteQuestionComment(request.data);
    } else if (request.data instanceof EditCommentRequest) {
      this.editQuestionComment(request.data);
    }
  }

  addQuestionCommentThread(
    createCommentThread: CreateCommentThreadRequest,
  ): void {
    this.question.commentThread = {
      reason: createCommentThread.reason,
      otherReason: createCommentThread.otherReason,
      comments: [
        {
          id: createCommentThread.id,
          text: createCommentThread.text,
          timeStamp: new Date(),
          isEdited: false,
          userDisplayName: this._userService.user?.idTokenClaims?.displayName,
        },
      ],
    } as CommentThread;
  }

  deleteQuestionComment(request: DeleteCommentRequest): void {
    if (!request.id) {
      this.deleteUnsavedComment(request);
    } else {
      this.deleteSavedComment(request);
    }
  }

  deleteUnsavedComment(request: DeleteCommentRequest): void {
    const comments = request.question?.commentThread?.comments;
    if (comments && comments.length > 1) comments.splice(request.index, 1);
    else this.question.commentThread = undefined;
  }

  deleteSavedComment(request: DeleteCommentRequest): void {
    this._commentService
      .deleteQuestionComment(this.flowId, request.question.id, request.id!)
      .subscribe((question: BaseQuestion) => {
        this.question.commentThread = question.commentThread;
        this.form.get(this.question.id)?.updateValueAndValidity();
      });
  }

  editQuestionComment(request: EditCommentRequest): void {
    if (!request.id) {
      const comment = this.question.commentThread!.comments[request.index];

      if (comment) comment.text = request.text;
    } else {
      this._commentService
        .editQuestionComment(this.flowId, request.question.id, {
          id: request.id,
          text: request.text,
        } as Comment)
        .subscribe((question: BaseQuestion) => {
          this.question.commentThread = question.commentThread;
        });
    }
  }

  addReplyToQuestionCommentThread(createComment: CreateCommentRequest): void {
    if (!this.reopenMode) {
      this._commentService
        .commentReply(
          this.flowId,
          createComment.question.id,
          new CommentReplyRequest(createComment.text, createComment.timeStamp),
        )
        .subscribe((question: BaseQuestion) => {
          this.question.commentThread = question.commentThread;
        });
    } else {
      this.question.commentThread!.comments.push({
        id: undefined,
        text: createComment.text,
        timeStamp: createComment.timeStamp,
        userDisplayName: this._userService.user?.idTokenClaims?.displayName,
      } as Comment);
    }
  }

  onQuestionCommentClose(): void {
    this.commentPopOver?.close();
  }

  toggleCommentPopUp(): void {
    this.isCommentPopUpActive = !this.isCommentPopUpActive;
    this.cd.detectChanges();
  }

  toggleCommentThread(): void {
    this.showCommentThread = !this.showCommentThread;
    this.cd.detectChanges();
  }

  isCommentIconActive(): boolean {
    if (this.question.commentThread) return true;
    if (this.isCommentPopUpActive) return true;

    return false;
  }

  canShowCommentIcon(): boolean {
    if (!this.showCommentIcon || !this.question.enabled) {
      return false;
    }
    if (this.isStepCompleted && this.question.commentThread != null) {
      return true;
    }
    return (
      this.reopenMode ||
      (this.question.commentThread != null &&
        !this.question.commentThread.isAddressed)
    );
  }
}
