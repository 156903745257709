import {
  MsalGuardConfiguration,
  MsalInterceptorConfiguration,
  ProtectedResourceScopes,
} from '@azure/msal-angular';
import {
  IPublicClientApplication,
  InteractionType,
  PublicClientApplication,
} from '@azure/msal-browser';
import { environment } from 'src/environments/environment';

/**
 * Here we pass the configuration parameters to create an MSAL instance.
 */
export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication(environment.auth.msalConfig);
}

/**
 * Set your default interaction type for MSALGuard here. If you have any
 * additional scopes you want the user to consent upon login, add them here as well.
 */
export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return {
    interactionType: InteractionType.Redirect,
    authRequest: environment.auth.loginRequest,
  };
}

/**
 * MSAL Angular will automatically retrieve tokens for resources added to protectedResourceMap.
 */
export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<
    string,
    Array<string | ProtectedResourceScopes> | null
  >();
  protectedResourceMap.set('*', environment.auth.loginRequest.scopes);

  return {
    interactionType: InteractionType.Popup,
    protectedResourceMap,
  };
}
