import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { QuestionEvent } from '../../../steps/step-detail/step-detail.component';
import { TableViewBase } from '../table-view-base/table-view-base.component';

@Component({
  selector: 'table-question-card',
  templateUrl: './table-question-card.component.html',
})
export class TableQuestionCardComponent
  extends TableViewBase
  implements OnInit
{
  @Input() tableFooter!: any;
  @Input() rowIndex!: number;
  @Input() isFooter = false;
  @Input() isRowNumbersHidden = false;

  @Output() questionEvent = new EventEmitter<QuestionEvent>();

  rowData: any;

  public ngOnInit(): void {
    if (this.tableData?.questionData) {
      this.rowData = this.tableData.questionData.find(
        (x) => x.rowIndex === this.rowIndex,
      );
    }
  }

  // Used to emit the event to the parent component from the footer
  // Currently not supported
  onFooterQuestionEvent(questionEvent: QuestionEvent): void {
    this.tableValueUpdateEvent.emit(questionEvent);
  }
}
