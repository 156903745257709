import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'relativeTime',
})
export class RelativeTimePipe implements PipeTransform {
  transform(
    value: Date,
    referenceDate: Date | null,
    suffixWord?: string,
  ): string {
    if (!value) {
      return '';
    }

    if (!referenceDate) {
      referenceDate = new Date();
    }

    const minute = 60 * 1000;
    const hour = minute * 60;
    const day = hour * 24;
    const month = day * 30;
    const year = day * 365;

    const diff = new Date(referenceDate).getTime() - new Date(value).getTime();

    let timeValue: number;
    let timeUnit: string;

    if (diff < minute) {
      return 'Just now';
    } else if (diff < hour) {
      timeValue = Math.floor(diff / minute);
      timeUnit = 'minute';
    } else if (diff < day) {
      timeValue = Math.floor(diff / hour);
      timeUnit = 'hour';
    } else if (diff < month) {
      timeValue = Math.floor(diff / day);
      timeUnit = 'day';
    } else if (diff < year) {
      timeValue = Math.floor(diff / month);
      timeUnit = 'month';
    } else {
      timeValue = Math.floor(diff / year);
      timeUnit = 'year';
    }
    return `${timeValue} ${timeUnit}${timeValue > 1 ? 's' : ''}${
      suffixWord ? ' ' : ''
    }${suffixWord ?? ''}`;
  }
}
