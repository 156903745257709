<div ngbAccordion class="mb-5">
  <div ngbAccordionItem class="border">
    <h2 ngbAccordionHeader>
      <button ngbAccordionButton class="p-3 gap-3">
        <i class="bi bi-chat-dots"></i>
        <div>
          <ff-text class="fw-semibold" [text]="'Comments'"></ff-text>
          <ff-text
            class="fs-8"
            [text]="overallCount + ' overall, ' + inlineCount + ' inline'"
          ></ff-text>
        </div>
      </button>
    </h2>
    <div ngbAccordionCollapse>
      <div ngbAccordionBody class="py-0">
        <ng-template>
          <div *ngIf="overallCount" class="d-flex flex-column gap-2 mb-3">
            <ff-text class="fw-semibold" [text]="'Overall'"></ff-text>
            <div class="alert-divider-information border">
              <div
                class="px-3 pt-3 step-note"
                *ngFor="let note of step.notes; let last = last"
                [class.pb-3]="last"
              >
                <user-remark
                  [date]="note.createdDate"
                  [remark]="note.text"
                  [userDisplayName]="note.createdBy!"
                ></user-remark>
              </div>
            </div>
          </div>
          <div *ngIf="inlineCount" class="d-flex flex-column gap-2 mb-3">
            <ff-text class="fw-semibold" [text]="'Inline'"></ff-text>
            <div *ngFor="let question of step.questions">
              <div *ngIf="question.commentThread" class="d-flex gap-2">
                <i
                  class="bi bi-chat-dots-fill text-primary cursor-pointer"
                  (click)="anchorToQuestion(question.id)"
                ></i>
                <ff-text
                  [text]="question.title"
                  class="text-primary cursor-pointer"
                  (click)="anchorToQuestion(question.id)"
                ></ff-text>
              </div>
            </div>
          </div>
        </ng-template>
      </div>
    </div>
  </div>
</div>
<ff-separator></ff-separator>
