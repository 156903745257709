import { Component, OnInit } from '@angular/core';

import { DateAndTime, DateDisplayFormat } from '../../../models/DateAndTime';
import { QuestionBaseComponent } from '../question-base-component';

import {
  DateTimeInputPipe,
  DateOnlyInputPipe,
  TimeOnlyInputPipe,
} from '@flowforma/core';

import { ShortDateTimePipe } from 'src/app/common/pipes/ffx-date-pipes/short-date-time.pipe';
import { ShortDatePipe } from 'src/app/common/pipes/ffx-date-pipes/short-date.pipe';
import { ShortTimePipe } from 'src/app/common/pipes/ffx-date-pipes/short-time.pipe';

@Component({
  selector: 'date-and-time',
  templateUrl: './date-and-time.component.html',
})
export class DateAndTimeComponent
  extends QuestionBaseComponent<Date>
  implements OnInit
{
  constructor(
    public dateTimeInputPipe: DateTimeInputPipe,
    public dateOnlyInputPipe: DateOnlyInputPipe,
    public timeOnlyInputPipe: TimeOnlyInputPipe,
    public shortDateTimePipe: ShortDateTimePipe,
    public shortDatePipe: ShortDatePipe,
    public shortTimePipe: ShortTimePipe,
  ) {
    super();
  }

  dateAndTime!: DateAndTime;

  formattedDateTime: string | null = null;
  displayFormat = DateDisplayFormat;

  ngOnInit(): void {
    this.dateAndTime = this.question as DateAndTime;
    const selectedDateTime = new Date(this.dateAndTime?.value ?? '');

    // convert the date time to the correct format
    if (!isNaN(selectedDateTime.valueOf())) {
      switch (this.dateAndTime.dateDisplayFormat) {
        case DateDisplayFormat.DateAndTime:
          this.formattedDateTime =
            this.dateTimeInputPipe.transform(selectedDateTime);
          break;
        case DateDisplayFormat.DateOnly:
          this.formattedDateTime =
            this.dateOnlyInputPipe.transform(selectedDateTime);
          break;
        case DateDisplayFormat.TimeOnly:
          this.formattedDateTime =
            this.timeOnlyInputPipe.transform(selectedDateTime);
          break;
      }
    }
  }

  get getDisplayValue(): string {
    const selectedDateTime = new Date(this.dateAndTime?.value ?? '');

    if (!isNaN(selectedDateTime.valueOf())) {
      switch (this.dateAndTime.dateDisplayFormat) {
        case DateDisplayFormat.DateAndTime:
          return this.shortDateTimePipe.transform(selectedDateTime)!;
        case DateDisplayFormat.DateOnly:
          return this.shortDatePipe.transform(selectedDateTime)!;
        case DateDisplayFormat.TimeOnly:
          return this.shortTimePipe.transform(selectedDateTime)!;
      }
    }

    return '';
  }
}
