import { RuleTriggerEvent } from '../../RuleTriggerEvent';

export class PatchFlowRequest {
  constructor(
    public flowId: string,
    public ruleTriggerEvent: RuleTriggerEvent,
    public files?: File[],
  ) {}
}

export class FormReopenRequest {
  constructor(
    public stepId: string,
    public reason: string,
  ) {}
}
