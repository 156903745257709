import { Component, Input } from '@angular/core';

@Component({
  selector: 'stepper-item',
  templateUrl: './stepper-item.component.html',
  styleUrls: ['./stepper-item.component.scss'],
})
export class StepperItemComponent {
  @Input() firstStepInFlow: boolean = false;
  @Input() lastStepInFlow: boolean = false;
  @Input() active: boolean = false;
  @Input() submitted: boolean = false;
  @Input() selected: boolean = false;
  @Input() disabled: boolean = false;
}
