import { Component, Input, OnInit } from '@angular/core';

import { ButtonQuestion } from '../../../models/ButtonQuestion';
import { ButtonType } from '@flowforma/ff-components';
import { QuestionBaseComponent } from '../question-base-component';

@Component({
  selector: 'button-question',
  templateUrl: './button-question.component.html',
})
export class ButtonQuestionComponent
  extends QuestionBaseComponent<void>
  implements OnInit
{
  button!: ButtonQuestion;
  buttonType = ButtonType.Primary;
  @Input() showCommentIcon: boolean = false;

  /**
   * @description Lifecycle hook, called after all data-bound properties have initialized.
   * @returns { void }
   */
  ngOnInit(): void {
    this.button = this.question as ButtonQuestion;
  }
}
