import { BaseQuestion } from 'src/app/models/Question';

type OtherReasonType = string | undefined | null;

export class CommentThread {
  constructor(
    reason: Reason,
    comments: Comment[],
    isAddressed: boolean,
    otherReason?: string,
  ) {
    this.reason = reason;
    this.comments = comments;
    this.isAddressed = isAddressed;
    this.otherReason = otherReason;
  }
  reason!: Reason;
  otherReason?: string;
  comments!: Comment[];
  isAddressed!: boolean;
}

export class Comment {
  constructor(
    id: string | undefined,
    text: string,
    timeStamp: Date,
    isEdited: boolean,
    userDisplayName?: string,
  ) {
    this.id = id;
    this.text = text;
    this.timeStamp = timeStamp;
    this.isEdited = isEdited;
    this.enableEdit = false;
    this.userDisplayName = userDisplayName;
  }
  id: string | undefined;
  text!: string;
  userDisplayName?: string;
  timeStamp!: Date;
  isEdited!: boolean;

  /**
   * Indicates whether the user has permission to edit the comment.
   */
  isEditable!: boolean;

  /**
   * Indicates whether to show text area when edit is pressed.
   */
  enableEdit!: boolean;
}

export class CreateCommentRequest extends Comment {
  constructor(question: BaseQuestion, id: string | undefined, text: string) {
    super(id, text, new Date(), false);
    this.question = question;
  }
  question: BaseQuestion;
}

export class CreateCommentThreadRequest extends CreateCommentRequest {
  constructor(
    question: BaseQuestion,
    reason: Reason,
    otherReason: OtherReasonType,
    text: string,
    id: string | undefined,
  ) {
    super(question, id, text);
    this.reason = reason;
    this.otherReason = otherReason;
  }
  reason: Reason;
  otherReason: string | undefined | null;
}

export class CreateCommentThreadDto {
  constructor(
    questionId: string,
    reason: Reason,
    otherReason: OtherReasonType,
    comments: Comment[],
  ) {
    this.reason = reason;
    this.otherReason = otherReason;
    this.questionId = questionId;
    this.comments = comments;
  }
  questionId: string;
  reason: Reason;
  otherReason: OtherReasonType;
  comments: Comment[];
}

export class OnCommentRequest {
  data:
    | CreateCommentThreadRequest
    | CreateCommentRequest
    | DeleteCommentRequest
    | EditCommentRequest;
  constructor(
    data:
      | CreateCommentThreadRequest
      | CreateCommentRequest
      | DeleteCommentRequest,
  ) {
    this.data = data;
  }
}

export class DeleteCommentRequest extends Comment {
  index: number;
  question: BaseQuestion;
  constructor(
    question: BaseQuestion,
    id: string | undefined,
    text: string,
    index: number,
  ) {
    super(id, text, new Date(), false);
    this.index = index;
    this.id = id;
    this.question = question;
  }
}

export class CommentReplyRequest {
  constructor(
    public text: string,
    public timeStamp: Date,
  ) {}
}

export interface CommentReason {
  value: Reason;
  displayName: string;
}

export class EditCommentRequest extends Comment {
  index: number;
  question: BaseQuestion;
  constructor(
    question: BaseQuestion,
    id: string | undefined,
    text: string,
    index: number,
  ) {
    super(id, text, new Date(), false);
    this.question = question;
    this.index = index;
  }
}

export class ArchivedComments extends CommentThread {
  constructor(
    questionId: string,
    reason: Reason,
    comments: Comment[],
    isAddressed: boolean,
    otherReason?: string,
  ) {
    super(reason, comments, isAddressed, otherReason);
    this.quetionId = questionId;
  }
  quetionId!: string;
}

export enum Reason {
  UploadError = 'UploadError',
  VerifyInfo = 'VerifyInfo',
  MoreInfo = 'MoreInfo',
  Other = 'Other',
}
