import { CommentThread } from '../components/questions/question-comment/question-comment.model';
import { QuestionType } from './QuestionType';

export abstract class BaseQuestion {
  // constructor(id: string){
  //   this.id = id;
  // }

  id!: string;
  title?: string;
  description?: string;
  required: boolean = false;
  readOnly: boolean = false;
  questionType!: QuestionType;
  visible: boolean = true;
  enabled: boolean = true;
  showTitle: boolean = true;
  verticalLayout: boolean = false;
  commentThread?: CommentThread;
  index!: number;
  subtitle?: string;
}

export abstract class Question<T> extends BaseQuestion {
  value?: T;
}
