import { RuleTriggerEvent } from '../../RuleTriggerEvent';
import { TableOperationType } from '../../TableQuestion';

export class SubmitQuestionRequest {
  constructor(
    public flowId: string,
    public questionId: string,
    public ruleTriggerEvent: RuleTriggerEvent,
    public newValue?: any,
    public files?: File[],
  ) {}
}

export class TableOperationRequest {
  constructor(
    public flowId: string,
    public operationType: TableOperationType,
    public ruleTriggerEvent: RuleTriggerEvent,
    public tableQuestionId: string,
    public value?: number | string | string[],
    public cellId?: string,
    public files?: File[],
  ) {}
}
