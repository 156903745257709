export class FormActions {
  formActionType!: FormActionType;
}

export class OpenFileInNewTab extends FormActions {
  fileUri!: string;
  displayName!: string;
}

export enum FormActionType {
  OpenFileInNewTab = 'OpenFileInNewTab',
}
