import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { AffectedQuestion } from 'src/app/models/AffectedQuestion';
import { Question } from 'src/app/models/Question';
import { TableCellValue, TableQuestion } from 'src/app/models/TableQuestion';

@Injectable({
  providedIn: 'root',
})
export class TableFeaturesService {
  private cellDataSource = new Subject<AffectedQuestion | null>();
  currentCellData = this.cellDataSource.asObservable();

  private questionChoicesSource = new Subject<AffectedQuestion | null>();
  currentQuestionChoicesData = this.questionChoicesSource.asObservable();

  private tableDataSource = new Subject<TableQuestion | null>();
  currentTableData = this.tableDataSource.asObservable();

  constructor() {}

  updateCellData(affectedQuestion: AffectedQuestion) {
    this.cellDataSource.next(affectedQuestion);
  }

  updateQuestionChoicesData(affectedQuestion: AffectedQuestion) {
    this.questionChoicesSource.next(affectedQuestion);
  }

  updateTableData(tableQuestion: TableQuestion) {
    this.tableDataSource.next(tableQuestion);
  }

  transformTableData(tableQuestion: TableQuestion): TableData {
    const tableColumns = tableQuestion.tableColumns!;

    const columns = tableColumns.map((column, index) => {
      const headerQuestion = column.headerQuestion;
      headerQuestion.showTitle = false;
      (headerQuestion as Question<TableCellValue>).value = null;
      const columnQuestion = {
        id: index + '',
        title: headerQuestion.title,
        question: headerQuestion,
      } as TableColumn;

      return columnQuestion;
    });
    let hasFooterQuestion = false;
    const footerColumns = tableColumns.map((column) => {
      const footerQuestion = column.footerQuestions[0];
      if (footerQuestion) {
        footerQuestion.showTitle = false;
        hasFooterQuestion = true;
      }
      return footerQuestion ?? {};
    });

    const questionData = Array.from({
      length: tableColumns[0].cells.length,
    }).map((_, rowIndex) => {
      const rowId = tableColumns[0].cells[rowIndex].rowId;

      return tableColumns.reduce<RowObject>(
        (rowAcc, column, index) => {
          const cell = column.cells[rowIndex];

          const {
            id = '',
            value = null,
            enabled = false,
            visible = false,
          } = cell || {};

          const cellQuestion = {
            value,
            id,
            enabled,
            visible,
          };

          rowAcc[`${index}`] = cellQuestion;

          return rowAcc;
        },
        { rowIndex, rowId },
      );
    });

    const cellData = this.convertDataStructure(questionData);

    return {
      columns,
      questionData,
      cellData,
      footerColumns,
      hasFooterQuestion,
    };
  }

  convertDataStructure(data: InputData[]): OutputData[] {
    return data.map((item) => {
      let result: OutputData = {};
      Object.keys(item).forEach((key, index) => {
        const nestedObject = item[key];
        if (nestedObject && typeof nestedObject === 'object') {
          const cellValue = this.transformObjectForCellData(
            nestedObject['value'],
          );
          result[index + ''] = cellValue;
        } else if (key == 'rowId' || key == 'rowIndex') {
          result[key] = nestedObject;
        }
      });

      return result;
    });
  }

  transformObjectForCellData(input: any): string[] {
    if (
      Array.isArray(input) &&
      input.length > 0 &&
      input.every((item) => typeof item === 'object' && 'displayName' in item)
    ) {
      return input.map((user) => user.displayName);
    }
    return input;
  }

  sortSingle(
    sortField: string,
    sortOrder: number,
    value: OutputData[],
  ): OutputData[] {
    // Return early if no field or order provided
    if (!sortField || !sortOrder) {
      return [];
    }

    value.sort((data1, data2) => {
      const value1 = this.resolveFieldData(data1, sortField);
      const value2 = this.resolveFieldData(data2, sortField);

      // Handling nulls
      if (value1 == null && value2 != null) return -1 * sortOrder;
      if (value1 != null && value2 == null) return 1 * sortOrder;
      if (value1 == null && value2 == null) return 0;

      // Comparison logic for strings and others
      if (typeof value1 === 'string' && typeof value2 === 'string') {
        return value1.localeCompare(value2) * sortOrder;
      }
      return this.compareValues(value1, value2, sortOrder);
    });

    // Returns a shallow copy of the array
    return value.slice();
  }

  compareValues(value1: any, value2: any, sortOrder: number): number {
    if (value1 < value2) {
      return -1 * sortOrder;
    } else if (value1 > value2) {
      return 1 * sortOrder;
    } else {
      return 0;
    }
  }

  resolveFieldData(data: any, field: any) {
    if (data && field) {
      if (this.isFunction(field)) {
        return field(data);
      } else if (field.indexOf('.') == -1) {
        return data[field];
      } else {
        let fields = field.split('.');
        let value = data;
        for (let i = 0, len = fields.length; i < len; ++i) {
          if (value == null) {
            return null;
          }
          value = value[fields[i]];
        }
        return value;
      }
    } else {
      return null;
    }
  }

  isFunction(obj: any): boolean {
    return !!(obj?.constructor && obj?.call && obj?.apply);
  }
}

export interface TableColumn {
  id: string;
  title: string;
  question: Question<TableCellValue>;
}

interface InputData {
  [key: string]: any;
}

export interface OutputData {
  [key: string]: string | null | any[] | number;
}

export interface RowObject {
  rowIndex: number;
  rowId: string;
  [key: string]: any;
}

export interface TableData {
  columns: TableColumn[];
  questionData: RowObject[];
  cellData: OutputData[];
  footerColumns: Question<TableCellValue>[];
  hasFooterQuestion: boolean;
}
