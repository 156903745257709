import { Component, Input, OnInit } from '@angular/core';
import { SingleLineOfText } from '../../../models/SingleLineOfText';
import { QuestionBaseComponent } from '../question-base-component';

@Component({
  selector: 'single-line-of-text',
  templateUrl: './single-line-of-text.component.html',
})
export class SingleLineOfTextComponent
  extends QuestionBaseComponent<string>
  implements OnInit
{
  @Input() isCalculatedQuestion?: boolean = false;
  slt!: SingleLineOfText;

  ngOnInit(): void {
    this.slt = new SingleLineOfText(this.question);
  }
}
