import { PatchFlowEventResponse } from '../flow/FlowResponse';
import { TableOperationType, TableQuestion } from '../../TableQuestion';
import { FileUploadDetails } from '@flowforma/ff-components';

export class PatchFlowTableEventResponse extends PatchFlowEventResponse {
  operationType!: TableOperationType;
  tableQuestion!: TableQuestion;
  files?: File[];
  invalidFileUploadDetails?: FileUploadDetails[];
  cellId?: string;
  rowId?: string;
}
