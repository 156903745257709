<div class="d-flex flex-row">
  <i class="bi bi-person-circle fs-3 text-primary me-3"></i>
  <div class="d-flex flex-column">
    <div class="d-flex flex-row">
      <ff-text class="text-secondary" [text]="userDisplayName"></ff-text>
      <ff-text
        class="fs-7 ps-2 text-gray-500"
        [text]="date | relativeTime: null : 'ago'"
      ></ff-text>
    </div>
    <ff-text [text]="remark"></ff-text>
  </div>
</div>
