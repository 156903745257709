<problem-indicator
  *ngIf="invalidQuestionIds.length"
  [errorAmount]="invalidQuestionIds.length"
  [isSideStepperExpanded]="isSideStepperExpanded"
  [problemIndicatorType]="problemIndicatorTypeEnum.Error"
>
</problem-indicator>

<problem-indicator
  *ngIf="selectedStep?.isReOpened && countIssuesToReview() > 0"
  [errorAmount]="countIssuesToReview()"
  [isSideStepperExpanded]="isSideStepperExpanded"
  [problemIndicatorType]="problemIndicatorTypeEnum.Issue"
>
</problem-indicator>

<div
  class="d-flex flex-column"
  [ngClass]="{
    stepper: !isSideStepperExpanded,
    'stepper-expanded': isSideStepperExpanded
  }"
>
  <div
    *ngFor="let step of steps; let i = index"
    class="step position-relative step-container"
  >
    <start-parallel-step-group
      *ngIf="
        step?.parallelStepGroupId &&
        (i === 0 ||
          steps![i - 1].parallelStepGroupId !== step.parallelStepGroupId)
      "
      class="stepper-parallel-step-group-header-flex"
      [class.stepper-parallel-step-group-header]="!isSideStepperExpanded"
      [class.showExpanded]="isSideStepperExpanded"
      [parallelStepGroupName]="
        getParallelStepGroup(step.parallelStepGroupId)?.parallelStepGroupName
      "
      [active]="showStepActive(step)"
      [submitted]="!!step?.completedBy"
      [parallelStepGroupExpanded]="
        getParallelStepGroup(step.parallelStepGroupId)
          ?.parallelStepGroupExpanded
      "
      (parallelStepGroupExpandedUpdated)="
        updateParallelStepGroupState($event, step.parallelStepGroupId)
      "
    >
    </start-parallel-step-group>
    <div class="d-flex">
      <stepper-item
        *ngIf="!step?.parallelStepGroupId"
        [firstStepInFlow]="i === 0"
        [lastStepInFlow]="i === steps!.length - 1"
        [active]="showStepActive(step)"
        [submitted]="!!step?.completedBy"
        [selected]="step?.id === this.selectedStep?.id"
        [disabled]="!step.enabled"
      >
      </stepper-item>

      <parallel-stepper-item
        *ngIf="step?.parallelStepGroupId"
        class="parallel-stepper-item"
        [ngClass]="getParallelStepperItemClass(step)"
        [firstStepInFlow]="isFirstStepInFlow(i)"
        [lastStepInFlow]="isLastStepInFlow(i)"
        [firstStepInParallelStepGroup]="isFirstStepInParallelStepGroup(i, step)"
        [lastStepInParallelStepGroup]="isLastStepInParallelStepGroup(i, step)"
        [active]="showStepActive(step)"
        [submitted]="!!step?.completedBy"
        [selected]="step?.id === selectedStep?.id"
        [disabled]="!step.enabled"
      ></parallel-stepper-item>

      <ul
        [ngClass]="getUlClass(step)"
        class="list-group step-circle-size step-title list-unstyled fw-normal fs-6 mb-0 stepper-ul-item"
      >
        <li
          class="list-group-item pb-lg-0"
          [ngClass]="{
            'pb-5': !isSideStepperExpanded,
            'pb-2': isSideStepperExpanded
          }"
        >
          <div
            class="d-lg-block"
            [ngClass]="{
              'd-none': !isSideStepperExpanded,
              'd-block': isSideStepperExpanded
            }"
          >
            <span
              [attr.tabindex]="step.isFutureStep ? -1 : 0"
              [class.tabbable]="!step.isFutureStep"
              [class.active]="step?.isActive"
              [class.selected]="selectedStep?.id === step?.id"
              class="cursor-pointer"
              (click)="onSelectedStepChange(step, $event)"
              (keydown)="onSelectedStepChange(step, $event)"
              >{{ step.title }}</span
            >
            <i
              *ngIf="step.completedBy && !step.isFutureStep"
              class="ms-1 bi bi-eye"
            ></i>
            <i
              *ngIf="!step.hasPermission && step.isFutureStep"
              class="ms-1 bi bi-lock"
            ></i>
            <i
              *ngIf="selectedStep?.id === step?.id && step.isActive"
              class="ms-1 bi bi-three-dots-vertical cursor-pointer"
              (click)="contextMenu.open()"
            >
              <ff-context-menu
                #contextMenu
                [itemsTemplate]="menuItems"
                [itemsTemplateContext]="{ step: step }"
              ></ff-context-menu>
            </i>
          </div>
          <div
            *ngIf="step?.submittedDate"
            class="fs-8 mb-3 d-lg-block"
            [ngClass]="{
              'd-none': !isSideStepperExpanded,
              'd-block': isSideStepperExpanded
            }"
          >
            Submitted
            {{ step?.submittedDate | mediumDatePipe }}
          </div>
          <div
            *ngIf="step?.description"
            class="fs-8 ms-2 d-lg-block"
            [ngClass]="{
              'd-none': !isSideStepperExpanded,
              'd-block': isSideStepperExpanded
            }"
          >
            {{ step?.description }}
          </div>
          <ul
            class="list-group step-section-title fs-7 list-unstyled fw-normal mt-2 d-lg-block"
            [ngClass]="{
              'd-block': isSideStepperExpanded,
              'd-none': !isSideStepperExpanded
            }"
            *ngIf="selectedStep?.id === step?.id"
          >
            <ng-container
              *ngFor="
                let question of selectedStep?.questions
                  | filterStepper: invalidQuestionIds
              "
            >
              <li
                class="list-group-item cursor-pointer mb-2"
                [class]="getQuestionWithIssuesClass(question)"
                (click)="scrollToElementById(question.id)"
              >
                {{ question.title }}
                <ul
                  class="list-group mt-2"
                  *ngIf="question?.questionType === questionType.Section"
                  style="list-style-type: circle"
                >
                  <ng-container
                    *ngFor="
                      let sectionQuestion of getSectionQuestion(question.id)
                        | filterStepper: invalidQuestionIds
                    "
                  >
                    <li
                      class="list-group-item cursor-pointer mb-2"
                      [class]="getQuestionWithIssuesClass(sectionQuestion)"
                      (click)="scrollToElementById(sectionQuestion.id)"
                    >
                      {{ sectionQuestion.title }}
                    </li>
                  </ng-container>
                </ul>
              </li>
            </ng-container>
          </ul>
        </li>
      </ul>
    </div>
  </div>
</div>
<ng-template #menuItems let-step="step">
  <div class="m-1">
    <button
      type="button"
      class="px-2"
      ngbDropdownItem
      *ngIf="selectedStep?.showDelegate"
      (click)="delegateStep(step)"
    >
      <i class="me-2 bi bi-box-arrow-in-down-right" title="Delegate"></i>
      <span>Delegate this step</span>
    </button>
    <button
      type="button"
      class="px-2"
      ngbDropdownItem
      *ngIf="selectedStep?.enableAttachments"
      (click)="addAttachments(step.id, step.unallowedAttachmentFileTypes)"
    >
      <i class="me-2 bi bi-paperclip" title="Attachments"></i>
      <span>Add attachments</span>
    </button>
    <button type="button" class="px-2" ngbDropdownItem>
      <i class="me-2 bi-envelope-plus" title="Recipient"></i>
      <span>Add recipient</span>
    </button>
  </div>
</ng-template>
