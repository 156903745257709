import { AbstractControl, ValidatorFn } from '@angular/forms';
import {
  FileError,
  FileExtensionPermissionType,
  FileUploadDetails,
  FileValidationErrorType,
} from '@flowforma/ff-components';
import { FileService } from '../services/files/file.service';

export function fileExtensionValidator(
  extensions: string[] | undefined,
  fileExtensionPermissionType: FileExtensionPermissionType | undefined,
): ValidatorFn {
  const fileExtensionValidator = (
    control: AbstractControl,
  ): { [key: string]: any } | null => {
    let invalidFileNames: string[] = [];
    for (let uploadedFileDetails of control.value) {
      if (
        uploadedFileDetails.displayName &&
        extensions &&
        extensions.length > 0 &&
        !FileService.allowedOrDisallowedFileExt(
          uploadedFileDetails.displayName,
          fileExtensionPermissionType,
          extensions,
        )
      ) {
        invalidFileNames.push(uploadedFileDetails.displayName);
      } else if (
        uploadedFileDetails.errorMessages?.some((x: FileError) => {
          if (x.type === FileValidationErrorType.Extension) {
            invalidFileNames.push(x.message);
            return true;
          }
          return false;
        })
      ) {
        continue;
      }
    }
    if (invalidFileNames.length > 0) {
      return {
        invalidFileExtension: invalidFileNames,
      };
    }
    return null;
  };

  return fileExtensionValidator;
}

export function fileSizeValidator(
  fileSizeLimit: number | undefined,
  fileUnitMessage: string,
): ValidatorFn {
  const fileSizeValidator = (
    control: AbstractControl,
  ): { [key: string]: any } | null => {
    let invalidFileNames: string[] = [];
    for (let uploadedFileDetails of control.value) {
      if (
        uploadedFileDetails.fileSize &&
        fileSizeLimit &&
        !FileService.isValidFileSize(
          uploadedFileDetails.fileSize,
          fileSizeLimit,
        )
      ) {
        invalidFileNames.push(
          fileSizeLimit + fileUnitMessage + uploadedFileDetails.displayName,
        );
      } else if (
        uploadedFileDetails.errorMessages?.some((x: FileError) => {
          if (x.type === FileValidationErrorType.Size) {
            invalidFileNames.push(fileSizeLimit + fileUnitMessage + x.message);
            return true;
          }
          return false;
        })
      ) {
        continue;
      }
    }
    if (invalidFileNames.length > 0) {
      return {
        invalidFileSize: invalidFileNames,
      };
    }
    return null;
  };

  return fileSizeValidator;
}

export function fileNameValidation(
  fileListItemUploads: FileUploadDetails[],
): ValidatorFn {
  const fileNameValidation = (
    control: AbstractControl,
  ): { [key: string]: any } | null => {
    let invalidFileNames: string[] = [];
    for (let uploadedFileDetails of control.value) {
      if (
        uploadedFileDetails.displayName &&
        uploadedFileDetails.fileSize != undefined &&
        !FileService.isFileNameUnique(
          uploadedFileDetails.displayName,
          fileListItemUploads,
        )
      ) {
        invalidFileNames.push(uploadedFileDetails.displayName);
      } else if (
        uploadedFileDetails.errorMessages?.some((x: FileError) => {
          if (x.type === FileValidationErrorType.Name) {
            invalidFileNames.push(x.message);
            return true;
          }
          return false;
        })
      ) {
        continue;
      }
    }
    if (invalidFileNames.length > 0) {
      return {
        invalidFileName: invalidFileNames,
      };
    }
    return null;
  };

  return fileNameValidation;
}
