import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { BaseQuestion } from 'src/app/models/Question';
import { Observable } from 'rxjs';
import {
  Comment,
  CommentReplyRequest,
} from 'src/app/components/questions/question-comment/question-comment.model';

@Injectable({
  providedIn: 'root',
})
export class CommentService {
  private _domain: string = environment.ffxFlowApiUrl;

  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
  };

  constructor(private _http: HttpClient) {}

  deleteQuestionComment = (
    flowId: string,
    questionId: string,
    id: string,
  ): Observable<BaseQuestion> => {
    return this._http.delete<BaseQuestion>(
      `${this._domain}/Flow/${flowId}/Question/${questionId}/Comment/${id}`,
    );
  };

  editQuestionComment = (
    flowId: string,
    questionId: string,
    commentDto: Comment,
  ): Observable<BaseQuestion> => {
    return this._http.patch<BaseQuestion>(
      `${this._domain}/Flow/${flowId}/Question/${questionId}/Comment`,
      commentDto,
    );
  };

  commentReply = (
    flowId: string,
    questionId: string,
    comment: CommentReplyRequest,
  ): Observable<BaseQuestion> => {
    return this._http.post<BaseQuestion>(
      `${this._domain}/Flow/${flowId}/Question/${questionId}/Comment`,
      comment,
      this.httpOptions,
    );
  };
}
