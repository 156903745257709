<question-selector
  *ngIf="tableCellQuestion"
  [question]="tableCellQuestion"
  [tableId]="tableId"
  [form]="form"
  [readOnly]="readOnly"
  [flowId]="flowId"
  [showCommentIcon]="showCommentIcon"
  [showErrorText]="showErrorText"
  (questionEvent)="onQuestionEvent($event)"
/>
