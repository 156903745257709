import { Pipe } from '@angular/core';
import { BaseFFxDatePipe } from '@flowforma/core';

@Pipe({
  name: 'fullDatePipe',
})
export class FullDatePipe extends BaseFFxDatePipe {
  override get formatString(): string {
    return 'fullDate';
  }
}
