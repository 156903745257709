export class AffectedQuestion {
  questionId!: string;
  newValue?: any;
  actions?: AffectedQuestionAction[];
  rowId?: string | null;
  errorMessage?: string;
}

export enum AffectedQuestionAction {
  Hide = 'Hide',
  Show = 'Show',
  Enable = 'Enable',
  Disable = 'Disable',
  DataValidationError = 'DataValidationError',
  TableQuestionUpdate = 'TableQuestionUpdate',
  QuestionUpdate = 'QuestionUpdate',
  ChoicesUpdate = 'ChoicesUpdate',
  TableChoicesUpdate = 'TableChoicesUpdate',
  TableUpdate = 'TableUpdate',
}
