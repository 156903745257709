import { Component, OnInit } from '@angular/core';
import { QuestionBaseComponent } from '../question-base-component';
import {
  SummarizationFeedback,
  SummarizationFeedbackType,
} from 'src/app/models/SummarizationFeedback';
import { ButtonType, EvaluationType } from '@flowforma/ff-components';

@Component({
  selector: 'summarization-feedback',
  templateUrl: './summarization-feedback.component.html',
  styleUrl: './summarization-feedback.component.scss',
})
export class SummarizationFeedbackComponent
  extends QuestionBaseComponent<string>
  implements OnInit
{
  buttonTypeEnum: typeof ButtonType = ButtonType;

  summarizationFeedback!: SummarizationFeedback;

  ngOnInit(): void {
    this.summarizationFeedback = this.question as SummarizationFeedback;
  }

  onButtonClick(evaluationResult: EvaluationType): void {
    evaluationResult === EvaluationType.Positive
      ? this.change(SummarizationFeedbackType.Like)
      : this.change(SummarizationFeedbackType.Dislike);
  }
}
