<div [formGroup]="form">
  <ff-separator
    *ngIf="separator"
    [id]="separator.id"
    [tooltip]="separator.description"
    [isDefaultColor]="separator.useDefaultColor"
    [color]="separator.backgroundColor"
  >
  </ff-separator>
</div>
