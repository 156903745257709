import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { catchError, Observable } from 'rxjs';
import { ExceptionHandleService } from '../exception/exception-handle.service';
import { IFlow } from 'src/app/models/IFlow';
import {
  DelegateStepRequest,
  StepAttachmentUploadRequest,
  StepFeedbackRequest,
  StepNoteRequest,
  StepReopenRequest,
  SubmitStepRequest,
} from 'src/app/models/requests/step/StepRequest';
import { PatchFlowEventResponse } from 'src/app/models/responses/flow/FlowResponse';

@Injectable({
  providedIn: 'root',
})
export class StepService {
  private _domain: string = environment.ffxFlowApiUrl;

  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
  };

  constructor(
    private _http: HttpClient,
    private _exceptionHandleService: ExceptionHandleService,
  ) {}

  submitStep = (
    submitStepRequest: SubmitStepRequest,
  ): Observable<PatchFlowEventResponse> => {
    const formData = new FormData();
    formData.append('flowId', submitStepRequest.flowId);
    formData.append('stepId', submitStepRequest.stepId);
    formData.append(
      'ruleTriggerEvent',
      submitStepRequest.ruleTriggerEvent.toString(),
    );
    submitStepRequest.files?.forEach((file) => {
      formData.append('files', file);
    });

    return this._http
      .patch<PatchFlowEventResponse>(`${this._domain}/Flow/Step`, formData)
      .pipe(catchError(this._exceptionHandleService.handleError));
  };

  delegateStep = (
    flowId: string,
    stepId: string,
    delegateStepRequest: DelegateStepRequest,
  ): Observable<IFlow> => {
    return this._http.post<IFlow>(
      `${this._domain}/Flow/${flowId}/Step/${stepId}/Delegate`,
      delegateStepRequest,
      this.httpOptions,
    );
  };

  submitStepFeedback = (
    flowId: string,
    stepId: string,
    stepFeedbackRequest: StepFeedbackRequest,
  ): Observable<IFlow> => {
    return this._http.post<IFlow>(
      `${this._domain}/Flow/${flowId}/Step/${stepId}/Feedback`,
      stepFeedbackRequest,
      this.httpOptions,
    );
  };

  submitStepNote = (
    flowId: string,
    stepId: string,
    stepNoteRequest: StepNoteRequest,
  ): Observable<IFlow> => {
    return this._http.patch<IFlow>(
      `${this._domain}/Flow/${flowId}/Step/${stepId}/Note`,
      stepNoteRequest,
      this.httpOptions,
    );
  };

  reopenStep = (
    flowId: string,
    stepId: string,
    reopenRequest: StepReopenRequest,
  ): Observable<IFlow> => {
    return this._http.patch<IFlow>(
      `${this._domain}/Flow/${flowId}/Step/${stepId}/ReOpen`,
      reopenRequest,
      this.httpOptions,
    );
  };

  stepAttachmentDelete = (
    flowId: string,
    stepId: string,
    attachmentId: string,
  ): Observable<IFlow> => {
    return this._http.delete<IFlow>(
      `${this._domain}/Flow/${flowId}/Step/${stepId}/Attachments/${attachmentId}`,
    );
  };

  stepAttachmentUpload = (
    flowId: string,
    stepId: string,
    stepAttachmentUploadRequest: StepAttachmentUploadRequest,
  ): Observable<IFlow> => {
    const formData = new FormData();
    stepAttachmentUploadRequest.files.forEach((file: File) => {
      formData.append('files', file);
    });
    return this._http.patch<IFlow>(
      `${this._domain}/Flow/${flowId}/Step/${stepId}/Attachments`,
      formData,
    );
  };
}
