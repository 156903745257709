import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { CommonModule, DatePipe, JsonPipe } from '@angular/common';
import {
  CurrencySymbolPipe,
  DateTimeInputPipe,
  DateOnlyInputPipe,
  TimeOnlyInputPipe,
  FFCoreModule,
  UserService,
  LogoutGuard,
} from '@flowforma/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import {
  MsalGuard,
  MsalBroadcastService,
  MsalService,
  MSAL_GUARD_CONFIG,
  MSAL_INSTANCE,
  MsalModule,
  MSAL_INTERCEPTOR_CONFIG,
  MsalInterceptor,
  MsalRedirectComponent,
} from '@azure/msal-angular';

import {
  NgbTooltip,
  NgbAccordionModule,
  NgbDropdownModule,
  NgbNavModule,
  NgbTypeaheadModule,
  NgbPopoverModule,
} from '@ng-bootstrap/ng-bootstrap';

import { AppRoutingModule } from './app-routing.module';

import { AppComponent } from './components/app.component/app.component';
import { HomeComponent } from './components/home/home.component';
import { FormComponent } from './components/forms/form/form.component';
import { StepDetailComponent } from './components/steps/step-detail/step-detail.component';
import { QuestionSelectorComponent } from './components/questions/question-selector/question-selector.component';
import { SingleLineOfTextComponent } from './components/questions/single-line-of-text/single-line-of-text.component';
import { ChoiceComponent } from './components/questions/choice/choice.component';
import { YesNoComponent } from './components/questions/yes-no/yes-no.component';
import { MultipleLinesOfTextComponent } from './components/questions/multiple-lines-of-text/multiple-lines-of-text.component';
import { ButtonQuestionComponent } from './components/questions/button-question/button-question.component';
import { SeparatorComponent } from './components/questions/separator/separator.component';
import { DateAndTimeComponent } from './components/questions/date-and-time/date-and-time.component';
import { NumberComponent } from './components/questions/number/number.component';

import { MultiSelectModule } from 'primeng/multiselect';
import { DropdownModule } from 'primeng/dropdown';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { FFUploadComponent } from './visual-components/ff-upload/ff-upload.component';
import { FileUploadComponent } from './components/questions/file-upload/file-upload.component';
import { StepperComponent } from './components/steps/stepper/stepper.component';
import { SectionComponent } from './components/questions/section/section.component';

import { FFComponentsModule } from '@flowforma/ff-components';
import { LocalesModule } from './Modules/locales/locales.module';
import { TableQuestionComponent } from './components/questions/table/table-question/table-question.component';

import { FFImageAnnotationComponent } from './visual-components/ff-image-annotation/ff-image-annotation.component';
import { TableGridComponent } from './components/questions/table/table-grid/table-grid.component';

import { FilterStepperPipe } from './common/pipes/filter-stepper/filter-stepper.pipe';
import { TableQuestionCardComponent } from './components/questions/table/table-question-card/table-question-card.component';
import { StepSubmissionComponent } from './components/steps/step-submitted/step-submitted.component';
import { PersonOrGroupComponent } from './components/questions/person-or-group/person-or-group.component';
import { DelegateStepDialogComponent } from './components/steps/delegate-step-dialog/delegate-step-dialog.component';
import { AddAttachmentsDialogComponent } from './components/steps/add-attachments-dialog/add-attachments-dialog.component';
import { DelegateStepSuccessDialogComponent } from './components/steps/delegate-step-success-dialog/delegate-step-success-dialog.component';
import { FormReopeningConfirmationDialogComponent } from './components/forms/form-reopening-confirmation-dialog/form-reopening-confirmation-dialog.component';
import { QuestionCommentComponent } from './components/questions/question-comment/question-comment.component';
import { StepReopeningConfirmationDialogComponent } from './components/steps/step-reopening-confirmation-dialog/step-reopening-confirmation-dialog.component';
import { FFPeoplePickerComponent } from './visual-components/ff-people-picker/ff-people-picker.component';
import { FeedbackPanelComponent } from './components/forms/feedback-panel/feedback-panel.component';
import { AddNoteComponent } from './components/steps/add-note/add-note.component';
import { WorkflowHistoryComponent } from './components/forms/workflow-history/workflow-history.component';
import { FormSummaryComponent } from './components/forms/form-summary/form-summary.component';
import { FormNameComponent } from './components/forms/form-name/form-name.component';
import { QuestionCommentThreadComponent } from './components/questions/question-comment-thread/question-comment-thread.component';
import { StepRemarksComponent } from './components/steps/step-remarks/step-remarks.component';
import { UserRemarkComponent } from './components/steps/user-remark/user-remark.component';
import { StepAttachmentsComponent } from './components/steps/step-attachments/step-attachments.component';
import { FormCardComponent } from './components/forms/form-card/form-card.component';
import { RelativeTimePipe } from './common/pipes/relative-time.pipe';
import { CheckboxModule } from 'primeng/checkbox';
import { InputNumberModule } from 'primeng/inputnumber';
import { TableModule } from 'primeng/table';
import {
  MSALGuardConfigFactory,
  MSALInstanceFactory,
  MSALInterceptorConfigFactory,
} from './auth-config';
import { FFxApiInterceptor } from './common/interceptors/ffx-api.interceptor';
import { FullDatePipe } from './common/pipes/ffx-date-pipes/full-date.pipe';
import { ShortDateTimePipe } from './common/pipes/ffx-date-pipes/short-date-time.pipe';
import { MediumDateTimePipe } from './common/pipes/ffx-date-pipes/medium-date-time.pipe';
import { ShortDatePipe } from './common/pipes/ffx-date-pipes/short-date.pipe';
import { ShortTimePipe } from './common/pipes/ffx-date-pipes/short-time.pipe';
import { MediumDatePipe } from './common/pipes/ffx-date-pipes/medium-date.pipe';
import { LongDatePipe } from './common/pipes/ffx-date-pipes/long-date.pipe';
import { CustomDayPipe } from './common/pipes/ffx-date-pipes/custom-day.pipe';
import { WetSignatureComponent } from './components/questions/wet-signature/wet-signature.component';
import { SharePointLookupComponent } from './components/questions/share-point-lookup/share-point-lookup.component';
import { TableFeaturesService } from './components/questions/table/table-features/table-features.service';
import { TableFeaturesComponent } from './components/questions/table/table-features/table-features.component';
import { TableQuestionSelectorComponent } from './components/questions/table/table-question-selector/table-question-selector.component';
import { FormFooterComponent } from './components/forms/form-footer/form-footer.component';
import { SqlLookupComponent } from './components/questions/sql-lookup/sql-lookup.component';
import { ParallelStepGroupComponent } from './components/steps/parallel-step-group/parallel-step-group.component';
import { StepperItemComponent } from './components/steps/stepper/stepper-item/stepper-item.component';
import { ParallelStepperItemComponent } from './components/steps/stepper/parallel-stepper-item/parallel-stepper-item.component';
import { StartParallelStepGroupComponent } from './components/steps/stepper/start-parallel-step-group/start-parallel-step-group.component';
import { SummarizationFeedbackComponent } from './components/questions/summarization-feedback/summarization-feedback.component';
import { ProblemIndicatorComponent } from './components/steps/stepper/problem-indicator/problem-indicator.component';
import { TableFileUploadComponent } from './components/questions/table/table-file-upload/table-file-upload.component';

@NgModule({
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgbTooltip,
    NgbAccordionModule,
    NgbNavModule,
    NgbDropdownModule,
    NgbPopoverModule,
    FormsModule,
    CommonModule,
    ReactiveFormsModule,
    HttpClientModule,
    MultiSelectModule,
    DropdownModule,
    CheckboxModule,
    AutoCompleteModule,
    FFCoreModule,
    FFComponentsModule,
    LocalesModule,
    NgbTypeaheadModule,
    JsonPipe,
    MsalModule,
    InputNumberModule,
    TableModule,
  ],
  declarations: [
    AppComponent,
    HomeComponent,
    FormComponent,
    StepDetailComponent,
    QuestionSelectorComponent,
    SingleLineOfTextComponent,
    ChoiceComponent,
    ButtonQuestionComponent,
    TableQuestionComponent,
    TableQuestionCardComponent,
    YesNoComponent,
    MultipleLinesOfTextComponent,
    SeparatorComponent,
    DelegateStepDialogComponent,
    DateAndTimeComponent,
    NumberComponent,
    StepperComponent,
    FFUploadComponent,
    FileUploadComponent,
    SectionComponent,
    FFImageAnnotationComponent,
    FilterStepperPipe,
    TableGridComponent,
    StepSubmissionComponent,
    DelegateStepSuccessDialogComponent,
    PersonOrGroupComponent,
    AddAttachmentsDialogComponent,
    FormReopeningConfirmationDialogComponent,
    QuestionCommentComponent,
    StepReopeningConfirmationDialogComponent,
    FFPeoplePickerComponent,
    FeedbackPanelComponent,
    AddNoteComponent,
    WorkflowHistoryComponent,
    FormSummaryComponent,
    FormNameComponent,
    QuestionCommentThreadComponent,
    StepRemarksComponent,
    UserRemarkComponent,
    StepAttachmentsComponent,
    FormCardComponent,
    RelativeTimePipe,
    ShortDateTimePipe,
    MediumDateTimePipe,
    ShortDatePipe,
    MediumDatePipe,
    LongDatePipe,
    FullDatePipe,
    ShortTimePipe,
    CustomDayPipe,
    WetSignatureComponent,
    SharePointLookupComponent,
    TableFeaturesComponent,
    TableQuestionSelectorComponent,
    FormFooterComponent,
    SqlLookupComponent,
    ParallelStepGroupComponent,
    StepperItemComponent,
    ParallelStepperItemComponent,
    StartParallelStepGroupComponent,
    SummarizationFeedbackComponent,
    ProblemIndicatorComponent,
    TableFileUploadComponent,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: FFxApiInterceptor,
      multi: true,
    },
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory,
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MSALGuardConfigFactory,
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MSALInterceptorConfigFactory,
    },
    MsalService,
    MsalGuard,
    MsalBroadcastService,
    DatePipe,
    CurrencySymbolPipe,
    DateTimeInputPipe,
    DateOnlyInputPipe,
    TimeOnlyInputPipe,
    ShortDateTimePipe,
    MediumDateTimePipe,
    ShortDatePipe,
    MediumDatePipe,
    LongDatePipe,
    FullDatePipe,
    ShortTimePipe,
    CustomDayPipe,
    LogoutGuard,
    UserService,
    TableFeaturesService,
  ],
  exports: [FilterStepperPipe],
  bootstrap: [AppComponent, MsalRedirectComponent],
})
export class AppModule {}
