import { Component, EventEmitter, Input, Output } from '@angular/core';
import { KeyboardKey } from 'src/app/common/enums/KeyboardKey';

@Component({
  selector: 'start-parallel-step-group',
  templateUrl: './start-parallel-step-group.component.html',
  styleUrls: ['./start-parallel-step-group.component.scss'],
})
export class StartParallelStepGroupComponent {
  @Input() active: boolean = false;
  @Input() submitted: boolean = false;
  @Input() parallelStepGroupExpanded?: boolean = false;
  @Input() parallelStepGroupName?: string;
  @Output() parallelStepGroupExpandedUpdated = new EventEmitter<boolean>();

  toggleParallelStepGroupExpanded(event: MouseEvent | KeyboardEvent): void {
    if (event instanceof KeyboardEvent && event.key !== KeyboardKey.Enter) {
      return;
    }
    if (
      this.parallelStepGroupExpanded == null ||
      this.parallelStepGroupExpanded == undefined
    ) {
      this.parallelStepGroupExpandedUpdated.emit(false);
    } else {
      this.parallelStepGroupExpandedUpdated.emit(
        !this.parallelStepGroupExpanded,
      );
    }
  }
}
