import { ChoiceType } from './ChoiceType';
import { Question } from './Question';
import { VisualType, VisualMapping, VisualMode, ShapeType } from './Visual';

export class Choice extends Question<string[]> {
  constructor(question: Question<string[]>);
  constructor();
  constructor(question?: Question<string[]>) {
    super();
    if (question) {
      Object.assign(this, question);
    }
  }

  choiceType?: ChoiceType;
  choices?: string[];
  multiSelect?: boolean;
  visualType?: VisualType;
  visualMappings?: VisualMapping[];
  visualMode?: VisualMode;
  imageWidth?: number;
  imageMaxWidth?: number;
  shapeType?: ShapeType;
}
