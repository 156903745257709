export class VisualMapping {
  value?: string;
  visualValue?: string;
}

export enum VisualType {
  Colors = 'Colors',
  Images = 'Images',
}

export enum VisualMode {
  Text = 'Text',
  Visual = 'Visual',
  VisualText = 'VisualText',
}

export enum ShapeType {
  Circle = 'Circle',
  Square = 'Square',
}
