<div>
  <div class="mb-2">
    <ff-label
      [label]="label"
      [tooltip]="tooltip"
      [required]="required"
      [showTitle]="showTitle"
      [controlId]="id"
    />
    <ng-content />
  </div>

  <ff-subtitle *ngIf="showSubtitle" [subtitle]="subtitle" />

  <ff-control-validation
    *ngIf="hasErrors && showErrorText"
    [errors]="control.errors"
    [label]="label"
  />

  <div [class.opacity-50]="!enabled">
    <label
      [ngClass]="{ disabled: readOnly || !enabled }"
      class="btn btn-outline-primary d-inline-flex align-items-center p-2 px-3 rounded mb-2"
      *ngIf="!withAnnotation && !hideUploadButton; else annotation"
    >
      <span class="bi bi-upload"></span>
      <div class="fs-6 fw-bold ms-2">Upload</div>
      <!-- Removed formControl=formControl to avoid console errors, real solution will be implemented with file upload functionality -->
      <input
        #fileInput
        [accept]="getAcceptableExtensions()"
        [class.is-invalid]="hasErrors"
        [id]="id"
        type="file"
        class="d-none"
        [multiple]="allowMultiple"
        (change)="handleFileInput($event)"
        [ngClass]="{ disabled: readOnly || !enabled }"
        [attr.disabled]="readOnly || !enabled ? true : null"
      />
    </label>

    <ng-template #annotation>
      <ff-upload-drag-and-drop
        *ngIf="!hideUploadButton"
        [ngClass]="{ 'd-none': files.length && !readOnly }"
        [formControl]="formControl"
        [id]="id"
        (OnChange)="handleFileInput($event)"
        [enabled]="enabled"
        [fileSizeLimit]="fileSizeLimit"
        [readOnly]="readOnly"
      ></ff-upload-drag-and-drop>

      <ff-image-annotation
        *ngIf="files.length > 0 && !readOnly && !hideUploadButton"
        [imageFile]="files[0]"
        [brushColor]="annotationColor"
        [isDrawingMode]="true"
      ></ff-image-annotation>
    </ng-template>

    <ff-upload-list-images
      *ngIf="withPreview && !hideUploadLink"
      [files]="uploadListImageItems"
      (onDelete)="deleteListItem($event)"
      (downloadFile)="downloadFileItem($event)"
      [readOnly]="readOnly"
    ></ff-upload-list-images>

    <ff-upload-list-files
      *ngIf="!withPreview && !hideUploadLink"
      [savedfiles]="savedFiles"
      (onDelete)="deleteListItem($event)"
      (downloadFile)="downloadFileItem($event)"
      [readOnly]="readOnly"
    ></ff-upload-list-files>
  </div>
</div>
