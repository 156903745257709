<div class="card mb-4 alert-divider-information">
  <div class="card-body text-primary fw-normal d-flex flex-column gap-2">
    <div>
      <i
        placement="bottom"
        [ngClass]="{
          'bi me-2 mt-1 cursor-pointer': true,
          'bi-chat-dots text-primary': true
        }"
      ></i>
      <ff-text class="fw-bold d-inline-block" [text]="'Add Feedback'"></ff-text>
    </div>
    <ff-text
      class="d-block fs-7"
      [text]="
        'Leave the form builder specific instructions here before re-opening'
      "
    ></ff-text>
    <div class="d-flex flex-column flex-sm-row gap-2">
      <div class="col-6 d-flex flex-column gap-sm-2">
        <ff-text class="d-block fs-7" [text]="'Return to:'"></ff-text>
        <div>
          <i
            *ngIf="!data?.returnTo?.imgUri"
            class="bi bi-person-circle h5 text-primary me-2"
          ></i>
          <img
            *ngIf="data?.returnTo?.imgUri"
            class="img-fluid rounded-circle w-10 me-2"
            [src]="data?.returnTo?.imgUri"
            alt="image of a person"
          />
          <strong class="text-dark fs-7">{{ data?.returnTo?.name }}</strong>
        </div>
      </div>
      <div
        class="col-8 col-sm-4 d-flex flex-column gap-sm-2"
        [formGroup]="form"
      >
        <ff-text class="d-block fs-7" [text]="'Due date:'"></ff-text>
        <div class="fs-7">
          {{ data?.dueDate | mediumDatePipe }}
          <a
            class="ms-2 link-secondary text-decoration-none cursor-pointer"
            (click)="isEditDueDateEnabled ? cancelEdit() : editDueDate()"
            >{{ isEditDueDateEnabled ? "Cancel" : "Edit" }}</a
          >
        </div>
        <ff-date-only
          *ngIf="isEditDueDateEnabled"
          [id]="'FeedbackPanelSelectDueDate'"
          formControlName="dateSelect"
          [placeHolder]="'Select date'"
          (OnChange)="dueDateChange($event)"
          [errorMessageSuffix]="'due date'"
        ></ff-date-only>
      </div>
    </div>
  </div>
</div>
