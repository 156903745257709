import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {
  FFConfirmationDialogComponent,
  FFTextComponent,
} from '@flowforma/ff-components';
import { ComponentRef, Injectable } from '@angular/core';
import { DelegateStepDialogComponent } from 'src/app/components/steps/delegate-step-dialog/delegate-step-dialog.component';
import { AddAttachmentsDialogComponent } from 'src/app/components/steps/add-attachments-dialog/add-attachments-dialog.component';
import { DelegateStepSuccessDialogComponent } from 'src/app/components/steps/delegate-step-success-dialog/delegate-step-success-dialog.component';
import { IStep } from 'src/app/models/IStep';
import {
  FormReopeningConfirmationDialogComponent,
  FormReopeningData,
} from 'src/app/components/forms/form-reopening-confirmation-dialog/form-reopening-confirmation-dialog.component';
import { IFlow } from 'src/app/models/IFlow';
import { StepReopeningConfirmationDialogComponent } from 'src/app/components/steps/step-reopening-confirmation-dialog/step-reopening-confirmation-dialog.component';
import { FlowService } from 'src/app/common/services/flows/flow.service';
import { User } from 'src/app/models/User';
import { Observable, Subject } from 'rxjs';
import { TableFileUploadComponent } from 'src/app/components/questions/table/table-file-upload/table-file-upload.component';
import { TableColumn } from 'src/app/components/questions/table/table-features/table-features.service';
import { StepService } from '../step/step.service';
import { DelegateStepRequest } from 'src/app/models/requests/step/StepRequest';

@Injectable({
  providedIn: 'root',
})
export class FFModalService {
  constructor(
    private _modalService: NgbModal,
    private _flowService: FlowService,
    private _stepService: StepService,
  ) {}

  openConfirmationDialog(
    title: string,
    message: string,
    func: () => void,
    confirmButtonText?: string,
    cancelButtonText?: string,
    ffTextComponent?: ComponentRef<FFTextComponent>,
  ): void {
    const modalRef = this._modalService.open(FFConfirmationDialogComponent);
    modalRef.componentInstance.title = title;
    modalRef.componentInstance.message = message;
    if (confirmButtonText)
      modalRef.componentInstance.confirmButtonText = confirmButtonText;
    if (cancelButtonText)
      modalRef.componentInstance.cancelButtonText = cancelButtonText;
    modalRef.componentInstance.ffTextComponent = ffTextComponent;

    modalRef.result
      .then((result) => {
        ffTextComponent?.destroy();
        if (result) {
          // Perform the action after confirmation
          func();
        }
      })
      .catch((error) => {
        ffTextComponent?.destroy();

        this.errorCatch(error);
      });
  }

  public openDelegateDialog(
    flowId: string,
    step: IStep,
    flowTitle: string,
    stepIndex: number,
    logo: string,
  ): Observable<IFlow> {
    const modalRef = this._modalService.open(DelegateStepDialogComponent);
    const resultSubject = new Subject<IFlow>();

    modalRef.componentInstance.flowTitle = flowTitle;
    modalRef.componentInstance.stepTitle = step.title;
    modalRef.result
      .then((delegateStepRequest: DelegateStepRequest) => {
        this._stepService
          .delegateStep(flowId, step.id, delegateStepRequest)
          .subscribe((flow: IFlow) => {
            const modalRef = this._modalService.open(
              DelegateStepSuccessDialogComponent,
            );
            modalRef.componentInstance.title = step.title;
            modalRef.componentInstance.assignedTo =
              delegateStepRequest.peopleOrGroup[0].displayName;
            modalRef.componentInstance.flowTitle = flowTitle;
            modalRef.componentInstance.stepIndex = stepIndex + 1;
            modalRef.componentInstance.logo = logo;
            modalRef.componentInstance.formTitle = flow.formTitle;
            resultSubject.next(flow);
            resultSubject.complete();
          });
      })
      .catch((error) => {
        this.errorCatch(error);
      });

    return resultSubject.asObservable();
  }

  /**
   * @description Opens the form reopening modal
   * @param flow the flow
   * @param func onSuccess function
   * @returns { void }
   */
  public openFormReopenModal(
    flow: IFlow,
    func: (formReopeningData: FormReopeningData) => void,
  ): void {
    const modalRef = this._modalService.open(
      FormReopeningConfirmationDialogComponent,
      { size: 'lg' },
    );
    modalRef.componentInstance.flow = flow;
    modalRef.result
      .then((result: FormReopeningData) => {
        func(result);
      })
      .catch((error) => {
        this.errorCatch(error);
      });
  }

  public openStepReopenModal(
    stepNumber: number,
    stepTitle: string,
    stepId: string,
    user: User,
    func: (result: Date) => void,
  ): void {
    const modalRef = this._modalService.open(
      StepReopeningConfirmationDialogComponent,
      { size: 'lg' },
    );
    modalRef.componentInstance.stepNumber = stepNumber;
    modalRef.componentInstance.stepTitle = stepTitle;
    modalRef.componentInstance.stepId = stepId;
    modalRef.componentInstance.stepAssignedTo = user.name;
    modalRef.componentInstance.avatarImage = user.imgUri;
    modalRef.result
      .then((result: Date) => {
        func(result);
      })
      .catch((error) => {
        this.errorCatch(error);
      });
  }

  public openAddAttachmentsDialog(
    stepId: string,
    unallowedAttachmentFileTypes: string[],
    func: (result: File[]) => void,
  ): void {
    const modalRef = this._modalService.open(AddAttachmentsDialogComponent);
    modalRef.componentInstance.stepId = stepId;
    modalRef.componentInstance.unallowedFileTypes =
      unallowedAttachmentFileTypes;
    modalRef.result
      .then((result: File[]) => {
        func(result);
      })
      .catch((error) => {
        this.errorCatch(error);
      });
  }

  public openUploadFileToTableDialog(
    flowId: string,
    questionId: string,
    tableColumns: TableColumn[],
    func: (result: any) => void,
  ): void {
    const modalRef = this._modalService.open(TableFileUploadComponent);
    modalRef.componentInstance.tableColumns = tableColumns;
    modalRef.componentInstance.updateTargetColumn();
    modalRef.componentInstance.flowId = flowId;
    modalRef.componentInstance.questionId = questionId;
    modalRef.result
      .then((result: File[]) => {
        func(result);
      })
      .catch((error) => {
        this.errorCatch(error);
      });
  }

  /**
   * @description Catches the error and logs it.
   * Ignores the error if it is a modal dismiss reason.
   * @param error the error
   * @returns { void }
   */
  private errorCatch(error: any): void {
    if (
      error == ModalDismissReasons.BACKDROP_CLICK ||
      error == ModalDismissReasons.ESC ||
      error == undefined
    ) {
      // Modal dismissed without confirmation
    } else {
      //Should be handled with logging task: #22076
      console.log(error);
    }
  }
}
