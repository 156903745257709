<div
  [ngClass]="{
    'bg-light rounded-bottom': isFooter,
    'rounded-top': !rowIndex && !isFooter
  }"
  class="card p-0 rounded-0"
>
  <div class="card-body pt-0">
    <div ngbAccordion>
      <div
        ngbAccordionItem
        [ngClass]="{ 'bg-light': isFooter }"
        [collapsed]="false"
        [disabled]="false"
      >
        <h2 ngbAccordionHeader>
          <button
            ngbAccordionButton
            [ngClass]="{ 'bg-light': isFooter }"
            class="mb-n3 px-0 shadow-none"
          >
            <div class="d-flex">
              <ng-container *ngIf="!isFooter">
                <ng-container *ngIf="isRowNumbersHidden">
                  <span class="text-black fw-semibold"
                    >#{{ rowIndex + 1 }}</span
                  >
                </ng-container>
                <h6
                  class="mb-0 text-black fw-semibold"
                  [ngClass]="{ 'ps-2': isRowNumbersHidden }"
                >
                  {{ tableData.columns[0].title }}
                </h6>
              </ng-container>

              <h6 class="text-black fw-semibold" *ngIf="isFooter">Total</h6>
            </div>
          </button>
        </h2>
        <div ngbAccordionCollapse>
          <div ngbAccordionBody class="px-4 py-0">
            <ng-template>
              <ng-container *ngIf="!isFooter">
                <ng-container
                  *ngFor="let colData of tableData.columns; let i = index"
                >
                  <div class="pb-2" [ngClass]="{ 'mt-3': !i }">
                    <table-question-selector
                      [question]="colData.question"
                      [tableId]="question.id"
                      [questionId]="rowData[colData.id].id"
                      [form]="form"
                      [visible]="rowData[colData.id].visible"
                      [enabled]="
                        rowData[colData.id].visible &&
                        rowData[colData.id].enabled
                      "
                      [readOnly]="readOnly"
                      [flowId]="flowId"
                      [value]="rowData[colData.id].value"
                      [cardViewMode]="true"
                      [colIndex]="i"
                      (questionEvent)="
                        onQuestionEvent($event, rowData.rowIndex, colData.id)
                      "
                    />
                  </div>
                </ng-container>
              </ng-container>

              <ng-container *ngIf="isFooter">
                <div
                  *ngFor="let footer of tableFooter; let i = index"
                  class="pb-2"
                  [ngClass]="{ 'mt-3': !i }"
                >
                  <table-question-selector
                    [question]="footer"
                    [tableId]="question.id"
                    [questionId]="footer.id"
                    [visible]="footer.visible"
                    [enabled]="footer.enabled"
                    [form]="form"
                    [readOnly]="readOnly"
                    [flowId]="flowId"
                    [showCommentIcon]="false"
                    [cardViewMode]="true"
                    [value]="footer.value"
                    [colIndex]="i"
                  />
                </div>
              </ng-container>

              <ng-container *ngIf="!readOnly">
                <div *ngIf="!isFooter && !isRowsFixed">
                  <button
                    type="button"
                    *ngIf="enableRowDuplication"
                    class="btn"
                    (click)="rowDuplicate(rowData.rowId)"
                  >
                    <i class="bi bi-files text-primary"></i>
                  </button>
                  <button
                    type="button"
                    *ngIf="
                      !isRemoveButtonHidden && tableData.cellData.length > 1
                    "
                    class="btn text-danger"
                    (click)="rowRemove(rowData.rowId)"
                  >
                    <i class="bi bi-trash3"></i>
                  </button>
                </div>
              </ng-container>
            </ng-template>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
