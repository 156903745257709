import { Component, Input } from '@angular/core';
import { ProblemIndicatorType } from '../stepper.component';

@Component({
  selector: 'problem-indicator',
  templateUrl: './problem-indicator.component.html',
  styleUrl: './problem-indicator.component.scss',
})
export class ProblemIndicatorComponent {
  @Input() errorAmount: number = 0;
  @Input() isSideStepperExpanded: boolean = false;
  @Input() problemIndicatorType!: ProblemIndicatorType;

  problemIndicatorTypeEnum: typeof ProblemIndicatorType = ProblemIndicatorType;

  getDisplayText(): string {
    let typeText =
      this.problemIndicatorType === ProblemIndicatorType.Issue
        ? 'Issue'
        : 'Error';
    let pluralText = this.errorAmount > 1 ? 's' : '';
    let actionText =
      this.problemIndicatorType === ProblemIndicatorType.Issue
        ? ' to review'
        : ' found';

    return `${typeText}${pluralText}${actionText}`;
  }
}
