import { Component } from '@angular/core';

import {
  MultipleLinesOfText,
  TextType,
} from '../../../models/MultipleLinesOfText';
import { QuestionBaseComponent } from '../question-base-component';

@Component({
  selector: 'multiple-lines-of-text',
  templateUrl: './multiple-lines-of-text.component.html',
})
export class MultipleLinesOfTextComponent extends QuestionBaseComponent<string> {
  multiLineText!: MultipleLinesOfText;
  textTypeEnum: typeof TextType = TextType;

  ngOnInit(): void {
    this.multiLineText = this.question as MultipleLinesOfText;
  }
}
