<div class="stepper-item">
  <div
    class="stepper-item-top-line"
    [class.hide]="firstStepInFlow"
    [class.active]="active"
    [class.submitted]="submitted"
    [class.selected]="selected"
    [class.disabled]="disabled"
  ></div>
  <div
    *ngIf="!lastStepInFlow"
    class="stepper-item-bottom-line"
    [class.hide]="lastStepInFlow"
    [class.submitted]="submitted"
    [class.selected]="selected"
    [class.disabled]="disabled"
  ></div>
  <div
    class="stepper-item-circle"
    [class.active]="active"
    [class.submitted]="submitted"
    [class.selected]="selected"
    [class.disabled]="disabled"
  ></div>
</div>
