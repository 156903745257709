<div class="card">
  <table
    class="d-grid flex-column align-items-start align-self-stretch p-2 px-4 py-2 overflow-x-scroll"
  >
    <caption class="d-none">
      Table Question
    </caption>
    <thead class="d-flex flex-column px-2">
      <tr class="d-flex align-items-end">
        <th
          *ngIf="this.tableQuestion.showRowNumbers"
          class="flex-column pt-3 col"
        ></th>
        <th
          *ngFor="let column of this.tableQuestion.tableColumns"
          class="d-flex flex-column py-1 px-2 col-auto align-items-left"
          [ngClass]="{
            'table-row-item-width':
              column.headerQuestion.questionType !== 'WetSignature',
            'table-row-item-wetSignature':
              column.headerQuestion.questionType === 'WetSignature'
          }"
        >
          <ff-label
            [label]="column.headerQuestion.title"
            [tooltip]="column.headerQuestion.description"
            [required]="column.headerQuestion.required"
            [showTitle]="column.headerQuestion.showTitle"
            [controlId]="column.headerQuestion.id"
          />
        </th>

        <ng-container [ngTemplateOutlet]="tableIconPlaceholders">
        </ng-container>
      </tr>
    </thead>

    <tbody class="d-flex flex-column px-2">
      <tr
        *ngFor="let row of mappedRows; let rowIndex = index"
        class="d-flex align-items-start justify-content-start"
      >
        <td
          *ngIf="this.tableQuestion.showRowNumbers"
          class="flex-column pt-3 gap-2 fw-bold col"
        >
          #{{ rowIndex + 1 }}
        </td>
        <td
          *ngFor="let cell of row"
          class="d-flex flex-column py-1 px-2 col-auto"
          [ngClass]="{
            'table-row-item-width':
              cell.questionInfo.questionType !== 'WetSignature',
            'table-row-item-wetSignature':
              cell.questionInfo.questionType === 'WetSignature'
          }"
        >
          <question-selector
            [question]="cell.questionInfo"
            [form]="form"
            (questionEvent)="onQuestionEvent($event)"
            [readOnly]="isReadOnly"
            [flowId]="flowId"
            [showCommentIcon]="false"
            [showErrorText]="false"
            [tableId]="question.id"
          />
        </td>

        <ng-container *ngIf="!isRowsFixed && !isReadOnly">
          <td
            class="d-flex flex-column py-1 px-2 align-items-center justify-content-center"
          >
            <button
              type="button"
              class="btn p-0 border-0 mt-3"
              [ngClass]="{ 'd-none': !enableRowDuplication }"
              (click)="rowDuplicate(row[0].cellAnswer.rowId)"
            >
              <i class="bi bi-files text-primary"></i>
            </button>
          </td>
          <td
            class="d-flex flex-column py-1 px-2 align-items-center justify-content-center"
          >
            <button
              type="button"
              class="btn text-danger p-0 border-0 mt-3"
              [ngClass]="{
                'd-none': isRemoveButtonHidden || mappedRows.length <= 1
              }"
              (click)="rowRemove(row[0].cellAnswer.rowId)"
            >
              <i class="bi bi-trash3"></i>
            </button>
          </td>
        </ng-container>
      </tr>

      <tr
        *ngIf="mappedFooterQuestions.length > 0"
        class="d-flex align-items-start"
      >
        <td
          *ngIf="this.tableQuestion.showRowNumbers"
          class="flex-column pt-3 col"
        ></td>

        <td
          *ngFor="let footerQuestions of mappedFooterQuestions"
          class="d-flex flex-column py-1 px-2 col-auto table-row-item-width"
        >
          <div *ngIf="footerQuestions; else empty">
            <ng-container *ngFor="let footerQuestion of footerQuestions">
              <question-selector
                [question]="footerQuestion"
                [form]="form"
                (questionEvent)="onQuestionEvent($event)"
                [readOnly]="isReadOnly"
                [flowId]="flowId"
                [tableId]="question.id"
              />
            </ng-container>
          </div>
          <ng-template #empty></ng-template>
        </td>
        <ng-container [ngTemplateOutlet]="tableIconPlaceholders">
        </ng-container>
      </tr>
    </tbody>
  </table>
</div>

<ng-template #tableIconPlaceholders>
  <ng-container *ngIf="!isRowsFixed && !isReadOnly">
    <td
      class="d-flex flex-column py-1 px-2 align-items-center justify-content-center"
    >
      <i
        class="bi bi-files text-primary invisible"
        [ngClass]="{ 'd-none': !enableRowDuplication }"
      ></i>
    </td>
    <td
      class="d-flex flex-column py-1 px-2 align-items-center justify-content-center"
    >
      <i
        class="bi bi-trash3 invisible"
        [ngClass]="{
          'd-none': isRemoveButtonHidden || mappedRows.length <= 1
        }"
      ></i>
    </td>
  </ng-container>
</ng-template>
