<div class="ff-background m-0 h-100">
  <div class="position-relative" *ngIf="flow$ | async as flow">
    <div
      *ngIf="isSideStepperExpanded"
      class="position-absolute z-2 h-100 w-100 overlay d-block d-lg-none"
      (click)="collapseStepper()"
    ></div>
    <div
      class="d-flex flex-column col-lg-3 z-3 bg-white position-fixed border border-bottom-0 border-start-0 text-dark-emphasis left-panel stepper-max-width-58"
      *ngIf="flow$"
      [ngClass]="{
        'col-10 max-width-320': isSideStepperExpanded,
        'col-2': !isSideStepperExpanded
      }"
    >
      <div
        class="me-0 border-bottom row side-stepper-title-bar mt-2 pt-2 ps-lg-4 align-items-center"
      >
        <div
          class="d-flex gap-2 d-lg-block"
          [ngClass]="{
            'justify-content-center align-items-center': !isSideStepperExpanded
          }"
        >
          <i
            class="bi ps-2 d-lg-none align-self-center"
            (click)="toggleStepper()"
            [ngClass]="{
              'bi-arrow-bar-left': isSideStepperExpanded,
              'bi-arrow-bar-right ms-4 ': !isSideStepperExpanded
            }"
          ></i>
          <div class="col-4">
            <img
              [src]="getLogo"
              class="img-fluid d-lg-inline-flex"
              [ngClass]="{
                'd-none': !isSideStepperExpanded,
                'd-inline': isSideStepperExpanded
              }"
              alt="Logo"
            />
          </div>
        </div>
        <div class="col-8">
          <ff-spinner></ff-spinner>
        </div>
      </div>

      <form-name
        class="d-lg-block me-0 p-form-name px-25 pt-25 pb-2"
        [flowTitle]="flow.title"
        [formTitle]="flow.formTitle"
        [ngClass]="{
          'd-none': !isSideStepperExpanded,
          'd-block': isSideStepperExpanded
        }"
      ></form-name>

      <div class="d-flex" *ngIf="flow.isFlowCompleted">
        <div class="circle-container">
          <div
            class="summary-circle rounded-circle circle-without-line-align-middle"
            [class.selected]="canShowSummaryActive"
            (click)="onSummaryClick($event)"
            (keydown)="onSummaryClick($event)"
          ></div>
        </div>
        <div
          class="summary-title fw-bold cursor-pointer d-lg-block align-self-center tabbable"
          [attr.tabindex]="0"
          [ngClass]="{
            'd-none': !isSideStepperExpanded,
            'd-block': isSideStepperExpanded
          }"
          (click)="onSummaryClick($event)"
          (keydown)="onSummaryClick($event)"
        >
          Summary
        </div>
      </div>

      <stepper
        *ngIf="canShowStepper"
        class="d-block px-0 me-0 stepper-scroll flex-grow-1"
        [form]="form"
        [steps]="flow.steps"
        [selectedStep]="selectedStep"
        [flowTitle]="flow.title"
        [flowId]="formGuid!"
        [stepIndex]="stepIndex"
        [isSideStepperExpanded]="isSideStepperExpanded"
        [initialDisplayStepId]="flow.initialDisplayStepId"
        [logo]="getLogo"
        [parallelStepGroups]="flow.parallelStepGroups"
        (selectedStepUpdated)="onSelectedStepChange($event)"
        (stepDelegateEvent)="onStepDelegateEvent($event)"
        (stepAttachmentUploadEvent)="onStepAttachmentEvent($event)"
        (sideStepperEvent)="onSideStepperEvent($event)"
      >
      </stepper>

      <div
        class="border-top row justify-content-between align-items-center py-2 me-0 d-none d-lg-flex mt-auto"
        [ngClass]="{
          'd-none': !isSideStepperExpanded,
          'd-flex': isSideStepperExpanded
        }"
      >
        <ff-button
          class="ps-4 col"
          [buttonType]="buttonTypeEnum.Secondary"
          [enabled]="true"
          [buttonSize]="buttonSizeEnum.Small"
          [buttonTooltip]="false"
          [id]="'FormSaveAndExitButton'"
          [textValue]="exitBtnText"
          (OnClick)="saveAndExit()"
          ><i class="bi bi-save2" Icon></i
        ></ff-button>

        <div class="col">
          <img
            [src]="poweredByLogo"
            class="img-fluid w-50"
            alt="flowforma logo"
          />
        </div>
      </div>
    </div>

    <div
      class="col-12 col-lg-9 form-detail pe-0 ps-0 form-p-left-58 ms-auto border-top d-flex flex-column justify-content-between min-vh-100"
    >
      <div class="mx-lg-8 my-lg-5 form-content">
        <parallel-step-group
          class="d-block mb-3"
          *ngIf="selectedStep?.parallelStepGroupId"
          [parallelStepGroupName]="
            getParallelStepGroup(selectedStep?.parallelStepGroupId)
              ?.parallelStepGroupName
          "
        ></parallel-step-group>

        <feedback-panel
          *ngIf="reopenMode"
          [data]="feedbackPanelData"
          (changeDueDateEvent)="changeDueDate($event)"
        ></feedback-panel>

        <step-detail
          *ngIf="currentShowComponentEnum === availableComponentEnum.StepDetail"
          [form]="form"
          [step]="selectedStep!"
          [parallelStepGroup]="
            getParallelStepGroup(selectedStep?.parallelStepGroupId)
          "
          [flowCompleted]="flow.isFlowCompleted"
          [stepNumber]="flow.steps.indexOf(selectedStep!) + 1"
          (stepEvent)="onStepEvent($event)"
          (questionEvent)="onQuestionEvent($event)"
          (tableEvent)="onTableEvent($event)"
          (questionErrorEvent)="onQuestionErrorEvent($event)"
          (stepNoteEvent)="onStepNoteEvent($event)"
          (continueEvent)="goNextStep()"
          (addFeedbackEvent)="addFeedback($event)"
          (reopenStepEvent)="confirmReopen($event)"
          (stepAttachmentDelete)="onStepAttachmentEvent($event)"
          (formCancelEvent)="onFormCancelEvent($event)"
          [reopenMode]="reopenMode"
          [flowId]="formGuid!"
          [lastStep]="lastStep"
          [showFormCancel]="flow.showCancel"
          [currentTime]="currentTime"
          [logo]="getLogo"
          [isSideStepperExpanded]="isSideStepperExpanded"
        ></step-detail>

        <step-submitted
          *ngIf="
            currentShowComponentEnum === availableComponentEnum.StepSubmitted
          "
          [flowTitle]="flow.title"
          [formTitle]="flow.formTitle"
          [message]="selectedStep!.title"
          [stepIndex]="stepIndex"
          [lastStep]="lastStep"
          [attachments]="selectedStep!.attachments"
          (stepSubmissionAction)="stepSubmissionAction($event)"
          (stepFeedbackAction)="stepFeedbackAction($event)"
          [logo]="getLogo"
        ></step-submitted>

        <form-summary
          *ngIf="
            currentShowComponentEnum === availableComponentEnum.FormSummary
          "
          [flow]="flow"
          (changeComponentView)="changeComponentView($event)"
          (getWorkflowHistoryEvent)="getWorkflowHistoryEventList()"
          (confirmReopenForm)="confirmFormReopen($event)"
          [logo]="getLogo"
        ></form-summary>

        <workflow-history
          *ngIf="
            currentShowComponentEnum === availableComponentEnum.WorkflowHistory
          "
          [flowTitle]="flow.title"
          [flowFormTitle]="flow.formTitle"
          [flowEvents]="flowEvents"
          [allStepsTitles]="allStepsTitles"
          (changeComponentView)="changeComponentView($event)"
        ></workflow-history>
      </div>
      <form-footer
        *ngIf="
          currentShowComponentEnum === availableComponentEnum.FormSummary ||
          currentShowComponentEnum === availableComponentEnum.StepSubmitted
        "
      ></form-footer>
    </div>
  </div>
</div>
