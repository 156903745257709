<div
  class="border-lg rounded-lg-3 responsive-lg-shadow header-parallel-step-group"
>
  <div class="header-info d-flex align-items-center">
    <i class="bi bi-info-circle text-secondary"></i>
    <div class="fw-semibold d-inline ms-2">
      This step is part of a parallel sequence
    </div>
  </div>
  <div *ngIf="parallelStepGroupName" class="header-parallel-step-group-name">
    {{ parallelStepGroupName }}
  </div>
</div>
