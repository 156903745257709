import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { NgControl } from '@angular/forms';
import { finalize } from 'rxjs/operators';

import {
  FFInputComponentBase,
  ListImageItem,
  FFUploadDragAndDropComponent,
  FileExtensionPermissionType,
  FileUploadDetails,
} from '@flowforma/ff-components';

import { FileService } from 'src/app/common/services/files/file.service';

@Component({
  selector: 'ff-upload',
  templateUrl: './ff-upload.component.html',
  providers: [{ provide: NgControl, useValue: NgControl }],
})
export class FFUploadComponent
  extends FFInputComponentBase
  implements OnInit, OnChanges
{
  @ViewChild('fileInput') fileInput!: ElementRef<HTMLInputElement>;
  @ViewChild(FFUploadDragAndDropComponent)
  childComponent!: FFUploadDragAndDropComponent;

  @Input() allowMultiple = false;
  @Input() withPreview = false;
  @Input() withAnnotation = false;
  @Input() hideUploadButton? = false;
  @Input() hideUploadLink? = false;
  @Input() annotationColor: string = '#FF0000';
  @Input() files: File[] = [];
  @Input() fileSizeLimit?: number;
  @Input() readOnly = false;
  @Input() savedFiles: FileUploadDetails[] = [];
  @Input() extensions?: string[];
  @Input() fileExtensionPermissionType?: FileExtensionPermissionType;

  @Output() onDelete: EventEmitter<FileUploadDetails[]> = new EventEmitter<
    FileUploadDetails[]
  >();

  uploadListImageItems: ListImageItem[] = [];

  constructor(
    protected ngControl: NgControl,
    private readonly _fileService: FileService,
  ) {
    super(ngControl);
  }

  override ngOnInit(): void {
    if (this.savedFiles.length > 0 && this.withPreview) {
      for (let savedFile of this.savedFiles) {
        if (this._fileService.isImageFileName(savedFile.displayName)) {
          if (savedFile.relativeFilePath && savedFile.storageType) {
            this._fileService
              .getImageData(
                savedFile.relativeFilePath,
                savedFile.displayName,
                savedFile.storageType,
              )
              .subscribe((file) => this.updateFilesWithRetrievedBlobFile(file));
          }
        } else {
          this.updateUploadListImageItems();
        }
      }
    }
  }

  updateFilesWithRetrievedBlobFile(file: File | null): void {
    if (file) {
      this.files = [...this.files, file];
      this.updateUploadListImageItems();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['files']) {
      this.updateUploadListImageItems();
    }
  }

  getUploadListFileItems(): FileUploadDetails[] {
    if (this.formControl.value) {
      return [this.formControl.value] as FileUploadDetails[];
    }
    return [];
  }

  handleFileInput($event: Event) {
    let files!: FileList | null;
    if ($event instanceof DragEvent) {
      files = $event.dataTransfer!.files;
    } else {
      files = ($event.target as HTMLInputElement).files;
    }
    if (files?.length) {
      this.onChangeEvent(files);
      // Reset file input value to allow for same file to be uploaded again.
      this.fileInput.nativeElement.value = '';
    }
  }

  /**
   * @description gets the allowed file extensions as string
   * @returns { string | undefined } string of allowed extensions
   */
  getAcceptableExtensions(): string | undefined {
    if (
      this.fileExtensionPermissionType === FileExtensionPermissionType.Allowed
    ) {
      return this.extensions?.join(', ');
    }
    return undefined;
  }

  onDrop($event: DragEvent): void {
    this.onChangeEvent($event);
  }

  updateUploadListImageItems(): void {
    this.uploadListImageItems = this.files
      ? this.files.map((file) => ({ fileName: file.name, image: file }))
      : [];
  }

  deleteListItem(index: number): void {
    if (this.fileInput == undefined && this.childComponent.fileInput) {
      this.childComponent.fileInput.nativeElement.value = '';
    } else {
      this.fileInput.nativeElement.value = '';
    }
    if (this.savedFiles.length > index && index >= 0) {
      const fileToDelete = this.savedFiles[index];
      this._fileService
        .deleteListItemFile(
          fileToDelete.storageType!,
          fileToDelete.relativeFilePath!,
        )
        .pipe(
          // File will be deleted from form regardless of result
          finalize(() => {
            this.removeFile(index);
          }),
        )
        .subscribe({
          error: () => {
            // Handle error
          },
        });
    }
  }

  removeFile(index: number): void {
    let fileDetailsToDelete = this.savedFiles.splice(index, 1);
    if (fileDetailsToDelete.length > 0) {
      this.onChangeEvent(this.savedFiles);
      this.files.splice(index, 1);
      this.updateUploadListImageItems();
      this.onDelete.emit(fileDetailsToDelete);
    }
  }

  downloadFileItem(index: number): void {
    if (
      this.savedFiles.length > index &&
      index >= 0 &&
      this.savedFiles[index].relativeFilePath &&
      this.savedFiles[index].storageType
    ) {
      this._fileService.downloadFileByStorageType(
        this.savedFiles[index].storageType!,
        this.savedFiles[index].displayName,
        this.savedFiles[index].relativeFilePath!,
      );
    }
  }
}
