import { FileUploadDetails } from '@flowforma/ff-components';
import { Question } from './Question';

export class TableQuestion extends Question<void> {
  tableColumns?: TableColumn[];
  showRowNumbers: boolean = false;
  fixedRows = false;
  hideRemoveButton = false;
  newRowCommandLabel!: string;
  enableRowDuplication = false;
  allowExportToCsv = false;
}

export interface TableColumn {
  cells: TableAnswer[];
  headerQuestion: Question<void>;
  footerQuestions: Question<TableCellValue>[];
}
export interface TableCell {
  cellAnswer: TableAnswer;
  questionInfo: Question<TableCellValue>;
}

export interface TableAnswer {
  enabled: boolean;
  id: string;
  rowId: string;
  visible: boolean;
  value: TableCellValue;
}

export interface TableColumnValue {
  displayValue: string;
  value: string;
}

export interface TableColumnMapping {
  sourceColumnName?: string;
  targetColumnId?: string;
}

export type TableCellValue =
  | string
  | number
  | boolean
  | null
  | Array<string | number | boolean | FileUploadDetails>;

export enum TableOperationType {
  AddRow = 'AddRow',
  RemoveRow = 'RemoveRow',
  DuplicateRow = 'DuplicateRow',
  QuestionUpdated = 'QuestionUpdated',
}

export enum TableViewMode {
  Card = 'Card',
  Grid = 'Grid',
}
