import { Component, Input } from '@angular/core';

@Component({
  selector: 'user-remark',
  templateUrl: './user-remark.component.html',
  styleUrls: ['./user-remark.component.scss'],
})
export class UserRemarkComponent {
  @Input() userDisplayName!: string;
  @Input() remark!: string;
  @Input() date!: Date;
}
