import { Component, OnInit } from '@angular/core';
import { QuestionBaseComponent } from '../question-base-component';
import { FFxUser, PersonOrGroupQuestion } from '../../../models/PersonOrGroup';
import { PersonOrGroupService } from '../../../common/services/person-or-group/person-or-group.service';

@Component({
  selector: 'person-or-group',
  templateUrl: './person-or-group.component.html',
})
export class PersonOrGroupComponent
  extends QuestionBaseComponent<FFxUser>
  implements OnInit
{
  constructor(private _personOrGroupService: PersonOrGroupService) {
    super();
  }

  personOrGroup!: PersonOrGroupQuestion;

  ngOnInit() {
    this.personOrGroup = this.question as PersonOrGroupQuestion;
  }

  GetPeopleOrGroupFromQuestion = (term: string) => {
    return this._personOrGroupService.GetPeopleAndGroupsQuestion(
      this.flowId,
      this.personOrGroup.id,
      term,
    );
  };

  get personOrGroupReadOnlyValue(): string {
    return (
      this.personOrGroup.value
        ?.map((item: FFxUser) => item.displayName)
        .join(', ') ?? ''
    );
  }
}
