import { NgModule } from '@angular/core';
import { CommonModule, registerLocaleData } from '@angular/common';
import localeEnAU from '@angular/common/locales/en-AU';
import localeEnCA from '@angular/common/locales/en-CA';
import localeEnGB from '@angular/common/locales/en-GB';
import localeEnIE from '@angular/common/locales/en-IE';
import localeEnUS from '@angular/common/locales/en';
import localeJaJP from '@angular/common/locales/ja';

@NgModule({
  declarations: [],
  imports: [CommonModule],
})
export class LocalesModule {
  constructor() {
    registerLocaleData(localeEnAU);
    registerLocaleData(localeEnCA);
    registerLocaleData(localeEnGB);
    registerLocaleData(localeEnIE);
    registerLocaleData(localeEnUS);
    registerLocaleData(localeJaJP);
  }
}
