import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AlertType } from '@flowforma/ff-components';
import { URIService } from '../../../common/services/uri/uri.service';

@Component({
  selector: 'delegate-step-success-dialog',
  templateUrl: './delegate-step-success-dialog.component.html',
})
export class DelegateStepSuccessDialogComponent {
  constructor(
    public activeModal: NgbActiveModal,
    private _uriService: URIService,
  ) {}

  @Input() title!: string;
  @Input() assignedTo!: string;
  @Input() flowTitle!: string;
  @Input() formTitle!: string;
  @Input() stepIndex!: number;
  @Input() avatarImage!: string;
  @Input() logo!: string;
  defaultLogoImage: string = 'assets/default_logo.png';
  public alertTypeEnum = AlertType;

  public toDashboard(): void {
    this._uriService.redirectToFlowFormaFormsList();
  }

  closeModal(): void {
    this.activeModal.dismiss();
  }
}
