import { Component, EventEmitter, Input, Output } from '@angular/core';
import { SqlLookup, SqlLookupOptionsResponse } from 'src/app/models/SqlLookup';
import { QuestionBaseComponent } from '../question-base-component';
import { LookupService } from 'src/app/common/services/lookup/lookup.service';
import { take } from 'rxjs';
import { QuestionCustomActionControl } from 'src/app/models/QuestionCustomActionError';

@Component({
  selector: 'sql-lookup',
  templateUrl: './sql-lookup.component.html',
})
export class SqlLookupComponent extends QuestionBaseComponent<string> {
  sqlLookup!: SqlLookup;
  options: string[] = [];

  @Input() tableId?: string;

  @Output() questionErrorEvent =
    new EventEmitter<QuestionCustomActionControl>();

  constructor(private _lookupService: LookupService) {
    super();
  }

  ngOnInit(): void {
    this.sqlLookup = this.question as SqlLookup;
    this.options =
      this.sqlLookup.value !== null && this.sqlLookup.value !== undefined
        ? [this.sqlLookup.value]
        : [];
  }

  retrieveOptions() {
    let controlValue = this.form.get(this.sqlLookup.id)?.value;
    if (controlValue === null) {
      this.options = [];
    }

    this._lookupService
      .GetSqlLookupQuestionOptions(this.flowId, this.sqlLookup.id, this.tableId)
      .pipe(take(1))
      .subscribe((optionsResponse: SqlLookupOptionsResponse) => {
        this.options = optionsResponse.options;
        this.questionErrorEvent.emit(
          new QuestionCustomActionControl(
            this.sqlLookup.id,
            optionsResponse.affectedQuestion,
            this.tableId,
          ),
        );
      });
  }

  convertValue(value: string[]) {
    this.change(value[0]);
  }
}
