<div [formGroup]="form">
  <div *ngIf="number && !readOnly" [ngSwitch]="number.useSlider">
    <ff-input-number
      *ngSwitchCase="false"
      [formControlName]="number.id"
      [tooltip]="number.description"
      [label]="number.title"
      [id]="number.id"
      [required]="number.required!"
      [enabled]="number.enabled"
      [showTitle]="number.showTitle"
      [minimum]="number.min"
      [maximum]="number.max"
      [decimalPlaces]="number.decimalPlaces"
      [culture]="number.culture"
      [format]="number.format"
      (OnChange)="change($event)"
      [isInputReadOnly]="isCalculatedQuestion"
      [showErrorText]="showErrorText"
      [disableThousandGrouping]="number.disableThousandGrouping"
    >
      <ng-container *ngTemplateOutlet="commentIcon" />
    </ff-input-number>

    <ff-input-number-slider
      *ngSwitchCase="true"
      [formControlName]="number.id"
      [tooltip]="number.description"
      [label]="number.title"
      [id]="number.id"
      [required]="number.required!"
      [enabled]="number.enabled"
      [showTitle]="number.showTitle"
      [minimum]="number.min"
      [maximum]="number.max"
      [decimalPlaces]="number.decimalPlaces"
      [culture]="number.culture"
      [format]="number.format"
      (OnChange)="change($event)"
      [showErrorText]="showErrorText"
      [disableThousandGrouping]="number.disableThousandGrouping"
    >
      <ng-container *ngTemplateOutlet="commentIcon" />
    </ff-input-number-slider>
  </div>
  <ff-textbox
    *ngIf="number && readOnly"
    [tooltip]="number.description"
    [label]="number.title"
    [id]="number.id"
    [required]="number.required!"
    [showTitle]="number.showTitle"
    [value]="getReadOnlyString"
    [showComment]="showComment"
    [enabled]="number.enabled"
  >
    <ng-container *ngTemplateOutlet="commentIcon" />
  </ff-textbox>
</div>
<ng-template #commentIcon>
  <ng-content />
</ng-template>
