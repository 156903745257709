<div [formGroup]="form">
  <div *ngIf="!readOnly">
    <ff-lookup
      [formControlName]="sharePointLookup.id"
      [tooltip]="sharePointLookup.description"
      [label]="sharePointLookup.title"
      [id]="sharePointLookup.id"
      [placeHolder]="'Please select'"
      [required]="sharePointLookup.required"
      [showTitle]="sharePointLookup.showTitle"
      [enabled]="sharePointLookup.enabled"
      [options]="options ?? []"
      [filter]="true"
      [showClear]="true"
      (lookupDropdownShow)="retrieveOptions()"
      (OnChange)="change($event)"
      [showErrorText]="showErrorText"
    >
      <ng-container *ngTemplateOutlet="commentIcon"></ng-container>
    </ff-lookup>
  </div>
  <div *ngIf="readOnly">
    <ff-textbox
      [tooltip]="sharePointLookup.description"
      [label]="sharePointLookup.title"
      [id]="sharePointLookup.id"
      [required]="sharePointLookup.required"
      [showTitle]="sharePointLookup.showTitle"
      [showComment]="showComment"
      [enabled]="sharePointLookup.enabled"
      [value]="sharePointLookup.value?.value"
    >
      <ng-container *ngTemplateOutlet="commentIcon" />
    </ff-textbox>
  </div>
</div>

<ng-template #commentIcon>
  <ng-content></ng-content>
</ng-template>
