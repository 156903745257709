<ff-message-divider
  *ngIf="step.completedBy && !reopenMode"
  class="d-block mx-4 mt-3 mx-lg-0 mt-lg-0"
  [alertType]="alertTypeEnum.StepSubmittedHistory"
  title="Completed"
  [message]="(step.submittedDate | fullDatePipe)!"
>
  <div class="above-message mt-1">
    <ff-text [text]="step.completedBy" class="d-block mt-1">
      <i class="bi bi-person-circle h5 text-primary me-2"></i>
    </ff-text>
  </div>
</ff-message-divider>

<ff-message-divider
  *ngIf="step.isReOpened && !checkStepOverdue"
  class="d-block mx-4 mt-4 mx-lg-0 mt-lg-0"
  [alertType]="alertTypeEnum.Warning"
  title="Re-opened"
  [message]="
    step.dueDate ? 'Due date: ' + (step.dueDate | mediumDatePipe) : undefined
  "
  icon="bi bi-reply-all-fill"
></ff-message-divider>

<ff-message-divider
  *ngIf="step.isReOpened && checkStepOverdue"
  class="d-block mx-4 mt-4 mx-lg-0 mt-lg-0"
  [alertType]="alertTypeEnum.Warning"
  [icon]="'bi bi-clock'"
  [title]="'Overdue'"
>
  <div class="above-message">
    This step is now overdue by
    <span class="fw-bold">{{ step.dueDate! | relativeTime: currentTime }}</span
    >. If you are unable to complete this please contact:
    <hr />
  </div>
  <div class="below-message fs-7">
    <a class="text-decoration-underline text-reset">{{
      step.reopenedBy ?? ""
    }}</a>
  </div>
</ff-message-divider>

<form-card *ngIf="step" [reopenMode]="reopenMode">
  <ff-validation-summary
    [formGroup]="form"
    [ngClass]="{
      'd-none': !showValidationSummary,
      invisible: !showValidationSummary
    }"
  ></ff-validation-summary>

  <div class="d-inline-flex">
    <img
      [src]="logo"
      class="col-5 col-sm-3 col-md-2 col-lg-3 col-xl-2"
      alt="Logo"
    />
  </div>
  <div>
    <h4>{{ step.title }}</h4>
    <ff-text
      *ngIf="reopenMode"
      class="fw-light"
      [text]="'Step ' + stepNumber"
    ></ff-text>
  </div>

  <ff-text [text]="step.description"></ff-text>

  <div class="mt-2 mb-5">
    <ff-separator></ff-separator>
    <div class="d-flex flex-column-reverse flex-sm-row gap-2">
      <ff-button
        *ngIf="canShowAddNotes()"
        class="add-note d-block"
        [id]="'StepDetailAddNoteButton'"
        [buttonType]="buttonTypeEnum.Note"
        [textValue]="'Add notes'"
        [enabled]="true"
        [buttonSize]="buttonSizeEnum.Small"
        [buttonTooltip]="false"
        (OnClick)="addNotes()"
        ><i class="bi bi-plus-square ps-2 ps-sm-0 pe-1 pe-sm-0" Icon></i
      ></ff-button>
      <ff-button
        *ngIf="canShowReopen()"
        class="reopen-step"
        [id]="'StepDetailReopenStepButton'"
        [buttonType]="buttonTypeEnum.Warning"
        textValue="Re-open step"
        [enabled]="true"
        [buttonSize]="buttonSizeEnum.Small"
        [buttonTooltip]="false"
        (OnClick)="reopenStep()"
        ><i class="bi bi-reply-all-fill" Icon></i
      ></ff-button>
    </div>
    <add-note
      *ngIf="isAddNoteCommentActive"
      (addNoteAction)="updateNoteAction($event)"
    ></add-note>
    <step-remarks
      *ngIf="flowCompleted"
      [step]="step"
      (questionIdToAnchor)="anchorToQuestion($event)"
    ></step-remarks>
  </div>

  <form [formGroup]="form">
    <div
      *ngFor="let question of step.questions"
      [ngClass]="{
        'mx-n45 px-45 alert-divider-warning':
          step.isReOpened &&
          question.commentThread &&
          !question.commentThread.isAddressed,
        'pb-40': !step.isReadOnly
      }"
    >
      <question-selector
        [question]="question"
        [form]="form"
        (questionEvent)="onQuestionEvent($event)"
        (tableEvent)="onTableEvent($event)"
        (questionErrorEvent)="onQuestionErrorEvent($event)"
        *ngIf="question.visible"
        [readOnly]="step.isReadOnly"
        [flowId]="flowId"
        [isStepCompleted]="isStepCompleted"
        [reopenMode]="reopenMode"
      />
    </div>
  </form>

  <step-attachments
    *ngIf="showAttachments"
    [attachments]="step.attachments!"
    [isStepCompleted]="isStepCompleted || reopenMode"
    (stepAttachmentDelete)="deleteAttachment($event)"
  ></step-attachments>

  <div class="d-flex justify-content-end" *ngIf="reopenMode">
    <ff-button
      class="cancel-reopen"
      [id]="'StepDetailCancelFormReopenButton'"
      [buttonType]="buttonTypeEnum.Secondary"
      textValue="Cancel"
      [enabled]="true"
      [buttonSize]="buttonSizeEnum.Medium"
      [buttonTooltip]="false"
      (OnClick)="cancelReopen()"
    ></ff-button>
    <ff-button
      class="ms-2 confirm-reopen"
      [id]="'StepDetailConfirmFormReopenButton'"
      [buttonType]="buttonTypeEnum.Primary"
      textValue="Re-open step"
      [enabled]="true"
      [buttonSize]="buttonSizeEnum.Medium"
      [buttonTooltip]="false"
      (OnClick)="confirmReopen()"
    ></ff-button>
  </div>

  <div
    *ngIf="canShowStepNavigationButtons"
    class="col-12 mt-5 form-detail ms-auto d-flex justify-content-end gap-2 flex-column-reverse flex-sm-row step-navigation-buttons"
  >
    <button
      *ngIf="showFormCancel"
      type="button"
      class="btn btn-outline-primary btn-lg"
      (click)="cancelForm()"
    >
      Cancel
    </button>
    <button
      *ngIf="canShowStepSubmit"
      type="button"
      class="btn btn-primary btn-lg btn-step-submit"
      [class.z-3]="!isSideStepperExpanded"
      (click)="triggerChange(step.id!, ruleTriggerEvent.StepCompleting)"
    >
      Submit
    </button>
    <button
      *ngIf="canShowContinue"
      type="button"
      class="btn btn-primary btn-lg btn-continue"
      (click)="continueBtnClick()"
    >
      Continue
    </button>
  </div>
</form-card>
