<div [formGroup]="form">
  <ff-evaluation-buttons
    *ngIf="summarizationFeedback && !readOnly"
    [formControlName]="summarizationFeedback.id"
    [tooltip]="summarizationFeedback.description"
    [label]="summarizationFeedback.title"
    [id]="summarizationFeedback.id"
    [required]="summarizationFeedback.required"
    [showTitle]="summarizationFeedback.showTitle"
    [enabled]="summarizationFeedback.enabled"
    [showErrorText]="showErrorText"
    [positiveButtonText]="'Like'"
    [negativeButtonText]="'Dislike'"
    [positiveButtonStyle]="buttonTypeEnum.Secondary"
    [negativeButtonStyle]="buttonTypeEnum.Secondary"
    (OnChange)="onButtonClick($event)"
  >
    <ng-container *ngTemplateOutlet="commentIcon" />
  </ff-evaluation-buttons>

  <ff-textbox
    *ngIf="summarizationFeedback && readOnly"
    [tooltip]="summarizationFeedback.description"
    [label]="summarizationFeedback.title"
    [id]="summarizationFeedback.id"
    [required]="summarizationFeedback.required!"
    [showTitle]="summarizationFeedback.showTitle"
    [value]="summarizationFeedback.value"
    [showComment]="showComment"
    [enabled]="summarizationFeedback.enabled"
  >
    <ng-container *ngTemplateOutlet="commentIcon"></ng-container>
  </ff-textbox>
</div>

<ng-template #commentIcon>
  <ng-content />
</ng-template>
