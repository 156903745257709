import { Component } from '@angular/core';

import { YesNo, YesNoOptions } from '../../../models/YesNo';
import { QuestionBaseComponent } from '../question-base-component';

@Component({
  selector: 'yes-no',
  templateUrl: './yes-no.component.html',
})
export class YesNoComponent extends QuestionBaseComponent<YesNoOptions> {
  yesNo!: YesNo;

  ngOnInit(): void {
    this.yesNo = this.question as YesNo;
  }
}
