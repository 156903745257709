<div class="modal-header">
  <h5 class="modal-title">Add attachments</h5>
  <i class="bi bi-x-lg" (click)="closeModal()"></i>
</div>

<div class="modal-body">
  <ff-text
    class="fw-bold"
    [text]="'Here you can share additional documents related to this step'"
  ></ff-text>
  <div class="note">
    <ff-text [text]="'Please note:'"></ff-text>
    <ul>
      <li>Uploading {{ unallowedFileTypes }} files is not allowed</li>
    </ul>
  </div>
  <div [formGroup]="form">
    <ff-upload
      formControlName="fileInput"
      [extensions]="unallowedFileTypes"
      [allowMultiple]="true"
      [fileExtensionPermissionType]="fileExtensionPermissionType.Disallowed"
      (OnChange)="change($event)"
      [files]="getFileArray()"
    >
    </ff-upload>
    <div *ngFor="let file of validFiles; let i = index">
      <ff-upload-list-file-item
        [fileName]="file.name"
        [readOnly]="false"
        (onDelete)="deleteValidFile(i)"
      ></ff-upload-list-file-item>
    </div>
    <div *ngFor="let file of invalidFiles; let i = index">
      <ff-upload-list-file-item
        [fileName]="file.name"
        [readOnly]="false"
        [isFileValid]="false"
        (onDelete)="deleteInvalidFile(i)"
      ></ff-upload-list-file-item>
    </div>
  </div>

  <div class="modal-footer border-0">
    <button
      type="button"
      class="btn btn-outline-primary"
      (click)="closeModal()"
    >
      Cancel
    </button>
    <button
      type="button"
      class="btn btn-primary"
      (click)="confirmAddAttachments()"
    >
      Save
    </button>
  </div>
</div>
