import { AfterViewInit, Component, Input, ViewChild } from '@angular/core';
import { Observable, firstValueFrom } from 'rxjs';
import { FFxUser } from 'src/app/models/PersonOrGroup';
import { PersonOrGroupService } from 'src/app/common/services/person-or-group/person-or-group.service';
import { NgControl } from '@angular/forms';
import { FFInputComponentBase } from '@flowforma/ff-components';
import {
  AutoComplete,
  AutoCompleteSelectEvent,
  AutoCompleteUnselectEvent,
} from 'primeng/autocomplete';

@Component({
  selector: 'ff-people-picker',
  templateUrl: './ff-people-picker.component.html',
  styleUrls: ['./ff-people-picker.component.scss'],
  providers: [{ provide: NgControl, useValue: NgControl }],
})
export class FFPeoplePickerComponent
  extends FFInputComponentBase
  implements AfterViewInit
{
  @ViewChild('autoComplete') autoComp?: AutoComplete;

  constructor(
    private _personOrGroupService: PersonOrGroupService,
    protected ngControl: NgControl,
  ) {
    super(ngControl);
  }

  @Input() customSearchFunction?: (term: string) => Observable<FFxUser[]>;

  @Input()
  value?: FFxUser[];

  @Input()
  allowMultiple: boolean = false;

  options!: FFxUser[];

  override ngOnInit() {
    super.ngOnInit();
    const value = (this.formControl.value ?? this.value) || [];
    this.formControl.patchValue(value);
  }

  ngAfterViewInit() {
    this.updateAutoCompleteState();
  }

  /**
   * @description Readonly stops all user input, so instead we apply the readonly attribute to the input element
   */
  updateAutoCompleteState(): void {
    if (this.autoComp) {
      const inputElement =
        this.autoComp.el.nativeElement.querySelector('input');
      if (inputElement) {
        if (this.isReadOnly) {
          inputElement.setAttribute('readonly', 'true');
        } else {
          inputElement.removeAttribute('readonly');
        }
      }
    }
  }

  async search(event: AutoCompleteCompleteEvent): Promise<void> {
    let response: Observable<FFxUser[]>;
    if (this.customSearchFunction) {
      response = this.customSearchFunction(event.query);
    } else {
      response = this._personOrGroupService.GetPeopleAndGroups(event.query);
    }
    const responseValues = await firstValueFrom(response);
    this.options = responseValues ?? [];
  }

  get isReadOnly(): boolean {
    return this.formControl.value?.length > 0 && !this.allowMultiple;
  }

  onSelect(option: AutoCompleteSelectEvent): void {
    this.updateAutoCompleteState();
    this.onChangeEvent(this.formControl.value);
  }

  onUnselect(value: AutoCompleteUnselectEvent): void {
    this.updateAutoCompleteState();
    this.onChangeEvent(this.formControl.value);
  }

  getFFxUserEmail(loginName?: string): string {
    if (loginName) {
      const splitLoginName = loginName.split('|');
      return splitLoginName.length == 3 ? splitLoginName[2] : '';
    }
    return '';
  }
}

export interface AutoCompleteCompleteEvent {
  originalEvent: Event;
  query: string;
}
