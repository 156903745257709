import { Component, OnInit } from '@angular/core';
import { UserService } from '@flowforma/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})
export class AppComponent implements OnInit {
  title = 'FlowForma.FFx.FormClient';
  user: any;

  constructor(private _userService: UserService) {}

  ngOnInit(): void {
    this._userService.subscribeToUser().subscribe((user) => {
      this.user = user;
    });
  }
}
