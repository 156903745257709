<div ngbAccordion class="mb-5">
  <div ngbAccordionItem class="border bg-transparent">
    <h2 ngbAccordionHeader>
      <button ngbAccordionButton class="p-3 gap-3 bg-transparent">
        <i class="bi bi-paperclip"></i>
        <ff-text class="fw-semibold" [text]="attachmentTitle"></ff-text>
      </button>
    </h2>
    <div ngbAccordionCollapse>
      <div ngbAccordionBody class="py-0">
        <ng-template>
          <div class="d-flex flex-column pt-2">
            <div *ngFor="let attachment of attachments; let i = index">
              <ff-upload-list-file-item
                [fileName]="attachment.displayName"
                [readOnly]="isStepCompleted"
                (onClick)="fileDownload(attachment)"
                (onDelete)="fileDelete(attachment)"
              ></ff-upload-list-file-item>
            </div>
          </div>
        </ng-template>
      </div>
    </div>
  </div>
</div>
