import { Question } from './Question';

export class DateAndTime extends Question<Date> {
  dateDisplayFormat?: DateDisplayFormat;
  timeFormat?: string;
  timeInterval?: string;
}

export enum DateDisplayFormat {
  DateAndTime = 'DateAndTime',
  DateOnly = 'DateOnly',
  TimeOnly = 'TimeOnly',
}
