<div class="modal-header">
  <h4 class="modal-title">Delegate step</h4>
  <button class="btn-clear" (click)="closeModal()">
    <i class="bi bi-x-lg"></i>
  </button>
</div>

<div class="modal-body">
  <h2>{{ flowTitle }}</h2>
  <h6>{{ stepTitle }}</h6>

  <ff-separator />

  <div [formGroup]="form">
    <ff-people-picker
      [id]="'DelegateStepDialogUserOrGroupToDelegateTo'"
      [label]="'Delegate this step to'"
      [required]="true"
      [formControlName]="'peopleOrGroup'"
      [placeHolder]="'Enter email address'"
      [errorMessageSuffix]="'user or group to delegate this step to'"
    />
    <div class="py-2"></div>
    <ff-textarea
      [id]="'DelegateStepDialogDelegateComments'"
      class="mt-2"
      [label]="'Comments'"
      [numberOfLines]="3"
      [placeHolder]="'Optional'"
      [readOnly]="false"
      [formControlName]="'comment'"
    ></ff-textarea>
  </div>

  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-outline-primary"
      (click)="activeModal.dismiss()"
    >
      Cancel
    </button>
    <button type="button" class="btn btn-primary" (click)="confirmDelegate()">
      Delegate step
    </button>
  </div>
</div>
