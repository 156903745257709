import { Component, AfterViewInit, OnInit, Input } from '@angular/core';
import { IEvent, Object } from 'fabric/fabric-impl';

declare const fabric: any;

@Component({
  selector: 'ff-image-annotation',
  templateUrl: './ff-image-annotation.component.html',
  styleUrls: ['./ff-image-annotation.component.scss'],
})
export class FFImageAnnotationComponent implements AfterViewInit, OnInit {
  private canvas!: fabric.Canvas;
  private isMiddleMouseDown: boolean = false;
  private lastMiddleMouseX: number = 0;
  private lastMiddleMouseY: number = 0;
  brushTypeEnum: typeof BrushType = BrushType;

  @Input() imageFile!: File;
  @Input() imageWidth: number = 800;
  @Input() imageHeight: number = 600;
  @Input() brushColor: string = '#FF0000';
  @Input() brushSize: number = 5;
  @Input() isDrawingMode!: boolean;

  ngOnInit(): void {
    this.canvas = new fabric.Canvas('canvas', {
      width: this.imageWidth,
      height: this.imageHeight,
      fireMiddleClick: true,
      isDrawingMode: this.isDrawingMode,
    });
  }

  ngAfterViewInit(): void {
    // Load the image on the canvas
    if (this.imageFile) {
      let imageUrl = URL.createObjectURL(this.imageFile);

      fabric.Image.fromURL(imageUrl, (img: any) => {
        let scaleFactor = Math.min(
          this.imageWidth / img.width!,
          this.imageHeight / img.height!,
        );
        img.erasable = false;

        // Scale image to fit canvas and center it
        img.scale(scaleFactor);
        img.set({
          left: (this.imageWidth - img.width! * scaleFactor) / 2,
          top: (this.imageHeight - img.height! * scaleFactor) / 2,
        });

        this.canvas.add(img);
        this.canvas.renderAll();
      });
    }

    // Set default brush color and width
    this.canvas.freeDrawingBrush.width = this.brushSize;
    this.canvas.freeDrawingBrush.color = this.brushColor;

    // Enable zooming functionality
    this.zoomFunctionality();

    // Enable panning functionality
    this.panningFunctionality();
  }

  setBrushType(type: BrushType): void {
    switch (type) {
      case BrushType.Eraser:
        // Add eraser functionality here
        this.canvas.freeDrawingBrush = new fabric.EraserBrush(this.canvas);
        this.canvas.freeDrawingBrush.width = this.brushSize;
        break;
      case BrushType.Highlighter:
        this.canvas.freeDrawingBrush = new fabric.PencilBrush(this.canvas);
        this.canvas.freeDrawingBrush.width = this.brushSize;
        this.canvas.freeDrawingBrush.color = 'rgba(255, 250, 90, 0.3)';
        break;
      case BrushType.Pencil:
        this.canvas.freeDrawingBrush = new fabric.PencilBrush(this.canvas);
        this.canvas.freeDrawingBrush.width = this.brushSize;
        this.canvas.freeDrawingBrush.color = this.brushColor;
        break;
    }
  }

  toggleDrawingMode(): void {
    this.canvas.isDrawingMode = !this.canvas.isDrawingMode;
  }

  changeBrushColor(event: Event): void {
    this.brushColor = (event.target as HTMLInputElement).value;
    this.canvas.freeDrawingBrush.color = this.brushColor;
  }

  onBrushSizeChange(event: Event): void {
    this.brushSize = parseInt((event.target as HTMLInputElement).value);
    this.canvas.freeDrawingBrush.width = this.brushSize;
  }

  clearDrawings(): void {
    const drawingObjects = this.canvas
      .getObjects()
      .filter((obj: Object) => obj.isType('path'));

    drawingObjects.forEach((obj: Object) => {
      this.canvas.remove(obj);
    });
  }

  private zoomFunctionality(): void {
    this.canvas.on('mouse:wheel', (opt: IEvent<WheelEvent>) => {
      let delta = opt.e.deltaY;
      let zoom = this.canvas.getZoom();
      zoom *= 0.999 ** delta;
      if (zoom > 20) zoom = 20;
      if (zoom < 0.01) zoom = 0.01;
      this.canvas.zoomToPoint({ x: opt.e.offsetX, y: opt.e.offsetY }, zoom);
      opt.e.preventDefault();
      opt.e.stopPropagation();
    });
  }

  private panningFunctionality(): void {
    this.canvas.on('mouse:down', (opt: IEvent<MouseEvent>) => {
      if (opt.e.button === 1) {
        this.isMiddleMouseDown = true;
        this.lastMiddleMouseX = opt.e.clientX;
        this.lastMiddleMouseY = opt.e.clientY;
        opt.e.preventDefault();
        opt.e.stopPropagation();
      }
    });

    this.canvas.on('mouse:up', (opt: IEvent<MouseEvent>) => {
      if (opt.e.button === 1) {
        this.isMiddleMouseDown = false;
        opt.e.preventDefault();
        opt.e.stopPropagation();
      }
    });

    this.canvas.on('mouse:move', (opt: IEvent<MouseEvent>) => {
      if (this.isMiddleMouseDown) {
        let offsetX = opt.e.clientX - this.lastMiddleMouseX;
        let offsetY = opt.e.clientY - this.lastMiddleMouseY;
        this.lastMiddleMouseX = opt.e.clientX;
        this.lastMiddleMouseY = opt.e.clientY;

        this.canvas.viewportTransform![4] += offsetX;
        this.canvas.viewportTransform![5] += offsetY;
        this.canvas.requestRenderAll();
        opt.e.preventDefault();
        opt.e.stopPropagation();
      }
    });
  }
}

enum BrushType {
  Pencil,
  Eraser,
  Highlighter,
}
