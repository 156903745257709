import {
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import {
  TableCell,
  TableCellValue,
  TableOperationType,
  TableQuestion,
  TableViewMode,
} from '../../../../models/TableQuestion';
import { QuestionBaseComponent } from '../../question-base-component';
import { QuestionEvent } from '../../../steps/step-detail/step-detail.component';
import { Question } from '../../../../models/Question';
import { saveAs } from 'file-saver';
import { AbstractControl, FormBuilder, FormControl } from '@angular/forms';
import {
  RowObject,
  TableColumn,
  TableData,
  TableFeaturesService,
} from '../table-features/table-features.service';
import { keywordSearchKey } from '../table-features/table-features.component';
import { Subscription } from 'rxjs';
import { Choice } from 'src/app/models/Choice';
import {
  AffectedQuestion,
  AffectedQuestionAction,
} from 'src/app/models/AffectedQuestion';
import { QuestionService } from 'src/app/common/services/question/question.service';
import { PatchFlowTableEventResponse } from 'src/app/models/responses/question/QuestionResponse';
import { TableOperationRequest } from 'src/app/models/requests/question/QuestionRequest';
import { RuleTriggerEvent } from 'src/app/models/RuleTriggerEvent';
import { PatchFlowEventResponse } from 'src/app/models/responses/flow/FlowResponse';
import { FormControlService } from 'src/app/common/services/form-control/form-control.service';

const ROW_ID_KEY = 'rowId';

@Component({
  selector: 'table-question',
  templateUrl: './table-question.component.html',
  styleUrls: ['./table-question.component.scss'],
})
export class TableQuestionComponent
  extends QuestionBaseComponent<QuestionEvent>
  implements OnInit, OnDestroy
{
  constructor(
    private formBuider: FormBuilder,
    private _questionSerivce: QuestionService,
    private _tableFeaturesService: TableFeaturesService,
    private _formControlService: FormControlService,
  ) {
    super();
  }

  TableViewMode = TableViewMode;
  selectedTableViewMode: string = TableViewMode[TableViewMode.Grid];

  @Output() tableEvent = new EventEmitter<PatchFlowTableEventResponse>();
  public tableQuestion!: TableQuestion;
  public mappedRows: TableCell[][] = [];
  public footerQuestions: Question<TableCellValue>[][] = [];
  public tableData!: TableData;
  public cardViewData: any;
  public footerHasData: boolean = false;
  firstViewingItemIndex = 0;
  viewableRows = 5;

  subscription?: Subscription;

  public ngOnInit(): void {
    // remove old table settings from session storage
    sessionStorage.removeItem(this.question.id);
    sessionStorage.removeItem(this.question.id + keywordSearchKey);
    this.tableQuestion = this.question as TableQuestion;

    let formControl = this.form.get(this.tableQuestion.id) as FormControl;
    if (formControl !== null) {
      this.selectedTableViewMode = Object.hasOwn(
        formControl.value,
        'tableViewMode',
      )
        ? formControl.value.tableViewMode
        : this.selectedTableViewMode;
    }

    this.updateViewData();

    this.subscribeToTableUpdates();
    this.subscribeToQuestionChoicesUpdates();
    this.subscribeToCellUpdates();
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  subscribeToTableUpdates() {
    this.subscription = this._tableFeaturesService.currentTableData.subscribe(
      (tableQuestion) => {
        if (tableQuestion && tableQuestion.id === this.tableQuestion.id) {
          this.tableQuestion = tableQuestion;
          this.updateViewData();
        }
      },
    );
  }

  updateTableAfterFileUpload(tableQuestion: TableQuestion): void {
    this.tableQuestion = tableQuestion;
    this.addFormControls(tableQuestion);
    this.updateViewData();
  }

  addFormControls(tableQuestion: TableQuestion): void {
    tableQuestion.tableColumns!.forEach((tableColumn) => {
      tableColumn.cells.forEach((cell) => {
        if (!this.form.contains(cell.id)) {
          this.form.addControl(cell.id, this.formBuider.control(cell.value));
        }
      });
    });
  }

  subscribeToQuestionChoicesUpdates(): void {
    const tableChoicesSubscription =
      this._tableFeaturesService.currentQuestionChoicesData.subscribe(
        (data) => {
          if (data) {
            const tableColumns = this.tableQuestion.tableColumns!;
            const headerQuestion = tableColumns.find(
              (tc) => tc.headerQuestion.id === data.questionId,
            )?.headerQuestion;
            if (headerQuestion) {
              (headerQuestion as Choice).choices = data.newValue;
              this.updateViewData();
            }
          }
        },
      );
    this.subscription?.add(tableChoicesSubscription);
  }

  subscribeToCellUpdates(): void {
    const cellDataSubscription =
      this._tableFeaturesService.currentCellData.subscribe(
        (affectedQuestion) => {
          if (!affectedQuestion) return;

          // Check if the affected question is a footer question
          const footerQuestion = this.tableData?.footerColumns?.find(
            (footerQuestion: any) =>
              footerQuestion.id === affectedQuestion.questionId,
          );
          if (footerQuestion) {
            footerQuestion.value = affectedQuestion.newValue;
            this.form
              ?.get(affectedQuestion.questionId)
              ?.patchValue(affectedQuestion.newValue);
            return;
          }

          // Get the cell question id from header question id and row number
          const tableColumn = this.tableData.columns.find(
            (column) => column.question.id === affectedQuestion.questionId,
          );
          if (
            tableColumn &&
            affectedQuestion.rowId &&
            affectedQuestion.questionId
          ) {
            const row = this.tableData.questionData.find(
              (row) => row.rowId == affectedQuestion.rowId,
            );

            const cellQuestionId = row ? row[tableColumn.id]?.id : undefined;
            const cellControl = this.form.get(cellQuestionId);
            if (row && cellQuestionId && cellControl) {
              // Update cell state and form value
              this.handleCellControl(
                affectedQuestion,
                row,
                tableColumn,
                cellControl,
              );

              // Update questionData
              row[tableColumn.id].value = affectedQuestion.newValue;

              // Update cellData
              const cellToUpdate = this.tableData.cellData.find(
                (cellData) => cellData[ROW_ID_KEY] == affectedQuestion.rowId,
              );
              if (cellToUpdate) {
                cellToUpdate[tableColumn.id] = affectedQuestion.newValue;
              }
            }
          }
        },
      );
    this.subscription?.add(cellDataSubscription);
  }

  updateCardViewData(cardViewingData: Output[]): void {
    this.cardViewData = cardViewingData;
  }

  handleCellControl(
    affectedQuestion: AffectedQuestion,
    row: RowObject,
    col: TableColumn,
    cellControl: AbstractControl<any, any>,
  ): void {
    if (!affectedQuestion.actions) return;

    switch (affectedQuestion.actions[0]) {
      case AffectedQuestionAction.Enable: {
        if (row[col.id].visible) {
          cellControl.enable();
        }
        row[col.id].enabled = true;
        break;
      }
      case AffectedQuestionAction.Show: {
        if (row[col.id].enabled) {
          cellControl.enable();
        }
        row[col.id].visible = true;
        cellControl.patchValue(affectedQuestion.newValue);
        break;
      }
      case AffectedQuestionAction.Disable: {
        cellControl.disable();
        row[col.id].enabled = false;
        break;
      }
      case AffectedQuestionAction.Hide: {
        cellControl.patchValue(affectedQuestion.newValue);
        cellControl.disable();
        row[col.id].visible = false;
        break;
      }
      case AffectedQuestionAction.TableQuestionUpdate: {
        cellControl.patchValue(affectedQuestion.newValue);
        break;
      }
      default:
        break;
    }
  }

  updateViewData(): void {
    this.tableData = this._tableFeaturesService.transformTableData(
      JSON.parse(JSON.stringify(this.tableQuestion)),
    );

    let currentSessionItems = sessionStorage.getItem(this.tableQuestion.id);
    if (currentSessionItems) {
      let parsedSesssionItems = JSON.parse(currentSessionItems);

      // If the table data is less than the first row index,
      // then we need to adjust the first row index to show correct page data
      if (this.tableData.cellData.length <= parsedSesssionItems.first) {
        parsedSesssionItems.first -= parsedSesssionItems.rows;
        sessionStorage.setItem(
          this.tableQuestion.id,
          JSON.stringify(parsedSesssionItems),
        );
      }

      this.cardViewData = this.tableData.cellData.slice(
        parsedSesssionItems.first,
        parsedSesssionItems.first + parsedSesssionItems.rows,
      );
    } else {
      this.cardViewData = this.tableData.cellData.slice(
        this.firstViewingItemIndex,
        this.viewableRows,
      );
    }

    this.footerHasData = this.tableData.footerColumns.some(
      (footerColumn: any) => {
        return footerColumn.id;
      },
    );
  }

  private hasNumber(value: number | null | undefined): boolean {
    return value !== null && value !== undefined;
  }

  changeActiveView(view: string): void {
    this.selectedTableViewMode = view;
    this.form.get(this.tableQuestion.id)?.setValue({ tableViewMode: view });
  }

  export(question: TableQuestion): void {
    this._questionSerivce
      .generateTableCsv(this.flowId, question.id)
      .subscribe((file) => {
        if (file) {
          saveAs(file, question.title + '.csv');
        }
      });
  }

  addRow(): void {
    this._questionSerivce
      .tableOperation(
        new TableOperationRequest(
          this.flowId,
          TableOperationType.AddRow,
          RuleTriggerEvent.QuestionUpdated,
          this.tableQuestion.id,
          1,
        ),
      )
      .subscribe((response: PatchFlowEventResponse) => {
        let newEvent = new PatchFlowTableEventResponse();
        newEvent.flow = response.flow;
        newEvent.affectedQuestions = response.affectedQuestions;
        newEvent.operationType = TableOperationType.AddRow;
        newEvent.tableQuestion = this.tableQuestion;

        this.tableEvent.emit(newEvent);
      });
  }

  onRowEvent(rowEvent: RowEvent): void {
    this._questionSerivce
      .tableOperation(
        new TableOperationRequest(
          this.flowId,
          rowEvent.type,
          RuleTriggerEvent.QuestionUpdated,
          this.tableQuestion.id,
          rowEvent.rowId,
        ),
      )
      .subscribe((response: PatchFlowEventResponse) => {
        let newEvent = new PatchFlowTableEventResponse();
        newEvent.flow = response.flow;
        newEvent.affectedQuestions = response.affectedQuestions;
        newEvent.operationType = rowEvent.type;
        newEvent.tableQuestion = this.tableQuestion;
        newEvent.rowId = rowEvent.rowId;

        this.tableEvent.emit(newEvent);
      });
  }

  updateQuestionValue(tableQuestionEvent: QuestionEvent): void {
    let formControlToPatch = this.form.get(tableQuestionEvent.questionId);
    this._questionSerivce
      .tableOperation(
        new TableOperationRequest(
          this.flowId,
          TableOperationType.QuestionUpdated,
          RuleTriggerEvent.QuestionUpdated,
          this.tableQuestion.id,
          tableQuestionEvent.newValue,
          tableQuestionEvent.questionId,
          tableQuestionEvent.files,
        ),
      )
      .subscribe((response: PatchFlowEventResponse) => {
        this._formControlService.handleCustomValidationOnResponse(
          response,
          tableQuestionEvent,
          formControlToPatch,
          this.form,
        );
        let newEvent = new PatchFlowTableEventResponse();
        newEvent.flow = response.flow;
        newEvent.affectedQuestions = response.affectedQuestions;
        newEvent.operationType = TableOperationType.QuestionUpdated;
        newEvent.tableQuestion = this.tableQuestion;
        newEvent.cellId = tableQuestionEvent.questionId;
        newEvent.files = tableQuestionEvent.files;
        newEvent.invalidFileUploadDetails =
          tableQuestionEvent.invalidFileUploadDetails;
        this.tableEvent.emit(newEvent);
      });
  }
}

export class RowEvent {
  constructor(
    public rowId: string,
    public type: TableOperationType,
  ) {}
}
