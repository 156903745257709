<div>
  <div>
    <select (change)="onBrushSizeChange($event)">
      <option value="1">Size1</option>
      <option value="5">Size2</option>
      <option value="10">Size3</option>
    </select>

    <button
      class="btn btn-outline-primary btn-lg"
      (click)="toggleDrawingMode()"
    >
      Drawing
    </button>

    <button
      class="btn btn-outline-primary btn-lg"
      (click)="setBrushType(brushTypeEnum.Pencil)"
    >
      <i class="bi bi-pencil"></i>
    </button>

    <button
      class="btn btn-outline-primary btn-lg"
      (click)="setBrushType(brushTypeEnum.Highlighter)"
    >
      <i class="bi bi-brush"></i>
    </button>

    <button
      class="btn btn-outline-primary btn-lg"
      (click)="setBrushType(brushTypeEnum.Eraser)"
    >
      <i class="bi bi-eraser"></i>
    </button>

    <button class="btn btn-outline-primary btn-lg" (click)="clearDrawings()">
      <i class="bi bi-arrow-clockwise"></i>
    </button>

    <input
      type="color"
      [value]="brushColor"
      (change)="changeBrushColor($event)"
    />
  </div>

  <div class="card d-inline-block container-fluid">
    <canvas id="canvas"></canvas>
  </div>
</div>
