import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { catchError, Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ExceptionHandleService } from '../exception/exception-handle.service';
import {
  SubmitQuestionRequest,
  TableOperationRequest,
} from 'src/app/models/requests/question/QuestionRequest';
import { PatchFlowEventResponse } from 'src/app/models/responses/flow/FlowResponse';

@Injectable({
  providedIn: 'root',
})
export class QuestionService {
  private _domain: string = environment.ffxFlowApiUrl;

  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
  };

  constructor(
    private _http: HttpClient,
    private _exceptionHandleService: ExceptionHandleService,
  ) {}

  submitQuestion = (
    submitQuestionRequest: SubmitQuestionRequest,
  ): Observable<PatchFlowEventResponse> => {
    if (submitQuestionRequest.files && submitQuestionRequest.files.length > 0) {
      const formData = new FormData();
      formData.append('flowId', submitQuestionRequest.flowId);
      formData.append('questionId', submitQuestionRequest.questionId);
      formData.append(
        'ruleTriggerEvent',
        submitQuestionRequest.ruleTriggerEvent.toString(),
      );
      formData.append('newValue', submitQuestionRequest.newValue);
      submitQuestionRequest.files.forEach((file: File) => {
        formData.append('files', file);
      });

      return this._http
        .patch<PatchFlowEventResponse>(
          `${this._domain}/Flow/Question/multipart`,
          formData,
        )
        .pipe(catchError(this._exceptionHandleService.handleError));
    }
    return this._http
      .patch<PatchFlowEventResponse>(
        `${this._domain}/Flow/Question/json`,
        submitQuestionRequest,
        this.httpOptions,
      )
      .pipe(catchError(this._exceptionHandleService.handleError));
  };

  generateTableCsv(flowId: string, questionId: string): Observable<Blob> {
    return this._http.get(
      `${this._domain}/Flow/${flowId}/Question/${questionId}/ExportTable`,
      { responseType: 'blob' },
    );
  }

  tableOperation(
    tableOperationRequest: TableOperationRequest,
  ): Observable<PatchFlowEventResponse> {
    if (
      tableOperationRequest.files &&
      tableOperationRequest.files.length > 0 &&
      tableOperationRequest.cellId
    ) {
      const formData = new FormData();
      formData.append('flowId', tableOperationRequest.flowId);
      formData.append(
        'ruleTriggerEvent',
        tableOperationRequest.ruleTriggerEvent.toString(),
      );
      formData.append('tableQuestionId', tableOperationRequest.tableQuestionId);
      formData.append('value', '');
      formData.append(
        'operationType',
        tableOperationRequest.operationType.toString(),
      );
      formData.append('cellId', tableOperationRequest.cellId);
      tableOperationRequest.files.forEach((file: File) => {
        formData.append('files', file);
      });

      return this._http
        .patch<PatchFlowEventResponse>(
          `${this._domain}/Flow/Question/Table/multipart`,
          formData,
        )
        .pipe(catchError(this._exceptionHandleService.handleError));
    }
    return this._http
      .patch<PatchFlowEventResponse>(
        `${this._domain}/Flow/Question/Table/json`,
        tableOperationRequest,
        this.httpOptions,
      )
      .pipe(catchError(this._exceptionHandleService.handleError));
  }

  uploadFilesToTable(
    flowId: string,
    questionId: string,
    files: File[],
    hasColumnTitleRow: boolean,
    worksheetNames: string[],
    mappings: any[],
    mapTableMappingType: any,
  ): Observable<any> {
    const formData: FormData = new FormData();
    files.forEach((file) => formData.append('uploadedFiles', file, file.name));
    formData.append('hasColumnTitleRow', JSON.stringify(hasColumnTitleRow));
    formData.append('worksheetNames', JSON.stringify(worksheetNames));
    formData.append('mappings', JSON.stringify(mappings));
    formData.append('mapTableMappingType', mapTableMappingType);

    return this._http.post<any>(
      `${this._domain}/Flow/${flowId}/Question/${questionId}/TableQuestionFileUpload`,
      formData,
    );
  }
}
