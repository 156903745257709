import { Component, Input } from '@angular/core';

@Component({
  selector: 'parallel-stepper-item',
  templateUrl: './parallel-stepper-item.component.html',
  styleUrls: ['./parallel-stepper-item.component.scss'],
})
export class ParallelStepperItemComponent {
  @Input() firstStepInFlow: boolean = false;
  @Input() lastStepInFlow: boolean = false;
  @Input() firstStepInParallelStepGroup: boolean = false;
  @Input() lastStepInParallelStepGroup: boolean = false;
  @Input() active: boolean = false;
  @Input() submitted: boolean = false;
  @Input() selected: boolean = false;
  @Input() disabled: boolean = false;
}
