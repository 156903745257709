import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class URIService {
  readonly FORMS_LIST_REDIRECT: string = '/Lists/Forms/AllItems.aspx';

  /**
   * @description Redirects to FlowForma Forms list
   * @returns { void }
   */
  redirectToFlowFormaFormsList(): void {
    const validatedURL = this.validateURL(this.getParam('appUrl'));
    if (validatedURL) {
      window.location.href = `${validatedURL}${this.FORMS_LIST_REDIRECT}`;
    }
  }

  /**
   * @description
   * @param paramaterName
   * @returns { string | null }
   */
  public getParam(paramaterName: string): string | null {
    const fullUrlStr = decodeURIComponent(window.location.href);
    const url = new URL(fullUrlStr);
    const searchParams = new URLSearchParams(url.search);
    return searchParams.get(paramaterName);
  }

  /**
   * @description Validates URL
   * @returns { string | null }
   */
  private validateURL(appURL: string | null): string | null {
    const validURLPattern =
      '(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?';
    if (!appURL || !new RegExp(validURLPattern).test(new URL(appURL).host)) {
      return null;
    }
    return appURL;
  }
}
